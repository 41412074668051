export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyDU9Aw5_LyHihcQ2A4zpBw9rS1PnGj7iGM",
    authDomain: "tanitutsx.firebaseapp.com",
    projectId: "tanitutsx",
    storageBucket: "tanitutsx.appspot.com",
    messagingSenderId: "840106997792",
    appId: "1:840106997792:web:d15c54011131394509e445",
    measurementId: "G-B9HRDQCHC3"
  },
};
