import { Component, Input, OnInit } from '@angular/core';
import { IPSlide, PQuestionComponent, PTextComponent } from 'src/app/models';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit {

  public _component!:PTextComponent | PQuestionComponent

  get component():any{
    return this._component;
  }


  @Input() set component(slide:any){
    //if slide is typeof PTextComponent
    if(  slide instanceof PTextComponent ){
      this._component = slide as PTextComponent;
    }
    else {
      this._component = slide as PQuestionComponent;
    }
  }


  @Input() slide!:IPSlide;

  constructor() { }

  ngOnInit(): void {
  }

  uploadedUrl(url:string){
    let elem = document.getElementById(`url_${this.component.id}`) as HTMLInputElement;
    //set the value of the element
    elem.value = url;
  }

}
