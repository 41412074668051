import { ApplicationRef, Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FirebaseService } from '../common/services/firebase/firebase.service';

@Component({
  selector: 'app-svg-experiments',
  templateUrl: './svg-experiments.component.html',
  styleUrls: ['./svg-experiments.component.css']
})
export class SvgExperimentsComponent implements OnInit {

  public obj:any = {
    svg:"",
  }

  public id="W6KnPcxq6TTao6hikalT"

  constructor(
    public fbSvc: FirebaseService,
    private zone: NgZone,
    private appRef: ApplicationRef,
  ) { }

  ngOnInit(): void {
  }


  save(){
      this.fbSvc.addTestDoc(this.obj);
  }

  get(){
      this.fbSvc.getTestDoc(this.id)
      .subscribe( (doc) => {
        this.obj =  doc.data() as any;
        let container = document.getElementById("svgContainer");
        if(  container ){
              container.innerHTML += this.obj.svg;
        }
        
      } )
  }

  drawArrow(){
      
      //Get the bounding rect of the svg
      let gClientRect = document.getElementById("m")?.getBoundingClientRect();
      let textDiv = document.getElementById("text");
      let textDivRect = document.getElementById("text")?.getBoundingClientRect();

      let svgClientRect = document.getElementById("svgContainer")?.getBoundingClientRect();

      //get the svg relative coords 
      gClientRect!.x -= svgClientRect!.x;
      gClientRect!.y -= svgClientRect!.y;

      let xStart =0, yStart = 0, xEnd = 0, yEnd = 0;

      //We are in row layout or column layout 
      if(  textDivRect!.y + 10 > svgClientRect!.bottom ){
        //starting coordinates of path
        xStart = gClientRect!.x + gClientRect!.width/2;
        yStart = gClientRect!.y + gClientRect!.height;
        //end coordinates of the path
        xEnd = xStart;
        yEnd = svgClientRect!.height + textDiv!.offsetTop - 10;
      }
      else {
        //starting coordinates of path
        xStart = gClientRect!.x + gClientRect!.width;
        yStart = gClientRect!.y + gClientRect!.height/ 2;

        //end coordinates of the path
        xEnd = svgClientRect!.width + textDiv!.offsetLeft - 10;
        //xEnd += textDiv!.offsetLeft;

        yEnd = yStart;
        textDiv!.style.top =  yStart - 20 + "px";
      }

      

      var dStrLeft = "M" +
      (xStart) + "," + (yStart) + " " +
      (xEnd) + "," + (yEnd);
  

      let path = document.getElementById("arrowRight")
      path!.setAttribute("d", dStrLeft);


    }
}
