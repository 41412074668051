<div>
    <input  id="file_{{component.id}}"  type="file" (change)="uploadImage($event)" accept=".png,.jpg" style="display: none;"/>
        <button 
            mat-raised-button 
            color="warning"
            (click)="chooseFile()">
            Choose File
        </button>
</div>
<div style="display: none;">
    <canvas id="canvas_{{slide.id}}"></canvas>
</div>

