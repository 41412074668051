<div class="columns" *ngIf="slide">
    <div> 
        <mat-toolbar>
            <div>
                <button mat-icon-button (click)="addText()">
                    Text
                </button>
                <button mat-icon-button (click)="addQuestion()">
                    Question
                </button>
            </div>
        </mat-toolbar>        
    </div>
    <div>
        <mat-card  *ngFor="let c of (components$ | async)" [ngClass]="{'selected-marker':c.id === selectedComponent?.id}">
            <mat-card-title>{{c.id}}</mat-card-title>
            <mat-card-actions>
                <button mat-button (click)="edit(c)"><mat-icon>edit</mat-icon></button>
                <button mat-button (click)="delete(c)" ><mat-icon>delete</mat-icon></button>
            </mat-card-actions>
          </mat-card>
    </div>
    <div *ngIf="selectedComponent">
        <div class="columns">
            <div class="row">
                <div class="tikz-groups">
                    <mat-checkbox *ngFor="let g of selectedComponent.groups" 
                    class="tikz-groups-margin" 
                    [(ngModel)]="g.value">{{g.key}}</mat-checkbox>
                </div>
                <div class="tikz-select-group">
                    <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="tikz-groups-margin"
                    [(ngModel)]="selectedComponent.markerGroupId">
                    <mat-radio-button class="example-radio-button" *ngFor="let g of selectedComponent.groups" [value]="g.key">
                        {{g.key}}
                    </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div>
                <app-text  *ngIf="selectedComponent.type === slideComponentEnum.tikzText" [component]="selectedComponent" [slide]="slide"></app-text>
                <app-question *ngIf="selectedComponent.type === slideComponentEnum.tikzQuestion" [component]="selectedComponent" [slide]="slide"></app-question>
            </div>
        </div>
        
    </div>
</div>


