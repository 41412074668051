import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { IMarker, IPSlide, Marker, PImageComponent } from 'src/app/models';
import { DialogService } from '../../services/dialog/dialog.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit, AfterViewInit{

  //SVG variables for drawing the annotation rectangles
  private svg!:SVGSVGElement;
  private svgNS!:string;
  private pt!:DOMPoint;
  private svgPoint!: any;

  public viewBox!:string;

  
  public selectedMarker$:Subject<IMarker|null> = new Subject();
  private _selectedMarker!:IMarker | null;
  //setter for selected marker
  set selectedMarker(marker:IMarker|null){
    this._selectedMarker = marker;
    this.selectedMarker$.next(marker);
  }
  //getter for selected marker
  get selectedMarker():IMarker | null{
    return this._selectedMarker;
  }

  public _component!:PImageComponent
  get component():any{
    return this._component;
  }
  @Input() set component(slide:any){
    this._component = slide as PImageComponent;
  }


  @Input() slide!:IPSlide;

  constructor(
    private pSvc:PresentationService,
    public dSvc: DialogService,
  ) { }

  public viewboxWidth:number = 800;;
  //private member variable viewboxHeight
  private _viewboxHeight:number = 400;
  //getter for the viewboxHeight
  public get viewboxHeight():number{
    return this._viewboxHeight;
  }
  //setter for the viewboxHeight
  public set viewboxHeight(height:number){
    this._viewboxHeight = height;
  }


  private _currentView:number = 0;
  //getter for the _currentView
  public get currentView():number{
    return this._currentView;
  }
  //setter for the viewboxHeight
  public set currentView(height:number){
    this._currentView = height;
    this.updateCurrentView();
    this.drawMarkers();
  }




  public views:number[] = [];



  ngAfterViewInit(): void {
    let temp = document.getElementById("svg_" + this.component.id) as any;
    this.svg = temp as SVGSVGElement;
    this.svgNS = this.svg.getAttribute('xmlns') || "http://www.w3.org/2000/svg";
    this.pt = this.svg.createSVGPoint();
    
    this.setSvgDrawable();
  }

  ngOnInit(): void {
  }

  uploadedUrl(url:string){
    this.component.src = url;
  }
  

  updateUI(){
    this.updateViews();
    this.updateCurrentView();
    this.drawMarkers();
  }

  imageLoaded($event:any): void {
    let x = $event.srcElement.getBBox();
    let width = x.width;
    let height = x.height;
    //this.viewBox = "0 0 " + width + " " + height;
    this.component.imageHeight = height;
    if(  !this.component.viewHeight ){
      this.component.viewHeight  = this.viewboxHeight;
    }
    else {
      this.viewboxHeight = this.component.viewHeight
    }
    this.updateUI();
  }

  //draw rectangles for the markers
  drawMarkers(){
    this.removeAllRects();
    //iterate over merkers in the component
    this.component.markers.forEach( (marker:IMarker) => {
      //Get the rectangle
      let rect = this.getRectangle();
      //set the id attribute of the rectangle
      rect.setAttribute('id', `rect_${marker.id}`);
      //set the attributes from marker
      this.updateRectangle(rect, marker.x, marker.y, marker.width, marker.height);
      //add the rectangle to the svg
      this.svg.appendChild(rect);
      //build annonymous function 
      //add the event listener to the rectangle
      rect.addEventListener('mousedown', (event) => {
        this.selectMarker(rect, marker);
        event.preventDefault();
        event.stopPropagation();
      })
    })
  }

  selectMarker(rect:SVGRectElement, marker:Marker){
    this.selectedMarker = marker;
    this.deselectAllRects();
    rect.style.stroke = "blue"
 }


  updateViewHeight(){
    this.component.viewHeight = this.viewboxHeight;
    this.updateViews();
    this.updateCurrentView();
  }

  //update current view
  updateCurrentView(){
    let top = (this.component.viewHeight * this.currentView );
    this.viewBox = `0 ${top} 800 ${this.component.viewHeight}`
  }
    


  //function to update view depending on the viewboxHeight
  updateViews(){
    this.views = [];
    let totalViews =  Math.ceil(this.component.imageHeight/this.component.viewHeight);
    for(let i = 0; i < totalViews; i += 1){
      this.views.push(i);
    }
  }

 
  removeAllRects(){
    let parent = this.svg;
    if( !parent ) return;
    let children = parent.getElementsByTagName("rect")
    let len = children.length;
    for( let i = 0; i < len; i++ ){
        let child = children[0];
        child.remove();
    }
  }

  //deselectAllRects
  deselectAllRects(){
    //get all the rectangles of the svg
    let rects = this.svg.getElementsByTagName("rect");
    //iterate over the rectangles
    for( let i = 0; i < rects.length; i++ ){
      //set the stroke property to red
      rects[i].style.stroke = "red"
    }
  }


  rearrange(){
    this.dSvc.openRearrangeDialog(this.component.markers);
  }

  /**
   * Attach the event handler to svg to draw the rectangle
   * on mouse move
   */
   setSvgDrawable(){
    this.svg.addEventListener('mousedown', (event) => {
      if( event.button == 0  ){
        this.drawMarkerRectWithMouseMove(event);
      }
    })
  }

  getSVGStartPoint(x:any, y:any){
    let p = this.svg.createSVGPoint();
    p.x = x;
    p.y = y;
    return p.matrixTransform(this.svg.getScreenCTM()!.inverse());
  }  


  getRectangle(){
    let rect = document.createElementNS("http://www.w3.org/2000/svg", 'rect');
    return rect;
  }

  updateRectangle(rect:any, x:number, y:number, width:number, height:number){
    rect.setAttribute('x', `${x}`);
    rect.setAttribute('y', `${y}`);
    rect.setAttribute('width', `${width}`);
    rect.setAttribute('height', `${height}`);
  }

  drawMarkerRectWithMouseMove(event:MouseEvent){
    var self = this;
    const rect = this.getRectangle();
    const start = this.getSVGStartPoint(event.clientX, event.clientY);
    let svg = this.svg;
    let marker = this.pSvc.addMarker(this.component, 0, 0);
    rect.setAttribute("id", `rect_${marker.id}`);
    const drawRect = (e:any) => {
      let p = this.getSVGStartPoint(e.clientX, e.clientY);
      let w = Math.abs(p.x - start.x);
      let h = Math.abs(p.y - start.y);
      if (p.x > start.x) {
        p.x = start.x;
      }
  
      if (p.y > start.y) {
        p.y = start.y;
      }
      this.updateRectangle(rect, p.x, p.y, w, h);
      marker.update(p.x, p.y, w, h);
      svg.appendChild(rect);
    }
  
    const endDraw = (e:any) => {
      svg.removeEventListener('mousemove', drawRect);
      svg.removeEventListener('mouseup', endDraw);
      rect.addEventListener('mousedown', (event) => {
          this.selectMarker(rect, marker);
          event.preventDefault();
          event.stopPropagation();
      })

      //this.selectedMarker = marker;
      this.selectMarker(rect, marker);

      let r = rect.getBoundingClientRect();
      if( r.width < 15 || r.height < 15 ){
        //Seems like marker added by mistake
        this.pSvc.deleteMarker(this.component, marker);
        this.onMarkerDeleted();
      }
    }
    
    svg.addEventListener('mousemove', drawRect);
    svg.addEventListener('mouseup', endDraw);
  }

  onMarkerDeleted(){
    //Marker has been already deleted in the svg-marker component
    this.updateUI();
    this.selectedMarker = null
  }
}
