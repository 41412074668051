<h1 mat-dialog-title>Select the image to reuse</h1>
<div mat-dialog-content>
    <mat-radio-group
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    [(ngModel)]="selectedImage">
    <mat-radio-button class="example-radio-button" 
            *ngFor="let img of images" [value]="img">
      {{img}}
    </mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="selectedImage" cdkFocusInitial>Ok</button>
</div>
