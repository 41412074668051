<div class="columns">
    <mat-list role="list">
        <div mat-subheader>Items</div>
        <mat-list-item  *ngFor="let l of data; index as i" role="listitem">
            <div mat-line>{{i}}){{ l.title}}</div>
            <mat-icon mat-list-icon (click)="up(i)" >arrow_upward</mat-icon>
            <mat-icon mat-list-icon (click)="down(i)">arrow_downward</mat-icon>
        </mat-list-item>
    </mat-list>
</div>
