import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreatePresentationConfirmationComponent } from '../../dialogs/create-presentation-confirmation/create-presentation-confirmation.component';

@Component({
  selector: 'app-new-document',
  templateUrl: './new-document.component.html',
  styleUrls: ['./new-document.component.css']
})
export class NewDocumentComponent implements OnInit {

  @Output () createDocumentEvent = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

  }


  createDocument(){
    const dialogRef = this.dialog.open(CreatePresentationConfirmationComponent, {
      width: '800px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if( result && result === "yes"){
        this.createDocumentEvent.emit(true);
      }
      else {
        this.createDocumentEvent.emit(false);
      }
    });
  }
}
