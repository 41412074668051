import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDispayRulesScore, ISlideScore } from 'src/app/models';

@Component({
  selector: 'app-rules-score',
  templateUrl: './rules-score.component.html',
  styleUrls: ['./rules-score.component.css']
})
export class RulesScoreComponent implements OnInit, OnDestroy {

  private ngUnsubscribe$ = new Subject();
  constructor(
  ) { }

  

  @Input() rulesScore!:IDispayRulesScore[];

  @Input() showScore:boolean = true;  

  ngOnInit(): void {
    this.typesetDocument();
  }

  typesetDocument(){
    //get the timer from rxjs and fire event every 30 seconds
    const source = timer(1000, 30000);
    source.pipe(  takeUntil( this.ngUnsubscribe$ ) )
    .subscribe( () => {
     let p = window.MathJax.typesetPromise() as any;
       //resolve the promise p 
       p.then(() => {
       })
       .catch((err:any) => {
         console.log(err);
       });
    } );
 }

  ngOnDestroy(){
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }


  

}
