import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { IPSlide, IPSlideComponent, PQuestionComponent, Presentation, Steps } from 'src/app/models';

@Component({
  selector: 'app-common-options',
  templateUrl: './common-options.component.html',
  styleUrls: ['./common-options.component.css']
})
export class CommonOptionsComponent implements OnInit, OnDestroy {
  public ngUnsubscribe = new Subject<void>();
  public isHint:boolean = false;
  public presentation!:Presentation;
  public _component!:IPSlideComponent
  public isTheory = false;

  get component():any{
    return this._component;
  }


  @Input() set component(slide:any){
    this._component = slide as IPSlideComponent;
  }

  @Input() slide!:IPSlide;

  steps:Steps = new Steps();

  constructor(
    public pSvc: PresentationService
  ) { }

  ngOnInit(): void {
    this.showHint()
    if( (this.slide.components[0] instanceof PQuestionComponent)){
      this.pSvc.assessmentChanged$
      .pipe( (takeUntil(this.ngUnsubscribe)) )
      .subscribe((value)=>{
        this.showHint();
      });
    }

    this.presentation = this.pSvc.currentPresentation;
    this.isTheory = this.presentation.isTheory();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  delete(){
      let c = confirm("Are you sure you want to perform delete operation?")
      if( c){
        this.pSvc.deleteComponent(this.slide, this.component);
      }
  }

  showHint(){
    this.isHint =  (this.slide.components[0] instanceof PQuestionComponent) 
                    && ( this.slide.components[0] as  PQuestionComponent).assessment
                    &&  this.slide.components[1].id === this.component.id;
  }
}
