import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { ScoreService } from 'src/app/common/services/score/score.service';
import { ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { IDispayRulesScore } from 'src/app/models';

export interface GoalSettingDialogData {
  step: number;
  showScore: boolean;
}


@Component({
  selector: 'app-goal-setting',
  templateUrl: './goal-setting.component.html',
  styleUrls: ['./goal-setting.component.css']
})
export class GoalSettingComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe$ = new Subject();
  public rulesScore:IDispayRulesScore[] = [];
  public goalTime:number = 0;
  public showScore: boolean = false;
  isTheory:boolean = true;

  @ViewChild('stepper') stepper!: MatStepper;

  constructor(
    public dialogRef: MatDialogRef<GoalSettingComponent>,
    public scoreSvc:ScoreService,
    public spSvc: ShowPresentationService,
    public rulesSvc:RulesService,
    @Inject(MAT_DIALOG_DATA) public data: GoalSettingDialogData,
  ) { }

  ngAfterViewInit(): void {
    if(this.data.step == 1){
      setTimeout(() => {
        this.stepper.next();
      }, 500);
    }
  }

  ngOnInit(): void {
    this.isTheory = this.scoreSvc.isTheoryPresentation()
    this.spSvc.showPresentationTime$
    .pipe( takeUntil(this.ngUnsubscribe$) )
    .subscribe(time => {
      this.goalTime = time / 60;
    });

    this.rulesSvc.rulesDoc$
    .pipe( takeUntil(this.ngUnsubscribe$) )
    .subscribe( (doc) => {
      if( doc ){
        this.getRulesScore();
      }
    } )

    this.showScore = this.data.showScore;
  }

  ngOnDestroy(){
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }


  getRulesScore(){
    let rules = this.rulesSvc.rulesDoc;
    if( !rules ){
      return;
    }

    this.rulesScore = [];
    this.rulesScore = this.scoreSvc.assessmentDetails.getRulesScoreAll(rules)

  }

  next(){
    //check if we are on step 0 of stepper
    if( this.stepper.selectedIndex === 0 ){
      if( this.isTheory || !this.rulesScore.length ){
        this.dialogRef.close();
      }
      else {
        //go to the next step
        this.stepper.next();
      }
    }
    else {
      //close the dialog
      this.dialogRef.close();
    }
  }

}
