export class Steps{
    steps:Array<KeyValue<number, string>> = [];
    constructor(){
        this.steps.push( new KeyValue(-1, "None") );
        this.steps.push( new KeyValue(1, "Understand") )
        this.steps.push( new KeyValue(2, "Plan") )
        this.steps.push( new KeyValue(3, "Execute") )
        this.steps.push( new KeyValue(4, "Lookback") )
    } 
}



export interface IDoc{
    firebaseId: string;
    authorId: string;
    companyId: string;
    toJson(): any;
    copy<T extends IDoc>(data:T):void
}

//create an enum for slide type
export enum PSlideComponentEnum {
    text,
    tikz,
    question,
    image,
    tikzText,
    tikzQuestion,
    //Only to be used to show assessment
    assessment,
}

export enum QuestionType{
    radio,
    checkbox
}

/**
 * This class creates IDs for the presentation  
 */
 export class UID {
    public _id: number = 0;
    constructor(){
    }

    public getId(){
        //return "" + (++this._id)
        return (Math.random() + 1).toString(36).substring(7)
    }

    public setId(id:number){
        //this._id = id;
    }
}




//export class with members key and value
export class KeyValue<K, V> {
    public key: K;
    public value: V;
    constructor(key: K, value: V) {
        this.key = key;
        this.value = value;
    }
}

export const theory:string = "theory";
export const examples:string = "examples";
export const assessment:string = "assessment";

const PresentationTypes:KeyValue<string, string>[] = [
    new KeyValue(theory, theory),
    new KeyValue(examples, examples),
    new KeyValue(assessment, assessment),
]

const uid = new UID();

export { uid, PresentationTypes  };


// export _presentationTypes;




//class to create slide components
export class PSlideComponentFactory{
    //static method to create slide component
    public static createSlideComponent(slideType:PSlideComponentEnum)
    :IPSlideComponent{
        switch(slideType){
            case PSlideComponentEnum.text:
                return new PTextComponent("");
            case PSlideComponentEnum.image:
                return new PImageComponent();
            case PSlideComponentEnum.tikz:
                return new PTikzSvgComponent(); 
            case PSlideComponentEnum.tikzText:
                return new PTikzTextComponent("");
            case PSlideComponentEnum.question:
                return new PQuestionComponent("");
            case PSlideComponentEnum.tikzQuestion:
                return new PTikzQuestionComponent("");
            default:
                return new PTextComponent("");
        }
    }
}

export interface IPSlideComponent {
    id: string;
    type: PSlideComponentEnum;
    title: string;
    hint:boolean;
    //In problem solving there are 4 steps understand, plan, execute and lookback. 
    //This variable keeps track of the above steps.
    //Its only used in assessment. Is not used in the theory.
    step:number;

    copy(sc:IPSlideComponent):void;
}

//class to implement ISlideComponent
export class PBaseSlideComponent implements IPSlideComponent {
    id: string = uid.getId();
    type: PSlideComponentEnum;
    title: string = "";
    hint: boolean = false;
    //-1 just tells that do not use this variable in UI. 
    step: number = -1;
    constructor(type: PSlideComponentEnum){
        this.type = type;
    }

    copy(sc:IPSlideComponent){
        this.id = sc.id;
        this.type = sc.type;
        this.title = sc.title;
        this.hint = sc.hint;
        this.step = sc.step;
    }
}


//interface for slide type
export interface IPSlide  {
    id: string;
    title: string;
    components: IPSlideComponent[]
}

//Base slide class implements Slide interface
export class PBaseSlide implements IPSlide {
    id: string = uid.getId();
    title: string = `slide-${this.id}`;
    components!: IPSlideComponent[];
    constructor(){
        this.components = [];
    }

    //copy id and type property from another slide
    copy(slide: IPSlide){
        this.id = slide.id;
        this.title = slide.title;
        //iterate through slide components and copy
        slide.components.forEach( (sc) => {
            let newSC = PSlideComponentFactory.createSlideComponent(sc.type);
            newSC.copy(sc);
            this.components.push(newSC);
        });
    }
}

//Text Slide interface
export interface IPTextComponent extends IPSlideComponent {
    text: string;
}



//TextSlide class implements Text interface
export class PTextComponent extends PBaseSlideComponent implements IPTextComponent { 
    text: string;
    constructor(text: string){
        super(PSlideComponentEnum.text);
        this.text = text;
    }

    //copy text property from another slide
    copy(slide: IPTextComponent){
        super.copy(slide);
        this.text = slide.text;
    }
}

export interface IPAssessmentComponet extends IPSlideComponent {
}

//AssessmentSlide class implements Assessment interface
export class PAssessmentComponent extends PBaseSlideComponent implements IPAssessmentComponet {
    constructor(){
        super(PSlideComponentEnum.assessment);
    }
}




export interface IPQuestionComponent extends IPSlideComponent {
    text: string;
    qType: QuestionType;
    answers: KeyValue<string, boolean>[];
    assessment:boolean;
    ruleKey:string | undefined;
    //variable to be used in the show-presentation
    hintShown:boolean;
    solutionShown:boolean;
}

//implement Question interface
export class PQuestionComponent extends PBaseSlideComponent implements IPQuestionComponent {
    text: string;
    qType: QuestionType = QuestionType.radio;
    answers: KeyValue<string, boolean>[];
    assessment: boolean = false;
    hintShown:boolean =  false;
    solutionShown: boolean = false;
    ruleKey: string | undefined;

    constructor( text: string){
        super(PSlideComponentEnum.question);
        this.text = text;
        this.answers = [];
    }

    //copy question properties from another slide
    copy(slide: IPQuestionComponent){
        super.copy(slide);
        this.text = slide.text;
        this.qType = slide.qType;
        this.answers = slide.answers;
        this.assessment = slide.assessment;
        this.ruleKey = slide.ruleKey;
    }
    
    
}


//interface for tikz TextSlide
export interface IPTikzTextComponent extends IPTextComponent {
    //Groups map with ids as key and boolean as value
    groups: KeyValue<string, boolean>[];
    //Group around which rectangle is drawn
    markerGroupId: string;
    setType():void;
}

//implement interface IPTikzTextComponent
export class PTikzTextComponent extends PBaseSlideComponent implements IPTikzTextComponent {
    text: string;
    groups: KeyValue<string, boolean>[];
    markerGroupId!: string;
    constructor(text: string){
        super(PSlideComponentEnum.tikzText);
        this.setType()
        this.text = text;
        this.groups = [];
    }

    //set type of slide component
    setType(){
        this.type = PSlideComponentEnum.tikzText;
    }


    //function to copy groups from map
    deepCopyGroups(groups:KeyValue<string, boolean>[]){
        //iterate over groups and copy
        this.groups = [];
        groups.forEach( (group) => {
            let newGroup = new KeyValue(group.key, group.value);
            this.groups.push(newGroup);
        });
    }

    //copy text property from another slide
    copy(slide: IPTikzTextComponent){
        super.copy(slide);
        this.setType();
        this.text = slide.text;
        this.groups = slide.groups;
        this.markerGroupId = slide.markerGroupId;
    }
}

//interface for tikzQuestion  extends IPTikzTextComponent
export interface IPTikzQuestionComponent extends IPTikzTextComponent {
    qType: QuestionType;
    answers: KeyValue<string, boolean>[];
    ruleKey: string | undefined;
}

//Implement IPTikzQuestionComponent interface
export class PTikzQuestionComponent extends PTikzTextComponent implements IPTikzQuestionComponent {
    qType: QuestionType = QuestionType.radio;
    answers: KeyValue<string, boolean>[];
    ruleKey: string | undefined;
    constructor(text: string){
        super(text);
        this.setType();
        this.answers = [];
    }

    //set type of slide component
    setType(){
        this.type = PSlideComponentEnum.tikzQuestion;
    }

    //copy question properties from another slide
    copy(slide: IPTikzQuestionComponent){
        super.copy(slide);
        this.setType();
        this.qType = slide.qType;
        this.answers = slide.answers;
        this.ruleKey = slide.ruleKey;
    }

}






//Marker interface
export interface IMarker {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
    title: string;

    copy(marker: IMarker): void;
    components: IPSlideComponent[];
    update(x: number, y: number, w:number, h:number): void;
    addComponent(scType:PSlideComponentEnum ):IPSlideComponent;
    deleteComponent(component: IPSlideComponent): void;
}

//Implement Imarker interface
export class Marker implements IMarker {
    id: string = uid.getId();
    x: number;
    y: number;
    width: number;
    height: number;
    components: IPSlideComponent[] = [];
    title: string = "" + this.id;

    constructor(x: number, y: number, width: number, height: number){
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    //copy id, x, y, width, height property and components from another marker
    copy(marker: IMarker){
        this.id = marker.id;
        this.x = marker.x;
        this.y = marker.y;
        this.width = marker.width;
        this.height = marker.height;
        this.title = marker.title;
        //iterate through slide components and copy
        marker.components.forEach( (sc) => {
            let newSC = PSlideComponentFactory.createSlideComponent(sc.type);
            newSC.copy(sc);
            this.components.push(newSC);
        });
    }

    update(x: number, y: number, w:number, h:number){
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
    }

    //implement addComponent method
    addComponent(scType:PSlideComponentEnum ):IPSlideComponent{
        let sc = PSlideComponentFactory.createSlideComponent(scType);
        this.components.push(sc);
        return sc;
    }

    //implement deleteComponent method
    deleteComponent(component: IPSlideComponent){
        let index = this.components.indexOf(component);
        this.components.splice(index, 1);
    }
}

//inteface for tikzSvg
export interface IPTikzSvgComponent extends IPSlideComponent {
    tikz: string;
    components: IPSlideComponent[];
    copy(marker: IPTikzSvgComponent): void;
    addComponent(scType:PSlideComponentEnum ):IPSlideComponent;
    deleteComponent(component: IPSlideComponent): void;
}

//implement ITikzSvgComponent interface
export class PTikzSvgComponent extends PBaseSlideComponent implements IPTikzSvgComponent {
    tikz!: string;
    components: IPSlideComponent[];
    constructor(){
        super(PSlideComponentEnum.tikz);
        this.components = [];
    }

    //copy id, tikz and components from another tikzSvg
    copy(slide: IPTikzSvgComponent){
        super.copy(slide);
        this.tikz = slide.tikz;
        //iterate through slide components and copy
        slide.components.forEach( (sc) => {
            let newSC = PSlideComponentFactory.createSlideComponent(sc.type);
            newSC.copy(sc);
            this.components.push(newSC);
        });
    }

    //implement addComponent method
    addComponent(scType:PSlideComponentEnum ):IPSlideComponent{
        let sc = PSlideComponentFactory.createSlideComponent(scType);
        this.components.push(sc);
        return sc;
    }

    //implement deleteComponent method
    deleteComponent(component: IPSlideComponent){
        let index = this.components.indexOf(component);
        this.components.splice(index, 1);
    }
}




//image component interface
export interface IPImageComponent extends IPSlideComponent {
    src: string;
    markers: IMarker[];
    viewHeight: number;
    imageHeight:number;//used to calculate the number of viewports
    addMarker(x:number, y:number): Marker;
    deleteMarker(marker: IMarker): void;
}


//implement IPImageComponent
export class PImageComponent extends PBaseSlideComponent implements IPImageComponent {
    src!: string;
    markers: IMarker[] = [];
    viewHeight: number = 300;
    imageHeight!:number;
    constructor(){
        super(PSlideComponentEnum.image);
    }

    //copy id, src, markers property from another image component
    copy(image: IPImageComponent){
        super.copy(image);
        this.src = image.src;
        this.viewHeight = image.viewHeight;
        this.imageHeight = image.imageHeight;
        //iterate through markers and copy
        image.markers.forEach( (marker) => {
            let newMarker = new Marker(marker.x, marker.y, marker.width, marker.height);
            newMarker.copy(marker);
            this.markers.push(newMarker);
        });
    }

    //implement addMarker method
    addMarker(x:number, y:number): Marker {
        let marker = new Marker(x, y, 0, 0);
        this.markers.push(marker);
        return marker;
    }

    //delete marker from markers array
    deleteMarker(marker: IMarker){
        let index = this.markers.indexOf(marker);
        this.markers.splice(index, 1);
    }
}

//interface for presentation
export interface IPresentation extends IDoc {
    docType : string;
    title: string;
    slides: IPSlide[];
    tag: string;
    lastUsedId: string;
}

//Presentation class implements IPresentation interface
export class Presentation implements IPresentation {
    //This is the id of the firebase document
    docType: string = PresentationTypes[0].value;   
    firebaseId: string = uid.getId();
    title: string;
    slides: IPSlide[];
    authorId!: string;
    companyId!: string;
    tag: string = "";
    lastUsedId: string = "";
    constructor(title: string, tag:string){
        this.title = title;
        this.tag = tag;
        this.slides = [];
    }

    //function to set authorId
    setAuthorId(authorId: string){
        this.authorId = authorId;
    }

    //function to set companyId
    setCompanyId(companyId: string){
        this.companyId = companyId;
    }
    


    //copy id, title and slides property from another presentation
    copy<T>(data: T){
        let presentation = data as unknown as  IPresentation;
        this.firebaseId = presentation.firebaseId;
        
        //set title if it exists
        if( presentation.title ){
            this.title = presentation.title;
        }
        //set authorId if it exists
        if( presentation.authorId ){
            this.authorId = presentation.authorId;
        }
        //set the type if it exists
        if( presentation.docType ){
            this.docType = presentation.docType;
        }
        

        //set companyId
        this.companyId = presentation.companyId;
        
        this.lastUsedId = presentation.lastUsedId || "0";

        //iterate through slides and copy
        if( presentation.slides ){ //copy slides from another presentation
            this.slides = presentation.slides.map( (slide) => {
                let newSlide = new PBaseSlide();
                newSlide.copy(slide)
                return newSlide;
            });
        }

        uid.setId( +this.lastUsedId )
    }


    //return true if the presentation is a theory
    isTheory(): boolean{
        return this.docType === theory;
    }

    //convert object to json
    toJson(){
        return  JSON.parse( JSON.stringify(this) );
    }
}


//interface for the flat image marker
export interface IFlatMarker {
    marker: IMarker | null;
    component: IPSlideComponent
    componentType: PSlideComponentEnum;
}

//implement IFlatImageMarker
export class FlatMarker implements IFlatMarker {
    marker: IMarker | null;
    component: IPSlideComponent;
    componentType: PSlideComponentEnum;
    constructor(marker: IMarker | null, text: IPSlideComponent, type: PSlideComponentEnum){
        this.marker = marker;
        this.component = text;
        this.componentType = type;
    }
}

//interface flat component
export interface IFlatComponent {
    component: IPSlideComponent;
    markers: IFlatMarker[] ;
}

//implament IFlatComponent
export class FlatComponent implements IFlatComponent {
    component: IPSlideComponent;
    markers: IFlatMarker[] = [];
    constructor(component: IPSlideComponent){
        this.component = component;
        this.flatten()
    }

    //flatten the slide
    flatten(){
        //iterate through slide components and flatten
        //switch on component type
        switch(this.component.type){
            case PSlideComponentEnum.text:
                //create FlatMarker from text component
                let flatText = new FlatMarker(null, this.component, this.component.type);
                //push to markers array
                this.markers.push(flatText);
                break;
            case PSlideComponentEnum.image:
                let image = this.component as IPImageComponent;
                //iterate through image markers and flatten
                image.markers.forEach( (marker) => {
                    //iterate through components of marker
                    marker.components.forEach( (sc) => {
                        //create FlatMarker from marker component
                        let flatMarker = new FlatMarker(marker, sc, sc.type);
                        //push to markers array
                        this.markers.push(flatMarker);
                    });
                });
                break;
            case PSlideComponentEnum.tikz:
                let tikz = this.component as IPTikzSvgComponent;
                //iterate through components of component
                tikz.components.forEach( (sc) => {
                    //create FlatMarker from component
                    let flatMarker = new FlatMarker(null, sc, sc.type);
                    //push to markers array
                    this.markers.push(flatMarker);
                });
                break;
            case PSlideComponentEnum.question:
                //create FlatMarker from text component
                let question = new FlatMarker(null, this.component, this.component.type);
                //push to markers array
                this.markers.push(question);
                break;
            case PSlideComponentEnum.assessment:
                //create FlatMarker from text component
                let assessment = new FlatMarker(null, this.component, this.component.type);
                //push to markers array
                this.markers.push(assessment);
                break;
            default:
                break;
        }

    }

}





//interface flat slide
export interface IFlatSlide {
    id: string;
    title: string;
    //array of IFlatMarker
    components: IFlatComponent[];
    flatten(slide: IPSlide): void;
    slide: IPSlide;
}

//implement IFlatSlide
export class FlatSlide implements IFlatSlide {
    id!: string;
    title!: string;
    slide: IPSlide;
    components: IFlatComponent[] = [];
    constructor(slide:IPSlide){
        this.id = slide.id;
        this.title = slide.title;
        this.slide = slide;
        //call the flatten method
        this.flatten(slide);
    }

    //flatten the slide
    flatten(slide: IPSlide){
        //iterate over slide components
        slide.components.forEach( (component) => {
            //create FlatComponent from component
            let flatComponent = new FlatComponent(component);
            //push to component array
            this.components.push(flatComponent);
        });
    }
}

//interface for flat presentation
export interface IFlatPresentation {
    id: string;
    title: string;
    slides: IFlatSlide[];
    presentation: IPresentation;

    slidesCount(): number;
    isTheory(): boolean;
}

//implement IFlatPresentation
export class FlatPresentation implements IFlatPresentation {
    id: string;
    title: string;
    slides: IFlatSlide[];
    presentation: IPresentation;
    constructor(presentation: IPresentation){
        this.presentation = presentation;
        this.id = presentation.firebaseId;
        this.title = presentation.title;
        this.slides = [];
        //iterate through slides and flatten
        presentation.slides.forEach( (slide) => {
            let flatSlide = new FlatSlide(slide);
            this.slides.push(flatSlide);
        });
    }

    //return number of slides
    slidesCount(): number{
        return this.slides.length;
    }

    //return true if the presentation is a theory
    isTheory(): boolean{
        return this.presentation.docType === theory;
    }
}


