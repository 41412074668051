export interface Users{
    role: string;
    company: string;
}

//create class for user
export class User implements Users{
    role!: string;
    company!: string;
    constructor(){
    }

    fromJson(json: any){
        this.role = json.role;
        if( json.company ){
            this.company = json.company;
        }
    }
}
