import { Subject } from "rxjs";
import { IDoc } from "./Presentation";

export interface IRule{
    key: string
    value: string
    children?: IRule[]
    copy(rule: IRule): void
}

//implement IRule
export class Rule implements IRule{
    key!: string
    value!: string
    children: IRule[] = []
    constructor(){
        this.value = "New Rule"
        this.key = "Key"
    }

    copy(rule: IRule){
        this.key = rule.key
        this.value = rule.value
        //iterate over children
        if(rule.children){
            rule.children.forEach( (child) => {
                let newChild = new Rule()
                newChild.copy(child)
                this.children.push(newChild)
            })
        }
    }

}



export interface IRulesDoc extends IDoc{
    tag:string,
    name:string,
    rules:IRule[],
}

//Implement IRulesDoc
export class RulesDoc implements IRulesDoc{
    tag!:string
    name:string
    rules!:IRule[]
    firebaseId!:string
    authorId!: string;
    companyId!: string;
    constructor(name:string){
        this.name = name;
    }
    
    copy<T>(data: T){
        let doc = data as unknown as IRulesDoc;
        this.tag = doc.tag;
        this.name = doc.name;
        this.firebaseId = doc.firebaseId;
        this.authorId = doc.authorId;
        this.companyId = doc.companyId;
        this.rules = [];
        if( !doc.rules ){
            return;
        }
        
        //iterate over rules and copy them
        doc.rules.forEach( (rule) => {
            let newRule = new Rule();
            newRule.copy(rule);
            this.rules.push(newRule);
        });
    }

    //convert object to json
    toJson(){
        return  JSON.parse( JSON.stringify(this) );
    }
}


export interface FlatRuleNode {
    expandable: boolean;
    inEditMode: boolean;
    level: number;
    rule: Rule;
}

export interface RuleDialogData{
    ruleDoc: RulesDoc;    
    selectedRule: Rule;
    ruleSelected$: Subject<Rule>;
}



export interface IDispayRulesScore{
    key: string;
    content:string;
    totalScore:number;
    userScore:number;
    percentage:number;
  }
  