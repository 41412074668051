import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ShowPresentationRouteService, ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { FlatComponent, IFlatMarker, PSlideComponentEnum } from 'src/app/models';

@Component({
  selector: 'app-show-paginator',
  templateUrl: './show-paginator.component.html',
  styleUrls: ['./show-paginator.component.css']
})
export class ShowPaginatorComponent implements OnInit, OnDestroy {

  public ngUnsubscribe = new Subject<void>();

  //Input for index of current slide
  @Input() index: number = 0;

  lastSlide: boolean = false;

  public markers!:IFlatMarker[];

  public _component!:FlatComponent
  get component():any{
    return this._component;
  }
  @Input() set component(slide:any){
    this._component = slide as FlatComponent;
    this.markers = this._component.markers;
  }

  

  constructor(
    private route: ActivatedRoute,
    private router:Router,
    private sRouter:ShowPresentationRouteService,
    public spSvc: ShowPresentationService
  ) { }

  ngOnInit(): void {
  }

  navigateTo(){
    let currentRoute = this.sRouter.getCurrentRoute();
    this.router.navigate( [`slide/${currentRoute[0].value}/c/${currentRoute[1].value}/sc/${this.index}`], {relativeTo: this.route.parent} );
  }

  next(){
      if(  this.index >= this.markers.length - 1 ){ 
        return;
      }
      this.index++;
      if(  this.index >= this.markers.length - 1 ){ 
        let currentRoute = this.sRouter.getCurrentRoute();
        //get the slide from this index in the presentation
        let slide = this.spSvc.flatPresentation.slides[currentRoute[0].value];

        if( currentRoute[1].value == slide.components.length -1 ){
          this.lastSlide = true;
        }
      }
      this.navigateTo()
  }

  prev(){
    //return if index is 0
    if( this.index === 0 ){
      return;
    }
    this.index--;

    this.navigateTo();

  }

  done(){
    this.spSvc.slideDone$.next(true);
  }


  speak(b:boolean){
    this.spSvc.speechSynthesisUtterance$.next(b);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


}
