import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { IClass, ISubject, ITag, Tags } from 'src/app/models/tags';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public classes:Map<string, IClass> = new Map();
  public classes$:BehaviorSubject<IClass[]>  = new BehaviorSubject<IClass[]>([]); 
  public subjects$:BehaviorSubject<ISubject[]>  = new BehaviorSubject<ISubject[]>([]); 
  public companyCollName:string = "companies"
  public tagCollName:string = "tags"
  public classCollName:string = "class"
  public subjectCollName:string = "subject"

  constructor(
    private firestore: AngularFirestore,
  ) { 

  }



  //get all classes from companyCollName for passed companyId
  getClasses(companyId:string){
    if( this.classes.size ){
      return;
    }
    let coll = this.firestore.collection(this.companyCollName)
    .doc(companyId)
    .collection(this.classCollName);
    
    coll.get().subscribe( (snapshot) => {
      snapshot.docs.forEach( (doc) => {
        let c:IClass = doc.data() as IClass;
        this.classes.set(c.name,c);
      });
      //Get the keys of map as array 
      let keys = Array.from(this.classes.values());
      //Call next on classes$ to update the BehaviorSubject
      this.classes$.next(keys);

    });
  }


  //get tags for company passed as parameter
  getSubjects(companyId:string, className:string){
    let c = this.classes.get(className)
    //If class exists and subjects are populated return
    if( c && c.subjects ){
      return;
    }

    //Initialize the subjects array
    let subjects:ISubject[] = [];
    //Set the subjects array to the subjects array of the class
    c!.subjects = subjects;


    let coll = this.firestore.collection(this.companyCollName)
    .doc(companyId)
    .collection(this.classCollName)
    .doc(className)
    .collection(this.subjectCollName);
    coll.get().subscribe( (snapshot) => {
      snapshot.docs.forEach( (doc) => {
        let s:ISubject = doc.data() as ISubject;
        subjects.push(s);
      });
      this.subjects$.next(subjects);
    });
  }


}
