<div class="columns">
<div>
  <mat-toolbar>
    <span class="spacer"></span>
    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      {{userSvc.userName}}
      </button>
    <a routerLink="/logout">
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </a> 
  </mat-toolbar>
   
</div>
<div>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Presentation">
      <div class="toolbar">
        <mat-toolbar>
          <app-new-document 
            (createDocumentEvent)="createPresentation($event)">
          </app-new-document>
          <button 
              mat-icon-button 
              class="btn" 
              (click)="showAllPresentations()">
            <mat-icon>view_list</mat-icon>
          </button>
          <app-tags (tagChangeEvent)="tagChangeEvent($event)"></app-tags>
        </mat-toolbar>
      </div>
      <div class="content list">
        <app-show-presentations [presentations$]="presentations$"></app-show-presentations>
      </div>


    </mat-tab>
    <mat-tab label="Rules">
      <app-rules-home></app-rules-home>
    </mat-tab>
    <mat-tab label="Courses">Content 3</mat-tab>
  </mat-tab-group>
</div>


