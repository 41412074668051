<div class="columns">
    <div class="row" *ngFor="let p of (presentations$ | async)">
        <div class="title"> 
            <a routerLink="/createPresentation/{{p.firebaseId}}">
                {{ p.title }}
            </a> </div>
        <div class="tag"> {{ p.tag }} </div>
        <div class="actions">
            <div>
                <a routerLink="/showPresentationTimeline/{{p.firebaseId}}" target="_blank">
                    <mat-icon>language</mat-icon>
                </a>
            </div>
        </div>
    </div>
</div>
