<h1 mat-dialog-title>Are you sure you want to create new document?</h1>
<div mat-dialog-content>
    <p>If you want to view your already created documents select
      <button 
        mat-icon-button 
        class="btn" 
      >
        <mat-icon>view_list</mat-icon> 
      </button> on the page below.
    </p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="create" cdkFocusInitial>Ok</button>
</div>
