Below is your rule score. Use it to identify
where you are making <b>mistake</b>.
<ul class="rule-score">
    <li *ngFor="let rule of rulesScore ">
        {{rule.content}}
            <span 
                class="row" 
                *ngIf="showScore" 
                [ngClass]="{'txt-danger': rule.percentage < 50}"
                class="txt-danger">
                {{rule.userScore}}/{{rule.totalScore}} ({{rule.percentage}}%)
            </span>
    </li>
</ul>
