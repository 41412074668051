<button 
      mat-raised-button 
      color="warning"
      (click)="delete()">
  Delete
</button>
<mat-checkbox class="example-margin" 
  [(ngModel)]="component.hint"
  *ngIf="isHint">
  Hint
</mat-checkbox>
<span class="step">
  <mat-form-field class="step" appearance="fill" *ngIf="!isTheory">
    <mat-label>Step</mat-label>
    <mat-select [(ngModel)]="component.step" name="step">
    <mat-option *ngFor="let kv of steps.steps" [value]="kv.key">
        {{kv.value}}
    </mat-option>
    </mat-select>
  </mat-form-field>  
</span>