<div class="columns">
    <div>
        <mat-form-field appearance="legacy" class="width100">
            <mat-label>Presentation Name</mat-label>
            <input [ngModel]="presentation?.title"  
            (ngModelChange)="presentation.title = $event" 
            matInput placeholder="Placeholder">
          </mat-form-field>
    </div>
    <div class="row tag">
        <div class="tagName">
            <mat-form-field appearance="legacy" class="width100">
                <mat-label>Tag</mat-label>
                <input [ngModel]="presentation?.tag" readonly  
                matInput placeholder="Placeholder"> 
            </mat-form-field>
        </div>
        <app-tags (tagChangeEvent)="tagChangeEvent($event)"></app-tags>
    </div>
    <div class="row">
        <mat-radio-group
            [ngModel]="presentation?.docType"
            (ngModelChange)="typeChanged($event)" >
            <mat-radio-button class="example-radio-button" 
                *ngFor="let type of presentatioTypes" 
                [value]="type.value">
                {{type.key}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>
<div class="columns">
    <div>
        <mat-form-field appearance="legacy" class="width100">
            <mat-label>Presentation Name</mat-label>
            <input [ngModel]="searchFor"  
            (ngModelChange)="searchFor = $event" 
            matInput placeholder="Placeholder">
          </mat-form-field>
          <button type="button" class="btn btn-primary" (click)="searchForPresentation()">Search</button>
          <button type="button" class="btn btn-primary" (click)="cancelSearch()">Cancel Search</button>
    </div>
    
    <div *ngIf = "searchedPresentation">
        <mat-card  *ngFor="let slide of searchedPresentation!.slides">
            <mat-card-title>{{slide.title}}</mat-card-title>
            <mat-card-actions>
              <button mat-button (click)="import(slide)"><mat-icon>edit</mat-icon></button>
            </mat-card-actions>
          </mat-card>
    </div>
</div>
