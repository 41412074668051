import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuestionCacheService {

  //map to keep id and either a single value or an array of values
  private _cache:Map<string, any> = new Map<string, any>();
  
  constructor() { }

  public get(id:string):any{
    return this._cache.get(id);
  }

  public set(id:string, value:any){
    this._cache.set(id, value);
  }

  //function to clear the cache
  public clear(){
    this._cache.clear();
  }
}
