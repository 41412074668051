import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AssessmentDetail, IAssessmentDetail } from 'src/app/models';
import { FirebaseService } from '../firebase/firebase.service';
import { UserService } from '../user/user.service';




@Injectable({
  providedIn: 'root'
})
export class PerformanceService {

  private currentPerformance: AssessmentDetail|null = null;

  constructor(
    private userSvc: UserService,
    private fSvc: FirebaseService,
  ) { }


  getPerformanceFromRoute(route: ActivatedRoute){
    let s = new Subject<AssessmentDetail|null>()
    route.params.subscribe( (params) => {
      let id = params["id"];
      if( this.currentPerformance?.firebaseId === id ){
        setTimeout(() => {
          s.next(this.currentPerformance);
          s.complete();
        }, 10);
      }
      else {
        this.getPerformance(id).subscribe( (doc) => {
          this.currentPerformance = doc;
          s.next(doc);
          s.complete();
        });
      }
    });
    return s;
  }

  getPerformance(firebaseId: string){
    let userId = this.userSvc.getUserId();
    let s = new Subject<AssessmentDetail|null>();
    let ad = new AssessmentDetail();  
    this.fSvc.getPerformance( userId, firebaseId )
      .subscribe( (p) => {
        if( p.exists  ){
          let data = p.data() as IAssessmentDetail;
          ad.initialize(data.docType, data.firebaseId)
          ad.populateFromJson(data);
          s.next(ad);
        }
        else {
          s.next(null);
        }
        s.complete();
      }
      , (err) => {
        console.log(err);
        s.next(null)
        s.complete();
      }
      , () => {
        console.log("getPerformance complete");
        s.next(null);
        s.complete();
      }
    );
    return s;
  }

  savePerformance(ad: AssessmentDetail){
    let userId = this.userSvc.getUserId();
    return new Promise( (resolve, reject) => {
      this.fSvc
      .savePerformance( userId, ad.toJson() )
      .then( (snapshot) => {
        resolve(null);
      }  )
      .catch( (err) => {
        reject(err);
      } )
    } )  
    
  }
}
