import { Component, Input, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { combineAll } from 'rxjs/operators';
import { CompanyService } from 'src/app/common/services/company/company.service';
import { FirebaseService } from 'src/app/common/services/firebase/firebase.service';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { examples, IPSlide, Presentation, PresentationTypes } from 'src/app/models';
import { IChapter, IClass, IDisplayTag, ISubject } from 'src/app/models/tags';

@Component({
  selector: 'app-presentation-metadata',
  templateUrl: './presentation-metadata.component.html',
  styleUrls: ['./presentation-metadata.component.css']
})
export class PresentationMetadataComponent implements OnInit {

  public subjects!: ISubject[];

  public presentation!: Presentation;

  public presentatioTypes = PresentationTypes;

  public searchFor:string = "";

  public searchedPresentation!:Presentation|null;


  constructor(
    private presentationSvc:PresentationService,
    private route: ActivatedRoute,
    public companySvc:CompanyService,
    public userSvc: UserService
  ) { }

  ngOnInit(): void {
      if( this.route.parent ){
      //get presentation from firebase using route param
      this.presentationSvc.currentPresentation$
      .subscribe( (presentation) => { 
        if( presentation ){
          this.presentation = presentation;
          //set presentation title if not set
          if( !this.presentation.title ){ 
            this.presentation.title = "New Presentation";
          }
        }
      })  ;

      
  
    }
  }

  tagChangeEvent($event:string){
      this.presentation.tag = $event;
  }

  typeChanged($event:string){
    this.presentation.docType = $event;
    setTimeout(() => {
      let slideId = "mnkcp";
      if( this.presentation.docType ==  examples){
        if(!this.presentation.slides.length || this.presentation.slides[0].id != slideId){
            this.presentationSvc.getThoughtProcessPresentation()
            .subscribe( p => {
              this.presentation.slides.unshift(p.slides[0]);
              //Hack .. Somehow after setting the first slide docType reverts to Theory
              //Change it back to examples
              setTimeout(() => {
                this.presentation.docType =  examples;
              }, 0); 
            } )        
        }
      }
      else {
        if(this.presentation.slides.length && this.presentation.slides[0]?.id == slideId){
          if( this.presentation.slides.length == 1 ){
            this.presentation.slides = []
          }
          else {
            this.presentation.slides.shift();
          }
          
        }
      }
    }, 0);
  }

  searchForPresentation(){
    if( this.searchFor.trim() == "" ){
      alert("Please enter the presentation Id");
      return; 
    }

    this.presentationSvc.getPresentationForSearch(this.searchFor)
    .subscribe( (presentation) => {
      this.searchedPresentation = presentation;
    } )
  }

  cancelSearch(){
    this.searchedPresentation = null;
  }
  
  import(slide:IPSlide){
    this.presentation.slides.push(slide)
  }
}
