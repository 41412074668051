import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoreService } from 'src/app/common/services/score/score.service';
import { ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { CountdownComponent } from 'ngx-countdown';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { IDispayRulesScore, IRule } from 'src/app/models';




@Component({
  selector: 'app-goal-assessment',
  templateUrl: './goal-assessment.component.html',
  styleUrls: ['./goal-assessment.component.css']
})
export class GoalAssessmentComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject();

  public rulesScore:IDispayRulesScore[] = [];
  public passed = true;
  public score:number = 0;
  public scorePercentage:string = "0";
  finishedInTime:string = "0";
  isTheory:boolean = true;
  @ViewChild('stepper') stepper!: MatStepper;

  constructor(
    public dialogRef: MatDialogRef<GoalAssessmentComponent>,
    public scoreSvc:ScoreService,
    public spSvc: ShowPresentationService,
    public rulesSvc:RulesService,
  ) { }

  ngOnInit(): void {
    this.isTheory = this.scoreSvc.isTheoryPresentation()
    this.spSvc.showPresentationTime$
    .pipe( takeUntil(this.ngUnsubscribe$) )
    .subscribe(time => {
      let temp = ( time*1000 -  this.scoreSvc.countdowenTimer.left)/1000/60
      this.finishedInTime = temp.toFixed(2);
      this.passed = this.passed && temp >= 0;
      this.score = this.scoreSvc.assessmentDetails.userScore;
      this.passed = this.passed && this.score >= (this.scoreSvc.assessmentDetails.totalScore/2);
      this.scorePercentage = ((this.score/this.scoreSvc.assessmentDetails.totalScore)*100).toFixed(2);
      this.calculateRulesScore();
    });
  }

  next(){
    if( this.isTheory  ){
      this.done();
    }
    else {
      this.stepper.selectedIndex = 1;
    }
    
  }

  done(){
    this.dialogRef.close();
  }


  ngOnDestroy(){
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }


  calculateRulesScore(){
      let rules = this.rulesSvc.rulesDoc;
      if( !rules ){
        return;
      }

      this.rulesScore = [];
      this.rulesScore = this.scoreSvc.assessmentDetails.getRulesScoreAll(rules);
  }
}
