<mat-horizontal-stepper
 [linear]="true"  #stepper>
    <mat-step>
        <ng-template  matStepLabel>Performance</ng-template>
        <mat-card>
            <mat-card-header>
                <mat-card-title *ngIf="passed" >Congratulations you passed!!!</mat-card-title>
                <mat-card-title *ngIf="!passed" >OOps!! We need to repeat this section again.</mat-card-title>
                <mat-card-subtitle>Total time to taken finish:{{finishedInTime}}</mat-card-subtitle>
                <mat-card-subtitle>Score:{{score}}({{scorePercentage}}%)</mat-card-subtitle>
              </mat-card-header>
        
            <mat-card-actions>
                <button mat-button color="primary"
                (click)="next()">Next</button>
            </mat-card-actions>
        </mat-card>
    </mat-step>
    <mat-step *ngIf="!isTheory">
        <ng-template  matStepLabel>Rules Score</ng-template>
        <mat-card class="example-card">
            <mat-card-header>
                <mat-card-title >Mistakes are where learing happens. Lets look at rules where we are making mistakes</mat-card-title>
              </mat-card-header>
        </mat-card>
        <mat-card-content>
            <app-rules-score [rulesScore]="rulesScore" ></app-rules-score>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button color="primary"
            (click)="done()">Next</button>
          </mat-card-actions>
    </mat-step>

</mat-horizontal-stepper>
