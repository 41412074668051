import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CreatePresentationConfirmationComponent } from 'src/app/common/dialogs/create-presentation-confirmation/create-presentation-confirmation.component';
import { CompanyService } from 'src/app/common/services/company/company.service';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { Presentation } from 'src/app/models';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.css']
})
export class AuthorComponent implements OnInit {

  public presentations$!:  Subject<Presentation[]>;
  public selectedTag:string = "";

  constructor(
    public userSvc:UserService,
    public pSvc:PresentationService,
    public companySvc:CompanyService,
    public routerSvc:Router,
    public dialog: MatDialog
  ) {
  }
  

  ngOnInit(): void {
  }

  createPresentation($event:boolean){
    if($event){
      this.pSvc.createPresentation("10math1")
        .subscribe( (presentation) => {
          this.routerSvc.navigate([`/createPresentation/${presentation.firebaseId}`]);
        })
    }
  }

  showAllPresentations(){
    if( !this.selectedTag  ){
      alert("please select a tag");
      return;
    } 
    this.presentations$ = this.pSvc.getPresentations(this.selectedTag)
  }

  tagChangeEvent($event:string){
    this.selectedTag = $event;
  }

}
