import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { IPresentation, IPSlide, PBaseSlide, Presentation, PSlideComponentEnum, PTextComponent, uid } from 'src/app/models';
import { DialogService } from '../../services/dialog/dialog.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit, AfterViewInit {

  public presentation!: Presentation;

  private menuToggle:boolean = false;
  //subject to raise menu toggled event
  private menuToggled = new Subject<boolean>();

  constructor(
    private presentationSvc:PresentationService,
    private route: ActivatedRoute,
    private router:Router,
    private dialogSvc:DialogService,
    public userSvc: UserService, 
    public rulesSvc: RulesService,
  ) { }
  
  ngOnInit(): void {
    //get presentation from firebase using route param
    this.presentationSvc.getPresentationFromRoute(this.route)
      .subscribe(presentation => {
        if (presentation) {
          this.presentation = presentation;
        }
      }
    );
  }

  
  //onviewinit navigate to metadata
  ngAfterViewInit(){
    this.router.navigate(['metadata'], {relativeTo: this.route});
  }

  //function menu click
  onMenuClick(){
    this.menuToggle = !this.menuToggle;
    this.menuToggled.next(this.menuToggle);
  }

  //save presentation
  savePresentation(){
    this.presentationSvc.savePresentation(this.presentation as Presentation);
  }

  //add text slide to presentation
  addSlide(){
    let sid = this.presentationSvc.addSlide()
    this.router.navigate([`slide/${sid}`], {relativeTo: this.route});
  }

  //delete slide
  deleteSlide(slide:IPSlide){
    //get confirmation from user
    if( !confirm("Are you sure you want to delete this slide") ){
      return;
    }

    this.presentationSvc.deleteSlide(slide);
    //navigate to metadata
    this.router.navigate(['metadata'], {relativeTo: this.route});
  }

  rearrange(){
      this.dialogSvc.openRearrangeDialog(this.presentation.slides);
  }

  rearrangeComponentsOfSlide(slide:IPSlide){
    this.dialogSvc.openRearrangeDialog(slide.components);
    this.router.navigate(['metadata'], {relativeTo: this.route});
  }
}
