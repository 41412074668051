import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { IChapter } from 'src/app/models/tags';
import { CompanyService } from '../../services/company/company.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {

  public chapters!:IChapter[];

  @Output() tagChangeEvent = new EventEmitter<string>();

  constructor(
    public companySvc:CompanyService,
    public userSvc: UserService

  ) { }

  ngOnInit(): void {
    //this.companySvc.getClasses(this.userSvc.user.company);
    this.companySvc.getClasses("QTKRyoB57ygBGtdfEPcQ");
  }

  classChange($event:MatSelectChange){
    this.companySvc.getSubjects(this.userSvc.user.company, $event.value)
  }

  subjectChange($event:MatSelectChange){
    this.chapters = $event.value.chapters;
  }

  tagChange($event:MatSelectChange){
    this.tagChangeEvent.emit($event.value);
  }

}
