import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserNoteComponent } from 'src/app/common/dialogs/user-note/user-note.component';
import { QuestionCacheService } from 'src/app/common/services/question-cache/question-cache.service';
import { ScoreService } from 'src/app/common/services/score/score.service';
import { ShowPresentationRouteService, ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { IFlatPresentation, ISlideState, KeyValue, SlideEventEnum, SlideStateEnum } from 'src/app/models';
import { CountdownConfig, CountdownEvent, CountdownComponent } from 'ngx-countdown';
import { GoalSettingComponent } from '../goal-setting/goal-setting.component';
import { GoalAssessmentComponent } from '../goal-assessment/goal-assessment.component';
import { MessageComponent } from 'src/app/common/dialogs/message/message.component';
import { RulesScoreDialogComponent } from '../rules-score-dialog/rules-score-dialog.component';
import { MessageChoiceComponent } from 'src/app/common/dialogs/message-choice/message-choice.component';
import { RulesService } from 'src/app/common/services/rules/rules.service';
declare global {
  interface Window {
    MathJax: {
      tex: {inlineMath: [['$', '$'], ['\\(', '\\)']]}
      processEscapes: true,
      typesetPromise: () => void;
      typeset: any;
    };
  }
}




@Component({
  selector: 'app-show-presentation',
  templateUrl: './show-presentation.component.html',
  styleUrls: ['./show-presentation.component.css']
})
export class ShowPresentationComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject();
  public presentation!: IFlatPresentation;

  private timerStarted:boolean = false;

  config: CountdownConfig = { leftTime: 0 };
  //Its any to avoid error in the template
  leftTime$:BehaviorSubject<any> = new BehaviorSubject(0);

  private _countdown!: CountdownComponent;

  //getter for _countdown
  get countdown():CountdownComponent{
    return this._countdown;
  }



  @ViewChild('cd', { static: false })  set countdown(value:CountdownComponent){
    this._countdown = value;
    this.scoreSvc.countdowenTimer = this._countdown;
  }


  public slideState!:ISlideState;
  public isTheory:boolean = false;
  
  constructor(
    private router:Router,
    private routerSvc: ShowPresentationRouteService,
    private route:ActivatedRoute,
    private qCacheSvc: QuestionCacheService,
    public spSvc: ShowPresentationService,
    private breakpointObserver: BreakpointObserver,
    public scoreSvc: ScoreService,
    public dialog: MatDialog,
    public rulesSvc:RulesService,

  ) { 
  }

  async ngOnInit(): Promise<void> {
    
    this.spSvc.getPresentationFromRoute(this.route)
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe( (presentation) => { 
      this.presentation = this.spSvc.getFlatPresentation(presentation);
      this.isTheory = this.scoreSvc.isTheoryPresentation();
      let temp = this.scoreSvc.assessmentDetails.getCurrentSlide();
      if( temp ){
        this.slideState = temp;
      }
  
      //subscribe to the routeChanged$ observable
      this.routerSvc.routeChanged$
      .pipe( takeUntil( this.ngUnsubscribe$ ) )
      .subscribe( (routeInfo:KeyValue<string, number>[]) => {
        //if route infor is empty then navigate to the first slide
        if(routeInfo.length === 0){
          //navigate to the first slide
          let url = this.spSvc.getFirstUrl(0);
          this.router.navigate([url], { relativeTo: this.route });
        }
      })
    });


    this.spSvc.showPresentationTime$.pipe( takeUntil( this.ngUnsubscribe$ ) )
    .subscribe( (time:number) => {
      //this.config.leftTime = time;
      this.leftTime$.next(time);
    });
    
    //call observe on BreakpointObserver
    const layoutChanges = this.breakpointObserver.observe([
      '(orientation: portrait)',
      '(orientation: landscape)',
    ]);
    
    layoutChanges.subscribe(result => {
      this.spSvc.orientationChanged$.next(true);
    });

    //clear cache in question cache service
    this.qCacheSvc.clear();
    this.spSvc.showHidePaginator$.next(true);

    this.spSvc.slideDone$.pipe( takeUntil( this.ngUnsubscribe$ ) )
    .subscribe( async (done:boolean) => {
      if( done ){
        await this.home();
      }
    });


    this.typesetDocument();
  }

  typesetDocument(){
     //get the timer from rxjs and fire event every 30 seconds
     const source = timer(10*1000, 30000);
     source.pipe(  takeUntil( this.ngUnsubscribe$ ) )
     .subscribe( () => {
      let p = window.MathJax.typesetPromise() as any;
        //resolve the promise p 
        p.then(() => {
        })
        .catch((err:any) => {
          console.log(err);
        });
     } );
  }

  handleEvent($event:CountdownEvent){

    if($event.action == 'restart'){
      this.timerStarted = true;
    }
    else if(  $event.action == 'done' ){
      if( !this.timerStarted ){
        return;
      }


      this.timerStarted = false;

      this.dialog.closeAll();
      let errorMessage = "Time has elapsed. Attempt again."
      let message$ = new BehaviorSubject<string>(errorMessage);
      if( errorMessage ){
        let x = this.dialog.open( MessageComponent, {
          disableClose: true,
          data: message$
        } )
        message$.next(errorMessage);
        x.afterClosed().subscribe( () => {
          this.toTimeLine();
        } )
      }      
    }
  }

  toTimeLine(){
    this.router.navigateByUrl('/showPresentationTimeline/' + this.presentation.presentation.firebaseId);    
  }

  async home(){

    this.spSvc.speechSynthesisUtterance$.next(false);

    let assessment = this.scoreSvc.assessmentDetails;
    assessment.aggregateScores();
    if( assessment.canMoveFromCurrentSlide() ){
      this.toTimeLine();
    }
    else {
      if( assessment.getCurrentState() === SlideStateEnum.note ){
        await this.openSlideAssessmentDialog();
        await this.openNotesDialog();
        this.toTimeLine();
      }
      else {
        //get the error message from the assessment
        let errorMessage = assessment.getErrorMsgg();
        let message$ = new BehaviorSubject<string>(errorMessage);
        if( errorMessage ){
          this.dialog.open( MessageChoiceComponent, {
            data: message$
          } )
          .afterClosed().subscribe( (result:string) => {
            if( result == 'continue' ){
              this.toTimeLine();
            }
          });


          message$.next(errorMessage);
        }
      }
    }      
  }

  //open a dialog box to get the user mistake 
  async openNotesDialog(){
    
    return new Promise( (resolve) => {
      const dialogRef = this.dialog.open(UserNoteComponent, {
        width: '800px',
        disableClose: true,
      });
  
      dialogRef.afterClosed().subscribe((result:string) => {
        //call addShortNote of score service
        if( result.trim()  ){
          this.scoreSvc.addShortNote(result);
        }

        resolve(null);
      });
    })
  }

  //open dialog for the slide assessment and
  //return the promise
  async openSlideAssessmentDialog(){
    return new Promise( (resolve) => {
      let arr = this.scoreSvc.assessmentDetails.getRulesScore(this.rulesSvc.rulesDoc)
      if( !arr.length ){
        resolve(arr);
        return;
      }

      const dialogRef = this.dialog.open(RulesScoreDialogComponent, {
        width: '800px',
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result:string) => {
        resolve(result);
      } );
    } );
  }

  ngOnDestroy(){
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}



/**
 *   
 * openGoalDialog(){
    const dialogRef = this.dialog.open(GoalSettingComponent, {
      width: '800px',
      disableClose: true,
    });
  }

async goToSlide(i:number){
    if( this.scoreSvc.isTheoryPresentation()  ){
      this.switchSlide(i);
    }
    else if( i == this.presentation.slides.length -1  ){
      //Going to assessment slide. 
      this.switchSlide(i);
    }
    else{
      //get the assessment from score service
      let assessment = this.scoreSvc.assessmentDetails;
      if( assessment.canMoveFromCurrentSlide() ){
        this.switchSlide(i);
      }
      else {
        if( assessment.getCurrentState() === SlideStateEnum.note ){
          await this.openSlideAssessmentDialog();
          await this.openNotesDialog();
          this.switchSlide(i);
        }
        else {
          //get the error message from the assessment
          let errorMessage = assessment.getErrorMsgg();
          let message$ = new BehaviorSubject<string>(errorMessage);
          if( errorMessage ){
            this.dialog.open( MessageComponent, {
              data: message$
            } )
            message$.next(errorMessage);
          }
        }
      }      
    }
  }

  private switchSlide(i:number){
    this.spSvc.showHidePaginator$.next(true);
    this.drawer.toggle();
    let url = this.spSvc.getFirstUrl(i);
    this.router.navigate([url], { relativeTo: this.route });    
  }

  //open a dialog box to get the user mistake 
  async openNotesDialog(){
    
    return new Promise( (resolve) => {
      const dialogRef = this.dialog.open(UserNoteComponent, {
        width: '800px',
        disableClose: true,
      });
  
      dialogRef.afterClosed().subscribe((result:string) => {
        //call addShortNote of score service
        if( result.trim()  ){
          this.scoreSvc.addShortNote(result);
        }

        resolve(null);
      });
    })
  }

  //open dialog for the slide assessment and
  //return the promise
  async openSlideAssessmentDialog(){
    return new Promise( (resolve) => {
      const dialogRef = this.dialog.open(RulesScoreDialogComponent, {
        width: '800px',
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result:string) => {
        resolve(result);
      } );
    } );
  }


  openAssessmentDialog(){
    return new Promise( (resolve) => {
  
      const dialogRef = this.dialog.open(GoalAssessmentComponent, {
        width: '800px',
        disableClose: true
      });
  
      dialogRef.afterClosed().subscribe( (result:boolean) => {
        resolve(null);
      } );
  
    } );


  }
  
  toggleSideNav(){
    this.drawer.toggle();
  }

  handleEvent($event:CountdownEvent){
    if(  $event.action == 'done' ){
      //need to think what to do when the time is up
    }
  }


  async home(){
    this.spSvc.showPresentationTime$
    .pipe( takeUntil(this.ngUnsubscribe$) )
    .subscribe( async time => {
      //get the time from the cd component
        let timeElsapsed = ( time * 1000 - this.countdown.left)/1000/60;
        //if user is on page for only 2 minutes then dont show the dialog
        if(timeElsapsed < 0){
          this.router.navigateByUrl('/');
        }
        else if (this.scoreSvc.assessmentDetails.areAllSlidesFinished()){
          this.router.navigateByUrl('/');
        }
        else {
          // await this.openAssessmentDialog();
          // let message$ = new BehaviorSubject<string>("Please wait while we save the analysis document");
          // let ref = this.dialog.open( MessageComponent, {
          //     data: message$
          //   } )
          // await this.scoreSvc.savePerformance();
          // ref.close();
          // this.router.navigateByUrl("/")
          
          this.switchSlide(this.presentation.slides.length - 1);
        }
    } )
  }

 * 
 * 
 */