import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { IPSlideComponent, IPImageComponent, PImageComponent, IPSlide } from 'src/app/models';
import { ReuseImageComponent } from '../reuse-image/reuse-image.component';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {

  @Input() component!: IPSlideComponent;

  @Input() slide!:IPSlide;


  isImageComponent:boolean = false;

  constructor(
    public dialog: MatDialog,
    public pSvc: PresentationService,
  ) { }

  ngOnInit(): void {
    if(  this.component instanceof PImageComponent){
      this.isImageComponent = true;
    }
  }

  showHide(){
      let componentId = `component_${this.component.id}`;
      //Toggle the style.display of the component with id as componentId
      let componet = document.getElementById(componentId);
      componet!.style.display = (componet!.style.display === 'none') ? 'block' : 'none';
  }

  reuseImage(){
    let ref = this.dialog.open(ReuseImageComponent, {
      width: '800px',
      data: this.slide,
    });

    ref.afterClosed().subscribe( (result) => {
      if(result){
        (this.component as PImageComponent).src  = result;
      }
    });
  }

  deleteComponent(){
      //Get confirmation before deleteing the slide
      if(!confirm('Are you sure you want to delete this component?')){
        return;
      }

      this.pSvc.deleteComponent(this.slide, this.component);
  }
}
