import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { FlatComponent, IPTextComponent, PTextComponent } from 'src/app/models';

declare global {
  interface Window {
    MathJax: {
      tex: {inlineMath: [['$', '$'], ['\\(', '\\)']]}
      processEscapes: true,
      typesetPromise: () => void;
      typeset: any;
    };
  }
}



@Component({
  selector: 'app-show-text',
  templateUrl: './show-text.component.html',
  styleUrls: ['./show-text.component.css']
})
export class ShowTextComponent implements OnInit, AfterViewInit, OnDestroy {

  public ngUnsubscribe = new Subject<void>();


  public _component!:FlatComponent;

  public text!:IPTextComponent;

  @Input() index: number = 0;
  private _showPaginator:boolean = false;
  get showPaginator(){
    return this._showPaginator;
  }

  @Input() set showPaginator(showPaginator:boolean){
    this._showPaginator = showPaginator;
  }


  @Input()  paginatorComponent!:FlatComponent;


  get component():any{
    return this._component;
  }


  private componentChanged$:Subject<boolean> = new Subject();

  @Input() set component(slide:any){
    this._component = slide as FlatComponent;
    this.text = this._component.component as IPTextComponent;
    this.synth.cancel();
    this.componentChanged$.next(true);
    this.spSvc.showSpeechSynthesie$.next(true);
  }



  public synth!:any;

  constructor(public spSvc: ShowPresentationService) { 
    this.synth  = window.speechSynthesis;
    combineLatest( this.spSvc.speechSynthesisUtterance$, this.componentChanged$ )
    .pipe( takeUntil( this.ngUnsubscribe ) )
    .subscribe( ([b,loaded]) => {
        if( !b ){
          this.synth.cancel();
        }
        else {
          setTimeout(() => {
            var elem = document.getElementById(`txt_${this.text.id}`)?.textContent;
            elem = elem?.replace(/&nbsp;/g, '');
            const utterThis = new SpeechSynthesisUtterance(elem);
            this.synth.speak(utterThis);
          }, 1000);     
        }
    }  )
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.mathjax();
    }, 10);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.mathjax()
    }, 10);
  }




  public mathjax(){
    let p = window.MathJax.typesetPromise() as any;
    //resolve the promiose p 
    p.then(() => {
    })
    .catch((err:any) => {
      console.log(err);
    });
  }


  ngOnDestroy() {
    this.spSvc.showSpeechSynthesie$.next(false);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
