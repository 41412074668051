import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { FlatRuleNode, Rule, RulesDoc } from 'src/app/models';
import { BaseComponent } from '../base/base.component';



declare global {
  interface Window {
    MathJax: {
      tex: {inlineMath: [['$', '$'], ['\\(', '\\)']]}
      processEscapes: true,
      typesetPromise: () => void;
      typeset: any;
    };
  }
}

@Component({
  selector: 'app-rules-create',
  templateUrl: './rules-create.component.html',
  styleUrls: ['./rules-create.component.css']
})
export class RulesCreateComponent extends BaseComponent implements OnInit, OnDestroy {

  public currentRule!:FlatRuleNode;




  constructor(
    public route: ActivatedRoute,
    public rulesSvc: RulesService,
    public userSvc: UserService
  ) {
    super(route, rulesSvc, userSvc);
   }

  
  saveRules(){
    this.rulesSvc.saveRulesDoc(this.rulesDoc)
    .subscribe( (doc:any) => {
        console.log("Rules saved", doc);
    })
  }

  
  
  addChild(node: FlatRuleNode){
    node.rule.children.push(this.getNewRule())
    this.dataSource.data = this.dataSource.data;
  }

  addRule(){
    this.rulesDoc.rules.push(this.getNewRule())
    this.dataSource.data = this.dataSource.data;
  }


  getNewRule(){
    let r = new Rule(); 
    r.key = this.rulesSvc.getRandomNumber4();
    return r;
  }


  editRule(node: FlatRuleNode){
    if( this.currentRule ){
      this.currentRule.inEditMode = false;  
    }
    this.currentRule = node;
    node.inEditMode = true;
  }
  
  done(){
    this.currentRule.inEditMode = false;
    this.refresh();
  }

  ngOnInit(){
    this.rulesSvc.getRulesFromRoute(this.route)
    .pipe(  takeUntil(this.ngUnsubscribe) )
    .subscribe( (rulesDoc) => {
      this.rulesDoc = rulesDoc;
      if( !this.rulesDoc.rules || this.rulesDoc.rules.length == 0 ){
        this.rulesDoc.rules = [];
      }
      this.dataSource.data = rulesDoc.rules as Rule[];

      setTimeout(() => {
          this.refresh();
      }, 10);
    })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  

  delete(node:FlatRuleNode){
      //Get confirmation before deleting
      if( !confirm("Are you sure you want to delete this rule?") ){
        return;
      }
      
      let r = node.rule;
      //find the index of rule using the rule.key
      let index = this.rulesDoc.rules.findIndex( (rule) => {
        return rule.key === r.key;
      })

      //delete the rule if found
      if( index > -1 ){
        this.rulesDoc.rules.splice(index, 1);
        this.refresh();
        return
      }

      //rule is not found in the rulesDoc.rules array
      //so iterate over all rules in the rulesDoc.rules array
      //and find the rule with the same key
      this.rulesDoc.rules.forEach( (rule) => {
        if( rule.children ){
          let index = rule.children.findIndex( (child) => {
            return child.key === r.key;
          })
          if( index > -1 ){
            rule.children.splice(index, 1);
          }
        }
      })
      this.refresh();
  }
}
