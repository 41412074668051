import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { MessageChoiceComponent } from 'src/app/common/dialogs/message-choice/message-choice.component';
import { MessageComponent } from 'src/app/common/dialogs/message/message.component';
import { QuestionCacheService } from 'src/app/common/services/question-cache/question-cache.service';
import { ScoreService } from 'src/app/common/services/score/score.service';
import { ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { FlatComponent, FlatMarker, IFlatSlide, IPQuestionComponent, IPTikzQuestionComponent, KeyValue, PSlideComponentEnum, QuestionType, SlideEventEnum, SlideStateEnum } from 'src/app/models';

declare global {
  interface Window {
    MathJax: {
      tex: {inlineMath: [['$', '$'], ['\\(', '\\)']]}
      processEscapes: true,
      typesetPromise: () => void;
      typeset: any;
    };
  }
}



@Component({
  selector: 'app-show-question',
  templateUrl: './show-question.component.html',
  styleUrls: ['./show-question.component.css']
})
export class ShowQuestionComponent implements OnInit, AfterViewInit {

  public _component!:FlatComponent | FlatMarker;

  public hasHint:boolean = false;

  public displayMsgg:boolean = false;
  
  //Declare slide variable of type IFlatSlide
  private _slide! : IFlatSlide;
  //create @input variable for slide
  @Input() set slide(slide:IFlatSlide){
    this._slide = slide;
    let s = this._slide.slide;
    //check if the first componnet has hint set to true
    if( s.components.length > 0 ){
      this.hasHint = s.components[1].hint
    }    

  }

  public question!:IPQuestionComponent  | IPTikzQuestionComponent;

  public checkBoxAnswers:boolean[] = [];
  public radioAnswer:number = -1; 

  public isAssessmentQuestion:boolean = false;

  public questionAnswered:boolean = false;

  questionTypeEnum = QuestionType;

  get component():any{
    return this._component;
  }


  @Input() set component(slide:any){
    
    //If question is followed by the slide component is not rendered again
    //so init is not called. In that case clear the state of the previous question. 
    this.questionAnswered = false;
    setTimeout( ()=> {
      this.radioAnswer = -1;
      this.updateQuestionFromCache();
    }, 0 )
    this.setQuestion(slide);
    //update question from cache

    if( !this.questionAnswered ){
      //if question type is checkbox then set the checkboxAsswers to the cache
      if( this.question.qType == QuestionType.checkbox ){
        //set the checkboxAsswers to array of false
        this.checkBoxAnswers = new Array(this.question.answers.length).fill(false); 
      }
    }

    this.resetNavigator();

  }  


  @Input() index: number = 0;
  private _showPaginator:boolean = false;
  get showPaginator(){
    return this._showPaginator;
  }

  @Input() set showPaginator(showPaginator:boolean){
    this._showPaginator = showPaginator;
  }
  @Input()  paginatorComponent!:FlatComponent;

  
  constructor(
    public spSvc:ShowPresentationService,
    public qCacheSvc:QuestionCacheService,
    public scoreSvc:ScoreService,
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    //this.resetNavigator();
  }

  resetNavigator(){
    //Show presentation and show slide set the showHidePaginator
    //to true. This function in theory should fire after them 
    //and set the right state. However, it seems that 
    //show slide gets fired after this. 
    //To compensate for delay fire it multiple times
    [0, .5, 1,1.5].map( (i:number) => {
      setTimeout(() => {
        if( !this.questionAnswered ){
          this.spSvc.showHidePaginator$.next(false);
        }
        else {
          this.spSvc.showHidePaginator$.next(true);
        }
      }, i * 1000);
    } );
  }

  ngAfterViewInit(): void {
    try{
      let textDiv = document.getElementById(`explain_11`);
      if( textDiv ){
        textDiv!.style.top =  "209px";
      }
    }
    catch(e){
      console.log(e);
    }
    finally{
      this.mathjax();
    }
  }


  setQuestion(slide:any){
    this._component = slide as FlatComponent;
    if( this._component.component.type == PSlideComponentEnum.question )
    {
      this.question = this._component.component as IPQuestionComponent;
      if( this.question.assessment  ){
        this.isAssessmentQuestion = true;
      }
    }
    else if(  this._component.component.type == PSlideComponentEnum.tikzQuestion ){
      this.question = this._component.component as IPTikzQuestionComponent;
    }
  }

  updateQuestionFromCache(){
    let cache = this.qCacheSvc.get(this.question.id);
    if( cache ){
      //if question type is checkbox then set the checkboxAsswers to the cache
      if( this.question.qType == QuestionType.checkbox ){
        this.checkBoxAnswers = cache;
      }//else if question type is radio then set the radioAnswer to the cache
      else if( this.question.qType == QuestionType.radio ){
        this.radioAnswer = cache as number;
      }
      this.questionAnswered = true;
    }
  }

  onQuestionChangedRadio(index:number, event:any){
    if( event.srcElement.checked ){
      this.radioAnswer = index;
    }
  }


  onQuestionChangedCheckbox(index:number, event:any){
    this.checkBoxAnswers[index] = event.srcElement.checked;
  }

  questionAnsweredEvent(question:IPQuestionComponent  | IPTikzQuestionComponent, 
                  score:number,
                  answers:any){

    this.qCacheSvc.set(question.id, answers);
    //call updatescore method from score service
    this.scoreSvc.updateUserScore(question as IPQuestionComponent, score);
  }


  verify(){
    let currentState = this.scoreSvc.assessmentDetails.getCurrentSlide();
    if( currentState?.state !== SlideStateEnum.init && this.isAssessmentQuestion   ){
      let message$ = new BehaviorSubject<string>("Question has alread been answered.");
      this.dialog.open( MessageComponent, {
        data: message$
      } )
      
    }
    else if(  this.question.qType == QuestionType.radio ){
      if( this.radioAnswer == -1 ){
        alert("Please select an answer");
        return
      }
      if( this.question.answers[this.radioAnswer].value  ){
        this.questionAnsweredEvent(this.question, 1, this.radioAnswer);
      }
      else{
        this.questionAnsweredEvent(this.question, -.25, this.radioAnswer);
      }
      this.questionAnswered = true;

    }
    else if( this.question.qType == QuestionType.checkbox ){
      //check if all elements in checkboxAnswers are false
      let allFalse = true;
      this.checkBoxAnswers.forEach(a=>{
        if( a ){
          allFalse = false;
        }
      });
      if( allFalse ){
        alert("Please select an answer");
        return;
      }


      let correct = true;
      //Iterate over question amswers and check if the same index in checkboxAnswers is true
      for( let i = 0; i < this.question.answers.length; i++ ){
        if( this.question.answers[i].value !== this.checkBoxAnswers[i] ){
          correct = false;
          break;
        }
      }
      if( correct ){
          //call questionAnsweredEvent with score 1 and checkboxAnswers
          this.questionAnsweredEvent(this.question, 1, this.checkBoxAnswers);
      }
      else{
        //call questionAnsweredEvent with score 0 and checkboxAnswers
        this.questionAnsweredEvent(this.question, -.25, this.checkBoxAnswers);
      }
      
      this.questionAnswered = true;
    }

    this.spSvc.showHidePaginator$.next(true);

  }

  public mathjax(){
    let p = window.MathJax.typesetPromise() as any;
    //resolve the promiose p 
    p.then(() => {
    })
    .catch((err:any) => {
      console.log(err);
    });
  }


  showHint()
  {
    let currentState = this.scoreSvc.assessmentDetails.getCurrentSlide();
    if( currentState?.state === SlideStateEnum.init  ){
      let message$ = new BehaviorSubject<string>("2 marks will be deducted for using hint. Do you wish to continue?");
      this.dialog.open( MessageChoiceComponent, {
        data: message$
      } )
      .afterClosed().subscribe( (result:string) => {
        if( result == 'continue' ){
          (this._component.component as IPQuestionComponent).hintShown = true;
          this.spSvc.showHint$.next(true);
          }
      });
    }
    else {
      (this._component.component as IPQuestionComponent).hintShown = true;
      this.spSvc.showHint$.next(true);
    }
  }

  showSolution(){

    let currentState = this.scoreSvc.assessmentDetails.getCurrentSlide();
    if( currentState?.state === SlideStateEnum.init  ){
      let message$ = new BehaviorSubject<string>("You will not get marks if you see solution. Do you wish to continue?");
      this.dialog.open( MessageChoiceComponent, {
        data: message$
      } )
      .afterClosed().subscribe( (result:string) => {
        if( result == 'continue' ){
          (this._component.component as IPQuestionComponent).solutionShown = true;
          this.scoreSvc.assessmentDetails.processQuestion(null, 0, SlideEventEnum.showSolution);
          this.spSvc.showHint$.next(true);
          }
      });
    }
    else {
      (this._component.component as IPQuestionComponent).solutionShown = true;
      this.scoreSvc.assessmentDetails.processQuestion(null, 0, SlideEventEnum.showSolution);
      this.spSvc.showHint$.next(true);
    }
  }
}
