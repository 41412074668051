<div>
    <button (click)="save()">Process</button>
    <button (click)="get()">Get SVG</button>
    <button (click)="drawArrow()">Draw Arrow</button>
</div>

<div class="columns">
    <div>
        <textarea [(ngModel)]="obj.svg"  
        name="svg" id="svg" cols="30" rows="10"></textarea>
    </div>
    <div class="row">
        <div class="row-children">
            <div id="svgContainer" style="width: 100%;"></div>
        </div>
        <div class="row-children">
                <div class="explain" id="text">
                    Here is some text
                </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" 
            width="100%" 
            height="100%"
            class="arrow"
            >
            <defs>
                <marker id="arrowhead" viewBox="0 0 10 10" refX="3" refY="5"
                    markerWidth="6" markerHeight="6" orient="auto">
                <path d="M 0 0 L 10 5 L 0 10 z" />
                </marker>
            </defs>
            <g fill="none" stroke="black" stroke-width="2" marker-end="url(#arrowhead)">
                <path id="arrowRight"/>
            </g>
            </svg>
    </div>
    
</div>
<!--
<div class="columns">
    <div>
        <button (click)="process()">Process</button>
        <button (click)="drawArrow()">Draw Arrow</button>
    </div>
    <div class="row">
            <div class="row-children">
                <svg version='1.1' 
                xmlns='http://www.w3.org/2000/svg' 
                xmlns:xlink='http://www.w3.org/1999/xlink' 
                viewBox='76.710908 56.245995 159.403681 300'
                class="svg-wrapper">
                    <g id='page1'>
                    <g id='plane'>
                    <path d='M76.910158 117.6523L146.5742 68.875V117.6523H76.910158Z' fill='#e6e6ff'/>
                    <path d='M76.910158 117.6523L146.5742 68.875V117.6523H76.910158Z' stroke='#000' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                    <path d='M109.418 77.5898L97.8086 85.71875L105.9375 97.32813L117.5469 89.19922Z' fill='#bfbfbf'/>
                    <g fill='none' stroke-miterlimit='10'>
                    <path d='M109.418 77.5898L97.8086 85.71875L105.9375 97.32813L117.5469 89.19922Z' stroke='#000' stroke-width='.3985'/>
                    <path d='M146.5742 68.875L152.3789 64.8086M159.4648 64.8086C159.4648 60.8945 156.293 57.7227 152.3789 57.7227S145.293 60.8945 145.293 64.8086S148.4648 71.8984 152.3789 71.8984S159.4648 68.7227 159.4648 64.8086Z' stroke='#000' stroke-width='.79701'/>
                    <path d='M113.6445 83.27735L148.4766 58.8906C151.6836 56.6445 156.1016 57.4258 158.3477 60.6328C159.1797 61.8203 159.6289 63.2422 159.6289 64.6953V93.04297' stroke='#f00' stroke-width='.79701'/>
                    </g>
                    <path d='M155.375 97.29297H163.8789V88.789064H155.375Z' fill='#bfbfbf'/>
                    <g stroke='#000' fill='none' stroke-miterlimit='10'>
                    <path d='M155.375 97.29297H163.8789V88.789064H155.375Z' stroke-width='.3985'/>
                    <path d='M91.0859 117.6523C91.0859 114.7422 90.1914 111.9063 88.7852 109.8984' stroke-width='.3985'/>
                    <path d='M88.16403 111.793119C88.597624 111.367346 88.703091 109.953284 88.613249 109.652497C88.867159 109.839997 90.230431 110.222813 90.777304 109.961089' stroke-width='.318784' stroke-linecap='round' stroke-linejoin='round'/>
                    </g>
                    <text class='f3' x='76.911579' y='89.305242' transform='translate(15.0624 24.7318)'>α</text>
                    </g>
                    <text class='f5' x='76.712329' y='129.806104'>here<tspan x='98.325775'>go</tspan><tspan x='108.565201'>es</tspan><tspan x='120.243645'>something</tspan></text>
                    <g id='m'>
                    <path d='M125.89453 176.16015L114.28516 184.28906L122.41406 195.89843L134.02344 187.76952Z' fill='#bfbfbf'/>
                    <g fill='none' stroke-miterlimit='10'>
                    <path d='M125.89453 176.16015L114.28516 184.28906L122.41406 195.89843L134.02344 187.76952Z' stroke='#000' stroke-width='.3985'/>
                    <path d='M140.4141 209.25L91.8984 139.9648' stroke='#808080' stroke-width='.3985' stroke-dasharray='2.98883 1.99255'/>
                    <path d='M91.277369 141.855819C91.71097 141.433947 91.816445 140.019881 91.726596 139.719098C91.980504 139.906609 93.343778 140.289413 93.890658 140.027697' stroke='#808080' stroke-width='.318784' stroke-linecap='round' stroke-linejoin='round'/>
                    </g>
                    <text class='f1' x='124.154051' y='186.028635' transform='translate(-28.9984 -44.656)' fill='#808080'>+</text>
                    <text class='f0' x='131.32157' y='186.028635' transform='translate(-28.9984 -44.656)' fill='#808080'>y</text>
                    <path d='M130.12109 181.85156L170.2188 153.7734' stroke='#808080' fill='none' stroke-width='.3985' stroke-miterlimit='10' stroke-dasharray='2.98883 1.99255'/>
                    <path d='M168.323877 153.152675C168.749656 153.586277 170.163728 153.691737 170.464503 153.601904C170.276998 153.855807 169.89419 155.219085 170.155905 155.765964' stroke='#808080' fill='none' stroke-width='.318784' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/>
                    <text class='f1' x='124.154051' y='186.028635' transform='translate(49.961 -30.277)' fill='#808080'>+</text>
                    <text class='f0' x='131.32157' y='186.028635' transform='translate(49.961 -30.277)' fill='#808080'>x</text>
                    <path d='M124.152344 200.2031C127.0625 200.2031 129.90234 199.3086 131.5 198.1875' stroke='#808080' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                    <path d='M129.605379 197.566678C130.031163 198.000268 131.441319 198.105749 131.746004 198.015888C131.558504 198.269798 131.175687 199.63308 131.433504 200.179952' stroke='#808080' fill='none' stroke-width='.318784' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/>
                    <text class='f0' x='124.154051' y='186.028635' transform='translate(2.584 19.502)' fill='#808080'>α</text>
                    <path d='M124.152344 186.027337L112.8906 169.9453' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                    <path d='M110.835524 167.007956C111.280824 167.992321 111.780839 169.750152 111.898014 171.12903L114.343322 169.414204C113.089425 168.836081 111.608959 167.765772 110.835524 167.007956' fill='#00f'/>
                    <text class='f3' x='124.154051' y='186.028635' transform='translate(-9.799 -22.541)'>N</text>
                    <path d='M118.1875 190.20702L97.9023 204.4101' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                    <path d='M94.964821 206.468718C95.94921 206.019494 97.707007 205.519498 99.08592 205.406217L97.371072 202.957007C96.792945 204.214822 95.722627 205.695286 94.964821 206.468718' fill='#00f'/>
                    <text class='f3' x='124.154051' y='186.028635' transform='translate(-44.1218 22.929)'>f</text>
                    <text class='f4' x='129.031619' y='187.523015' transform='translate(-44.1218 22.929)'>R</text>
                    <path d='M130.12109 181.85156L150.4063 167.6484' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                    <path d='M153.343964 165.589892C152.359603 166.039102 150.601784 166.539107 149.222875 166.652379L150.933807 169.101598C151.515842 167.843791 152.586161 166.363328 153.343964 165.589892' fill='#00f'/>
                    <text class='f3' x='124.154051' y='186.028635' transform='translate(25.586 -23.958)'>T</text>
                    </g>
                    <path d='M124.152344 186.027337V210.7891' fill='#00f'/>
                    <path d='M124.152344 186.027337V210.7891' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                    <path d='M124.152344 214.37474C124.351563 213.31224 124.949219 211.585677 125.64844 210.390364H122.66016C123.355469 211.585677 123.953126 213.31224 124.152344 214.37474' fill='#00f'/>
                    <text class='f3' x='124.154051' y='186.028635' transform='translate(-7.93 38.675)'>M<tspan x='134.905394'>g</tspan></text>
                    <g id='M'>
                    <path d='M86.46875 282.33592H94.97266V273.83202H86.46875Z' fill='#bfbfbf'/>
                    <g fill='none' stroke-miterlimit='10'>
                    <path d='M86.46875 282.33592H94.97266V273.83202H86.46875Z' stroke='#000' stroke-width='.3985'/>
                    <path d='M90.71875 282.53514V334.3203' stroke='#808080' stroke-width='.3985' stroke-dasharray='2.98883 1.99255'/>
                    <path d='M92.3125 333.12463C91.714843 333.22229 90.820312 334.319946 90.71875 334.616821C90.621093 334.319946 89.722656 333.22229 89.125 333.12463' stroke='#808080' stroke-width='.31879' stroke-linecap='round' stroke-linejoin='round'/>
                    </g>
                    <text class='f1' x='90.720148' y='278.08432' transform='translate(-10.6874 58.935)' fill='#808080'>+</text>
                    <path d='M90.71875 273.6328V248.8711' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                    <path d='M90.71875 245.2847C90.519531 246.3472 89.921875 248.073767 89.22656 249.269079H92.21484C91.515625 248.073767 90.917968 246.3472 90.71875 245.2847' fill='#00f'/>
                    <text class='f3' x='90.720148' y='278.08432' transform='translate(-5.0004 -36.318)'>T</text>
                    <text class='f2' x='97.925791' y='274.468956' transform='translate(-5.0004 -36.318)'></text>
                    <path d='M90.71875 282.53514V307.2969' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                    <path d='M90.71875 310.88325C90.917968 309.82075 91.515625 308.094187 92.21484 306.898875H89.22656C89.921875 308.094187 90.519531 309.82075 90.71875 310.88325' fill='#00f'/>
                    <text class='f3' x='90.720148' y='278.08432' transform='translate(3.5198 33.974)'>mg</text>
                    </g>
                    </g>
            </svg>
            </div>
            <div class="row-children">
                Here is some text we want to go
            </div>
    </div>
    <div class="row">
        <div class="row-children">
            <svg version='1.1' 
            xmlns='http://www.w3.org/2000/svg' 
            xmlns:xlink='http://www.w3.org/1999/xlink' 
            viewBox='76.710908 56.245995 159.403681 300'
            class="svg-wrapper">
            <g id='page1'>
                <g id='plane'>
                <path d='M76.910158 117.6523L146.5742 68.875V117.6523H76.910158Z' fill='#e6e6ff'/>
                <path d='M76.910158 117.6523L146.5742 68.875V117.6523H76.910158Z' stroke='#000' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                <path d='M109.418 77.5898L97.8086 85.71875L105.9375 97.32813L117.5469 89.19922Z' fill='#bfbfbf'/>
                <g fill='none' stroke-miterlimit='10'>
                <path d='M109.418 77.5898L97.8086 85.71875L105.9375 97.32813L117.5469 89.19922Z' stroke='#000' stroke-width='.3985'/>
                <path d='M146.5742 68.875L152.3789 64.8086M159.4648 64.8086C159.4648 60.8945 156.293 57.7227 152.3789 57.7227S145.293 60.8945 145.293 64.8086S148.4648 71.8984 152.3789 71.8984S159.4648 68.7227 159.4648 64.8086Z' stroke='#000' stroke-width='.79701'/>
                <path d='M113.6445 83.27735L148.4766 58.8906C151.6836 56.6445 156.1016 57.4258 158.3477 60.6328C159.1797 61.8203 159.6289 63.2422 159.6289 64.6953V93.04297' stroke='#f00' stroke-width='.79701'/>
                </g>
                <path d='M155.375 97.29297H163.8789V88.789064H155.375Z' fill='#bfbfbf'/>
                <g stroke='#000' fill='none' stroke-miterlimit='10'>
                <path d='M155.375 97.29297H163.8789V88.789064H155.375Z' stroke-width='.3985'/>
                <path d='M91.0859 117.6523C91.0859 114.7422 90.1914 111.9063 88.7852 109.8984' stroke-width='.3985'/>
                <path d='M88.16403 111.793119C88.597624 111.367346 88.703091 109.953284 88.613249 109.652497C88.867159 109.839997 90.230431 110.222813 90.777304 109.961089' stroke-width='.318784' stroke-linecap='round' stroke-linejoin='round'/>
                </g>
                <text class='f3' x='76.911579' y='89.305242' transform='translate(15.0624 24.7318)'>α</text>
                </g>
                <text class='f5' x='76.712329' y='129.806104'>here<tspan x='98.325775'>go</tspan><tspan x='108.565201'>es</tspan><tspan x='120.243645'>something</tspan></text>
                <g id='m'>
                <path d='M125.89453 176.16015L114.28516 184.28906L122.41406 195.89843L134.02344 187.76952Z' fill='#bfbfbf'/>
                <g fill='none' stroke-miterlimit='10'>
                <path d='M125.89453 176.16015L114.28516 184.28906L122.41406 195.89843L134.02344 187.76952Z' stroke='#000' stroke-width='.3985'/>
                <path d='M140.4141 209.25L91.8984 139.9648' stroke='#808080' stroke-width='.3985' stroke-dasharray='2.98883 1.99255'/>
                <path d='M91.277369 141.855819C91.71097 141.433947 91.816445 140.019881 91.726596 139.719098C91.980504 139.906609 93.343778 140.289413 93.890658 140.027697' stroke='#808080' stroke-width='.318784' stroke-linecap='round' stroke-linejoin='round'/>
                </g>
                <text class='f1' x='124.154051' y='186.028635' transform='translate(-28.9984 -44.656)' fill='#808080'>+</text>
                <text class='f0' x='131.32157' y='186.028635' transform='translate(-28.9984 -44.656)' fill='#808080'>y</text>
                <path d='M130.12109 181.85156L170.2188 153.7734' stroke='#808080' fill='none' stroke-width='.3985' stroke-miterlimit='10' stroke-dasharray='2.98883 1.99255'/>
                <path d='M168.323877 153.152675C168.749656 153.586277 170.163728 153.691737 170.464503 153.601904C170.276998 153.855807 169.89419 155.219085 170.155905 155.765964' stroke='#808080' fill='none' stroke-width='.318784' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/>
                <text class='f1' x='124.154051' y='186.028635' transform='translate(49.961 -30.277)' fill='#808080'>+</text>
                <text class='f0' x='131.32157' y='186.028635' transform='translate(49.961 -30.277)' fill='#808080'>x</text>
                <path d='M124.152344 200.2031C127.0625 200.2031 129.90234 199.3086 131.5 198.1875' stroke='#808080' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                <path d='M129.605379 197.566678C130.031163 198.000268 131.441319 198.105749 131.746004 198.015888C131.558504 198.269798 131.175687 199.63308 131.433504 200.179952' stroke='#808080' fill='none' stroke-width='.318784' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/>
                <text class='f0' x='124.154051' y='186.028635' transform='translate(2.584 19.502)' fill='#808080'>α</text>
                <path d='M124.152344 186.027337L112.8906 169.9453' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                <path d='M110.835524 167.007956C111.280824 167.992321 111.780839 169.750152 111.898014 171.12903L114.343322 169.414204C113.089425 168.836081 111.608959 167.765772 110.835524 167.007956' fill='#00f'/>
                <text class='f3' x='124.154051' y='186.028635' transform='translate(-9.799 -22.541)'>N</text>
                <path d='M118.1875 190.20702L97.9023 204.4101' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                <path d='M94.964821 206.468718C95.94921 206.019494 97.707007 205.519498 99.08592 205.406217L97.371072 202.957007C96.792945 204.214822 95.722627 205.695286 94.964821 206.468718' fill='#00f'/>
                <text class='f3' x='124.154051' y='186.028635' transform='translate(-44.1218 22.929)'>f</text>
                <text class='f4' x='129.031619' y='187.523015' transform='translate(-44.1218 22.929)'>R</text>
                <path d='M130.12109 181.85156L150.4063 167.6484' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                <path d='M153.343964 165.589892C152.359603 166.039102 150.601784 166.539107 149.222875 166.652379L150.933807 169.101598C151.515842 167.843791 152.586161 166.363328 153.343964 165.589892' fill='#00f'/>
                <text class='f3' x='124.154051' y='186.028635' transform='translate(25.586 -23.958)'>T</text>
                </g>
                <path d='M124.152344 186.027337V210.7891' fill='#00f'/>
                <path d='M124.152344 186.027337V210.7891' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                <path d='M124.152344 214.37474C124.351563 213.31224 124.949219 211.585677 125.64844 210.390364H122.66016C123.355469 211.585677 123.953126 213.31224 124.152344 214.37474' fill='#00f'/>
                <text class='f3' x='124.154051' y='186.028635' transform='translate(-7.93 38.675)'>M<tspan x='134.905394'>g</tspan></text>
                <g id='M'>
                <path d='M86.46875 282.33592H94.97266V273.83202H86.46875Z' fill='#bfbfbf'/>
                <g fill='none' stroke-miterlimit='10'>
                <path d='M86.46875 282.33592H94.97266V273.83202H86.46875Z' stroke='#000' stroke-width='.3985'/>
                <path d='M90.71875 282.53514V334.3203' stroke='#808080' stroke-width='.3985' stroke-dasharray='2.98883 1.99255'/>
                <path d='M92.3125 333.12463C91.714843 333.22229 90.820312 334.319946 90.71875 334.616821C90.621093 334.319946 89.722656 333.22229 89.125 333.12463' stroke='#808080' stroke-width='.31879' stroke-linecap='round' stroke-linejoin='round'/>
                </g>
                <text class='f1' x='90.720148' y='278.08432' transform='translate(-10.6874 58.935)' fill='#808080'>+</text>
                <path d='M90.71875 273.6328V248.8711' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                <path d='M90.71875 245.2847C90.519531 246.3472 89.921875 248.073767 89.22656 249.269079H92.21484C91.515625 248.073767 90.917968 246.3472 90.71875 245.2847' fill='#00f'/>
                <text class='f3' x='90.720148' y='278.08432' transform='translate(-5.0004 -36.318)'>T</text>
                <text class='f2' x='97.925791' y='274.468956' transform='translate(-5.0004 -36.318)'></text>
                <path d='M90.71875 282.53514V307.2969' stroke='#00f' fill='none' stroke-width='.3985' stroke-miterlimit='10'/>
                <path d='M90.71875 310.88325C90.917968 309.82075 91.515625 308.094187 92.21484 306.898875H89.22656C89.921875 308.094187 90.519531 309.82075 90.71875 310.88325' fill='#00f'/>
                <text class='f3' x='90.720148' y='278.08432' transform='translate(3.5198 33.974)'>mg</text>
                </g>
                </g>
        </svg>
        
        </div>
        <div class="row-children">
            Here is some text we want to go
        </div>
    </div>
</div>
-->


<!-- This file was generated by dvisvgm 2.11.1 -->

