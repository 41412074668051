import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ignoreElements, takeUntil } from 'rxjs/operators';
import { ScoreService } from 'src/app/common/services/score/score.service';
import { ShowPresentationRouteService, ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { FlatComponent, FlatSlide, IFlatSlide, IPQuestionComponent, KeyValue, PSlideComponentEnum } from 'src/app/models';

@Component({
  selector: 'app-show-slide',
  templateUrl: './show-slide.component.html',
  styleUrls: ['./show-slide.component.css']
})
export class ShowSlideComponent implements OnInit, OnDestroy, AfterViewInit{

  public isTheory:boolean = false;
  
  public components$:Subject<FlatComponent[]> = new Subject();
  private ngUnsubscribe$ = new Subject();
  public slideComponentEnum = PSlideComponentEnum;
  public currentRoute!:KeyValue<string, number>[];

  public slide!:IFlatSlide
  

  private suppressNavigation:boolean = false;

  public inAssessmentMode:boolean = false;

  public currentVisibleComponentIndex:number = 0;


  public firstRouteLoaded = true;

  @ViewChild('stepper') stepper!: MatStepper;

  constructor(
    private routerSvc: ShowPresentationRouteService,
    private route: ActivatedRoute,
    private router: Router,
    private spSvc: ShowPresentationService,
    private scoreSvc: ScoreService,
  ) { }
  
  ngAfterViewInit(): void {
  }


  ngOnInit(): void {

    this.spSvc.showHint$
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe( (show) => {
      if( !show){
        return
      }
      try{
      let url = `slide/${this.currentRoute[0].value}/c/1/sc/0`;
      this.router.navigate([url], { relativeTo: this.route.parent });
      }catch(err){
        console.log(err);
      }
    });
    
    //wait for presentation to be loaded
    this.spSvc.presentationLoaded$
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe( 
      (loaded) => {
        if(loaded){
          this.isTheory = this.spSvc.flatPresentation.isTheory();
          //If loaded get the components for the current slide
          this.routerSvc.routeChanged$
          .pipe( takeUntil( this.ngUnsubscribe$ ) )
          .subscribe( (routeInfo:KeyValue<string, number>[]) => {
            this.currentRoute = routeInfo;
            if(routeInfo.length === 0){
              //Dont know what to do 
            }
            else {
              let i = routeInfo[0].value;
              this.spSvc.currentSlide$.next(i);
              this.slide = this.spSvc.flatPresentation.slides[i];
              this.currentVisibleComponentIndex = routeInfo[1].value;
              this.initUI(i);
              this.loadComponents(i);
              setTimeout(() => {
                this.initStepper();
                this.firstRouteLoaded = false;
              }, 10);
              
              setTimeout(() => {
                this.mathjax();
              }, 200);
            }
          });
        }
      }
    )
  }


  initUI(i:number){
    if(  !this.firstRouteLoaded ){
      return;
    }


    if( this.currentRoute[1].value == 0 ){
      //We are on the first step so no need to check for assessment
      return;
    }
    //get the first component 
    let fc = this.spSvc.flatPresentation.slides[i].components[0];
    //check if its question as well as assessment
    let c = fc.component;
    if( c.type === this.slideComponentEnum.question &&
        (c as IPQuestionComponent).assessment){
          //Question is assessment and we are not on first step
          //so expand the question 
          if((c as IPQuestionComponent).hintShown || (c as IPQuestionComponent).solutionShown){
            return 
          }
          (c as IPQuestionComponent).solutionShown = true;
    }
    else {
      return 
    }
  }

  initStepper(){
    //We navigate through whole presentation by changing route
    //However user might land up directly by refreshing the page
    if( this.firstRouteLoaded && this.currentRoute[1].value != 0){
      //User has refreshed the page with some component
      //which is not the first component
      //We will switch to stepper step so suppress the navigation
      this.suppressNavigation = true
    } 
    //switch to the selected step.
    try {
      this.stepper.selectedIndex = this.currentRoute[1].value;        
    } catch (error) {
      //Very treacherous in case assessment question 
      //flipping between question/hint and solution
    }
    
  }

  //step change event
  stepChanged($event:StepperSelectionEvent){
      this.spSvc.showHidePaginator$.next(true);
      //We are changing the stepper step. Here we need to distinguish
      //between user action vs landing on this route
      if( !this.suppressNavigation){
        let url = `slide/${this.currentRoute[0].value}/c/${$event.selectedIndex}/sc/0`;
        this.router.navigate([url], { relativeTo: this.route.parent });
        return;
      }
      this.suppressNavigation = false;
      this.spSvc.stepperSlideChanged$.next($event.selectedIndex);
  }


  loadComponents(i:number){
    //Get the first component of the slide to see if its assessment
    let fc = this.spSvc.flatPresentation.slides[i].components[0];
    let c = fc.component;
    let resumeNormalFlow = true;
    let arr:FlatComponent[] = [];
    if( c.type === this.slideComponentEnum.question && 
       (c as IPQuestionComponent).assessment){
      {
        let q = c as IPQuestionComponent;

        resumeNormalFlow = false;
        this.inAssessmentMode = true; 
        //We have assessment 
        //push the first component from slide components to arr
        arr.push((this.spSvc.flatPresentation.slides[i].components[0] as any) as FlatComponent);
        if( q.hintShown  ){
          //Push the hint component
          arr.push((this.spSvc.flatPresentation.slides[i].components[1] as any) as FlatComponent);
        }
        if( q.solutionShown  ){
          //general case
          arr = this.spSvc.flatPresentation.slides[i].components as FlatComponent[];
        }

        this.components$.next(arr);
      }
    }

    if( resumeNormalFlow ){
      this.components$.next( 
        this.spSvc.flatPresentation.slides[i].components as FlatComponent[] 
      );
    }
  }

  ngOnDestroy(){
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public mathjax(){
    let p = window.MathJax.typesetPromise() as any;
    //resolve the promiose p 
    p.then(() => {
    })
    .catch((err:any) => {
      console.log(err);
    });
  }
}
