import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-message-choice',
  templateUrl: './message-choice.component.html',
  styleUrls: ['./message-choice.component.css']
})
export class MessageChoiceComponent implements OnInit {



  constructor(
    public dialogRef: MatDialogRef<MessageChoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public message$: BehaviorSubject<string>
  ) { }

  ngOnInit(): void {

  }

  closeDialog(ret:string) {
    this.dialogRef.close(ret);
  }

}
