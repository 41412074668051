import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore'
import { AngularFireStorage } from '@angular/fire/storage';
import {  from,  Subject } from 'rxjs';
import { IAssessmentDetail, IDoc, IPresentation, Presentation } from 'src/app/models';
import { finalize, last, switchMap } from 'rxjs/operators';

import * as firebase from 'firebase/app';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private collName = "test";
  private _performanceCollName = "performance";
  private _presentationCollName = "Presentation";
  private _tagsCollName = "Tags";


  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private userSvc: UserService,
  ) { }
  

  //set timestamp to any object
  setTimestamp(obj:any){
    obj.timestamp = Date.now()
  }


  saveFireStoreDoc<T extends IDoc>(doc:T , collName:string){
    this.setTimestamp(doc);
    return from(this.firestore.collection(collName)
    .doc(doc.firebaseId)
    .set(doc.toJson()))
  }

  getFireStoreDoc(id:string, collName:string):Subject<any>{
    let s = new Subject<any>();
    this.firestore.collection(collName)
    .doc(id)
    .get()
    .subscribe( (doc) => {
      s.next(doc);
      s.complete();
    });
    return s; 
  }

  createFireStoreDoc<T extends IDoc>(doc:T, collName:string):Subject<T>{
    let data = doc.toJson();
    let s = new Subject<T>();
    this.setTimestamp(data);
    this.firestore.collection(collName)
    .add(data)
    .then( (docRef) => {
      data.firebaseId = docRef.id;
      this.firestore.collection(collName)
      .doc(data.firebaseId)
      .set(data)
      .then( () => {
          s.next(data);
          s.complete();
      })
    });
    return s;
  }

  //create new presentation, save it firebase and return Subject
  //with presentation
  createPresentation(presentation: Presentation){
    return this.createFireStoreDoc(presentation, this._presentationCollName)
  }

  //save presentation to  firebase and return observable
  savePresentation(presentation: Presentation){
    return this.saveFireStoreDoc(presentation, this._presentationCollName);
  }

  //get presentation from firebase and return Subject with presentation
  getPresentation(id: string){
    let s = new Subject<Presentation>()
    this.getFireStoreDoc(id, this._presentationCollName)
    .subscribe( (doc:any) => {
      let title = "";
      let data = doc.data();
      //declare presentation variable and set it doc data
      let ip = data as IPresentation
      //if doc data has title property than update title
      if( ip.title ){
        title = (doc.data() as any).title;
      }

      let presentation = new Presentation(title, ip.tag);
      presentation.firebaseId = doc.id;
      presentation.copy(ip);
      s.next(presentation);
    })

    return s

  }

  //get firestore document by applying filter on field
  //passed as parameter
  getFirestoreDocsByField(field:string, value:any, collName:string)
  :Promise<firebase.firestore.QuerySnapshot<unknown>>{
    let ref = this.firestore
            .collection(collName)
            .ref
    return ref.where(field, '==', value)
    .limit(1)
    .get();
    

  }


  addTestDoc( json:any ){
    this.firestore.collection(this.collName)
    .add(json)
    .then( (d) => {
        console.log(d.id);
    } )
  }

  getTestDoc(id:string){
      return this.firestore.collection("test")
      .doc(id)
      .get()
  }

  //upload image to angular fire storage and return observable with url
  uploadImage(filePath:string, file_url:any){
    let s = new Subject<string>();
    let fileRef = this.storage.ref(filePath);
    var metaData = {
      cacheControl: 'public, max-age=14400, s-maxage=14400',
    };
    let task = fileRef.putString(file_url, 'data_url', metaData);
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe( (url) => {
          s.next(url);
        })
      })
    ).subscribe();
    return s;
  }

  


  getFirestoreDocsForAuthor(authorId:string,tag:string, collName:string)
  :Promise<firebase.firestore.QuerySnapshot<unknown>>{
    return this.firestore.collection(collName)
        .ref
        .where("authorId", "==", authorId)
        .where("tag", "==", tag)
        .orderBy("timestamp", "desc")
        .get()
  }

  //get all presentations from firestore with 
  //authorId passed in parameter
  getPresentationsForAuthor(authorId:string, tag:string){
    let s = new Subject<Presentation[]>();
    this.getFirestoreDocsForAuthor(authorId, tag, this._presentationCollName)
    .then( (querySnapshot) => {
      let presentations:Presentation[] = [];
      querySnapshot.forEach( (doc) => {
        let ip = doc.data() as IPresentation;
        let presentation = new Presentation("", ip.tag);
        presentation.copy(ip);
        presentations.push(presentation);
      });
      s.next(presentations);
    });
    return s;
  }


  getPerformance(userId:string, firebaseId:string){
      return this.firestore
      .collection(this._performanceCollName)
      .doc(userId)
      .collection(this._presentationCollName)
      .doc(firebaseId)
      .get();
  }

  savePerformance(userId:string, data:IAssessmentDetail){
    return this.firestore
    .collection(this._performanceCollName)
    .doc(userId)
    .collection(this._presentationCollName)
    .doc(data.firebaseId)
    .set(data);
  }

}


