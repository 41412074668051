<mat-card>
    <mat-card-header>
        <mat-card-title >Performance Analysis</mat-card-title>
      </mat-card-header>
    <mat-card-content>
        <app-rules-score [rulesScore]="rulesScore" ></app-rules-score>
    </mat-card-content>    

    <mat-card-actions>
        <button mat-button color="primary"
        (click)="next()">Next</button>
    </mat-card-actions>
</mat-card>

