<div class="columns">
    <div class="row">
        <div class="svg row-children">
            <svg id="svg_{{imageComponent.id}}" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" 
                version="1.1" baseProfile="full" 
                [attr.viewBox] = "viewBox"
                >
                    <image 
                        (load)="imageLoaded($event)"
                        id="svg_{{imageComponent.id}}_img" 
                        x="0" 
                        y="0" 
                        [attr.href] = "imageComponent?.src"
                    ></image>
                </svg> 
        </div>
        <div class="marker-info row-children">
            <div  
                *ngIf="textComponent?.type === slideComponentEnum.text" 
                class="explain" id="explain_{{imageComponent.id}}"  
                >
                <app-show-text 
                    [component]="subComponent"
                    [index]="curentMarkerIndex"
                    [paginatorComponent] = "component"
                    [showPaginator] = true 
                >
                </app-show-text>
            </div>
            <div class="explain" 
                id="explain_{{imageComponent.id}}" 
                *ngIf="textComponent?.type === slideComponentEnum.question" >
                <app-show-question 
                    [component]="subComponent" 
                    [index]="curentMarkerIndex"
                    [showPaginator] = true 
                    [paginatorComponent] = "component"

                >
                </app-show-question>
            </div>
            <app-show-paginator  *ngIf="true" [index]="curentMarkerIndex" [component]="component" ></app-show-paginator>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg"
            id="svg_arrow_{{imageComponent.id}}" 
                class="arrow"
                >
                <defs>
                    <marker id="arrowhead_{{imageComponent.id}}" viewBox="0 0 10 10" 
                        refX="3" refY="5" stroke="red" fill="red"
                        markerWidth="6" markerHeight="6" orient="auto">
                    <path d="M 0 0 L 10 5 L 0 10 z" />
                    </marker>
                </defs>
                <g fill="none" stroke="red" stroke-width="2" id="g_arrow_head_{{imageComponent.id}}" >
                    <path id="arrow_path_{{imageComponent.id}}"/>
                </g>
                </svg>
    </div>
    <!--<app-show-paginator [index]="curentMarkerIndex" [component]="component" ></app-show-paginator>-->
</div>