<div class="column margin-20 width100">
    <div class="width100">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <div class="column width100">
            <div>
                  <!-- use a disabled button to provide padding for tree leaf -->
                  <button mat-icon-button disabled></button>
                  <mat-checkbox 
                  (change)="setRule(node.rule)"
                  [checked] = "node.rule.key === selectedRule.key"
                  >
                    {{node.rule.value}}
                  </mat-checkbox>
            </div>
          </div>
          
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
          <div class="column width100">
            <div>
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <mat-checkbox 
              (change)="setRule(node.rule)"
              [checked] = "node.rule.key === selectedRule.key"
              >{{node.rule.value}}</mat-checkbox>
          </div>
        </div>
        </mat-tree-node>
      </mat-tree>
    </div>
  </div>

