<div class="columns">
    <div class="row">
        <mat-toolbar>
            <div class="row-children">
                <app-common-options [component]="component" [slide]="slide"></app-common-options>
            </div>
        
        
            <div class="row-children">
                <app-upload-file 
                [slide] = "slide" 
                [component]="component"
                (url) = "uploadedUrl($event)"
                ></app-upload-file>
            </div>
        
            <div class="row-children">
                <mat-form-field appearance="legacy" class="width100">
                    <mat-label>Height</mat-label>
                    <input [ngModel]="viewboxHeight"  
                    (ngModelChange)="viewboxHeight = $event" 
                    matInput placeholder="Placeholder">
                </mat-form-field>
            </div>
            <div class="row-children">
                <button 
                    mat-raised-button 
                    color="warning"
                    (click)="updateViewHeight()">
                    Update View Height
                </button>

            </div>
            <div class="row-children">
                <mat-form-field appearance="fill">
                    <mat-label>Views</mat-label>
                    <mat-select [(ngModel)]="currentView" name="view">
                    <mat-option *ngFor="let v of views" [value]="v">
                        {{v}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>            
    
            </div>
            <div>
                <!--Angular button with mat-icon   -->
                <button 
                    mat-icon-button
                    (click)="rearrange()"
                    color="warning">
                    <mat-icon>list</mat-icon>
                </button>
                


            </div>
        </mat-toolbar>
    </div>
    <div class="row">
        <div class="svg">
            <svg id="svg_{{component.id}}" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" 
                version="1.1" baseProfile="full" 
                [attr.viewBox] = "viewBox"
                >
                    <image 
                        (load)="imageLoaded($event)"
                        id="svg_{{component.id}}_img" 
                        x="0" 
                        y="0" 
                        [attr.href] = "component?.src"
                    ></image>
                </svg> 
        </div>
        <div class="marker-info">
            <app-svg-marker  
                [imageComponent]="component"  
                [marker]="(selectedMarker$ | async)" 
                [slide]="slide"
                (onMarkerDeleted)="onMarkerDeleted()"
                ></app-svg-marker>
        </div>
    </div>
</div>