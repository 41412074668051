import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { IMarker, IPQuestionComponent, IPSlide, IPSlideComponent, PImageComponent, PSlideComponentEnum } from 'src/app/models';
import { DialogService } from '../../services/dialog/dialog.service';

@Component({
  selector: 'app-svg-marker',
  templateUrl: './svg-marker.component.html',
  styleUrls: ['./svg-marker.component.css']
})
export class SvgMarkerComponent implements OnInit, AfterViewInit {

  public selectedComponent!:IPSlideComponent | IPQuestionComponent |  null;

  public slideComponentEnum = PSlideComponentEnum;


  public components$:Subject<IPSlideComponent[]> = new Subject();

  //private property _marker to store marker instance
  private _marker!:IMarker | null;
  //getter and setter for marker
  get marker():IMarker | null{
    return this._marker;
  }


  @Output() onMarkerDeleted = new EventEmitter<void>();


  public _imageComponent!:PImageComponent
  get imageComponent():any{
    return this._imageComponent;
  }
  @Input() set imageComponent(slide:any){
    this._imageComponent = slide as PImageComponent;
  }


  @Input() set marker(marker:IMarker | null){
    this._marker = marker;

    if(  !marker ){
      return
    }
    this.selectedComponent = null;

    setTimeout(() => {
      this.components$.next(marker.components);   
    }, 10);
  }

  //add input for slide
  @Input() slide!:IPSlide;
  //add input for marker
  
  constructor(
    public pSvc:PresentationService,
    public dSvc:DialogService
  ) { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
      this.components$.subscribe(components => {
        console.log(components);
      })
  }




  addText(){
    //Add text component to the marker
    this.selectedComponent = this.marker!.addComponent(PSlideComponentEnum.text);
    this.components$.next(this.marker!.components);
  }

  addQuestion(){
    this.selectedComponent = this.marker!.addComponent(PSlideComponentEnum.question);
    this.components$.next(this.marker!.components);    
  }

  edit(c:IPSlideComponent){
    this.selectedComponent = c;
  }

  delete(c:IPSlideComponent){
    this.marker!.deleteComponent(c);
    this.components$.next(this.marker!.components);
  }

  deleteMarker(){
    //get confirmation from user
    if(!confirm('Are you sure you want to delete this marker?')){
      return;
    }
    //delete marker
    this.imageComponent.deleteMarker(this.marker);
    this.onMarkerDeleted.emit();
  }

  rearrange(){
    this.dSvc.openRearrangeDialog(this.marker!.components);
  }
}
