export interface ITag{
    chapter:string;
    class:string;
    subject:string;
    id:string;
}

export interface IDisplayTag{
    name:string;
}

export interface IChapter{
    number:string,
    name:string,
}


export interface IClass{
    name:string;
    subjects:ISubject[] | null;
}

export interface ISubject{
    name:string;
    chapters:IChapter[];
}


export class Tags{
    public classes:IClass[] = [];

    constructor(){
    }

    //populate class from array of ITag
    public populateClasses(classes:IClass[]){
        //create a map with key as class name and value as array of classes
        let classMap:Map<string,IClass> = new Map();
        //Iterate over classes and populate classMap
        classes.forEach( (c) => {   
            if( !classMap.has(c.name) ){
                classMap.set(c.name,c);
            }
        });

        //get all values from classMap as array and assign it to this.classes
        this.classes = Array.from(classMap.values());
    }
}