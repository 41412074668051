import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { IPSlide, PSlideComponentEnum } from 'src/app/models';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.css']
})
export class SlideComponent implements OnInit {
  public slide!:IPSlide;

  public slideComponentEnum = PSlideComponentEnum;

  constructor(
    private route: ActivatedRoute,
    private pSvc: PresentationService
  ) { }


  ngOnInit(): void {
    //Get the parameters from the route
    this.route.params.subscribe(params => {
     let sid = params['sid'];
     //assign slide to slide from presentation service
     let slide = this.pSvc.getSlide(sid);      
     if( slide ){
       this.slide = slide;
     }

    });
  }

  addText(){
      //add PTextComponent to the slide
      this.pSvc.addTextComponent(this.slide);
  }

  addImage(){
    this.pSvc.addImageComponent(this.slide);
  }

  addTikz(){
    this.pSvc.addTikzComponent(this.slide);
  }

  addQuestion(){
    this.pSvc.addQuestionComponent(this.slide);
  }
}
