<mat-horizontal-stepper
 [linear]="true"  #stepper class="instructions">
 <mat-step>
    <ng-template  matStepLabel>Your Goal</ng-template>
    <ul class="pointer">
        <li>
            <mat-card-title>You must finish each section in 10 minutes.</mat-card-title>
            <mat-card-subtitle>You can see the time in the tool bar at right side of screen</mat-card-subtitle>
          
        </li>
        <li *ngIf="!isTheory">
           
                    <mat-card-title>You must score altleast {{this.scoreSvc.assessmentDetails.totalScore/2}}( 50% ) points  </mat-card-title>
                    <mat-card-subtitle>
                        You can see the score in the tool bar at right side of screen
                        <ul >
                            <li>
                                <b>You will only get marks for the problem MCQ.</b>
                                ALL MCQs which you see in solution are designed to identify the mistakes.
                            </li>
                            <li>
                                For correct answer to problem you get 10 points. For incorrect answer 2.5 marks will be deducted.
                            </li>
                            <li>
                                If you see the hint than 2 points will be deducted.
                            </li>
                            <li>
                                You will get no point if you see the solution. 
                            </li>
                        </ul>
                    </mat-card-subtitle>
              
        </li>
    </ul>
 </mat-step>
 <mat-step *ngIf="!isTheory && rulesScore.length">
    <ng-template  matStepLabel>Rules</ng-template>
    <app-rules-score [showScore] = "showScore" [rulesScore]="rulesScore" ></app-rules-score>
 </mat-step>
</mat-horizontal-stepper>

<mat-dialog-actions align="end">
    <button mat-button (click)="next()"  cdkFocusInitial>Got It</button>
</mat-dialog-actions>
