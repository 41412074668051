<div class="columns">
    <div>
        <mat-toolbar>
            <app-common-options [component]="component" [slide]="slide"></app-common-options>
            <button 
                    mat-icon-button
                    (click)="rearrange()"
                    color="warning">
                    <mat-icon>list</mat-icon>
                </button>
        </mat-toolbar>
    </div>
    <div>
        <mat-tab-group (selectedTabChange)="onTabChanged($event);">
            <mat-tab label="Text">
                <textarea id="text_svg_{{component.id}}" 
                cols="30" rows="10"
                [(ngModel)]="component.tikz"></textarea>
            </mat-tab>
            <mat-tab label="Svg"> 
                <div class="row">
                    <div class="row-children svg" >
                        <div id="tikz_{{component.id}}" class="row-children">
                        </div>
                    </div>
                    <div class="row-children markers">
                           <app-tikz-marker class="width100" [groups]="groups" [component]="component"  [slide]="slide"></app-tikz-marker>
                    </div>
                </div>
            </mat-tab>
          </mat-tab-group>
    </div>
</div>

