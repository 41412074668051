import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageComponent } from 'src/app/common/dialogs/message/message.component';
import { PerformanceService } from 'src/app/common/services/performance/performance.service';
import { ScoreService } from 'src/app/common/services/score/score.service';
import { ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { IFlatPresentation, SlideStateEnum } from 'src/app/models';
import { GoalAssessmentComponent } from '../goal-assessment/goal-assessment.component';
import { GoalSettingComponent } from '../goal-setting/goal-setting.component';

@Component({
  selector: 'app-show-presentation-timeline',
  templateUrl: './show-presentation-timeline.component.html',
  styleUrls: ['./show-presentation-timeline.component.css']
})
export class ShowPresentationTimelineComponent implements OnInit, OnDestroy{
  private ngUnsubscribe$ = new Subject();
  public presentation!: IFlatPresentation;

  public currentSlideIndex:string = '';
  public isTheory:boolean = true;
  constructor(
    private route:ActivatedRoute,
    private router:Router,
    public spSvc: ShowPresentationService,
    public scoreSvc:ScoreService,
    public dialog: MatDialog,
    public perfSvc:PerformanceService,
    public userSvc:UserService,
  ) { }

  ngOnInit(): void {
    
    this.userSvc.userAuthStateChanged$.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe( (user:boolean) => {
      if( user ){
        this.spSvc.getPresentationFromRoute(this.route)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe( (presentation) => {
          this.presentation = this.spSvc.getFlatPresentation(presentation);
          this.isTheory = this.scoreSvc.isTheoryPresentation()
          this.spSvc.presentationLoaded$
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe( (loaded) => {
            if(loaded){
              if( !this.scoreSvc.assessmentDetails.instructionsShown ){
                this.openGoalDialog(0, false);
                this.scoreSvc.assessmentDetails.instructionsShown = true;
              }
            }
          } )

          setTimeout(() => {
            this.mathjax();
          }, 10);
        })
        /*
        //Id of the slide as in IBaseSlide
        let id = this.scoreSvc.assessmentDetails.currentSlideId;
        //Find the index of the slide in the presentation with same is as id
        this.currentSlideIndex = this.presentation.slides.findIndex( (slide) => {
          return slide.id === id;
        }).toString()
        */
      }});
  }


  async home(){
    if( this.scoreSvc.isAssessmentDirty() ){
      let message$ = new BehaviorSubject<string>("Please wait while we save the analysis document");
      let ref = this.dialog.open( MessageComponent, {
          data: message$
        } )
      await this.scoreSvc.savePerformance();
      ref.close();
    }

    this.router.navigateByUrl(`/`)    
  }

  goTo(i:number){
    //get the flat slide for index 1
    let slide = this.presentation.slides[i];
    this.currentSlideIndex = slide.id;
    this.scoreSvc.slideChanged$.next(slide.id);
    this.router.navigate(['showPresentation', 
    this.presentation.presentation.firebaseId,
    'slide', i.toString(), 'c', '0', 'sc', '0'], { state:{'presentation':this.presentation} })
  }

  ngOnDestroy(){
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  openGoalDialog(step:number, showScore:boolean){
    const dialogRef = this.dialog.open(GoalSettingComponent, {
      width: '600px',
      height: '450px',
      disableClose: true,
      data: {step:step, showScore:showScore}
    });
  }


  openAssessmentDialog(){
    return new Promise( (resolve) => {
  
      const dialogRef = this.dialog.open(GoalAssessmentComponent, {
        width: '800px',
        height: '500px',
        disableClose: true
      });
  
      dialogRef.afterClosed().subscribe( (result:boolean) => {
        resolve(null);
      } );
  
    } );
  }

  getSlideState(i :number){
    //get the slide id of slide at index i
    let slideId = this.presentation.slides[i].id;
    //get the ISlideState for the slide
    return this.scoreSvc.assessmentDetails.getSlide(slideId);
  }


  slideStateColor(i:number){

    let slideState = this.getSlideState(i);


    if( slideState?.state === SlideStateEnum.none ){
      return "";
    }

    let ret = "incomplete"

    if( slideState?.state !== SlideStateEnum.finished ){
      return ret;
    }

    if( slideState?.state === SlideStateEnum.finished ){
      ret = "complete";
    }

    if( this.isTheory ){
      return ret;
    }

    let score = slideState?.userScore || 0;

    if( score > 0 ){
      return "passed";
    }
    else if( score < 0 ){
      return "failed";
    }
  
    return ret;
  }

  openNote(i:number){
    //get the slide note
    let note = this.scoreSvc.assessmentDetails.getSlide(this.presentation.slides[i].id)?.userNote || "";; 
    let message$ = new BehaviorSubject<string>(note);
    if( note ){
      this.dialog.open( MessageComponent, {
        width: '800px',
        height: '500px',
        data: message$
      } )
      message$.next(note);
    }

  }

  public mathjax(){
    let p = window.MathJax.typesetPromise() as any;
    //resolve the promiose p 
    p.then(() => {
    })
    .catch((err:any) => {
      console.log(err);
    });
  }
}
