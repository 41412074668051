import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { IPSlide, PImageComponent, PSlideComponentEnum } from 'src/app/models';

@Component({
  selector: 'app-reuse-image',
  templateUrl: './reuse-image.component.html',
  styleUrls: ['./reuse-image.component.css']
})
export class ReuseImageComponent implements OnInit {

  selectedImage:string = "";
  images:string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ReuseImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPSlide
  ) { }

  ngOnInit(): void {
      //from slide get all components of type PImageComponent
      //and set the selected image to the first one
      this.images = this.data.components.filter(c => {
        return c.type == PSlideComponentEnum.image 
        && (c as PImageComponent).src}
      )
      .map( (c) => {
        let ic = c as PImageComponent;
        return ic.src
      } );

      if(  this.images.length  ){
        this.selectedImage = this.images[0];
      }
  }

}
