<mat-toolbar>
  <app-create-home-icon></app-create-home-icon>
    <button 
        mat-icon-button 
        class="btn" 
        (click)="saveRules()">
      <mat-icon>save</mat-icon>
    </button>
</mat-toolbar>
<div class="column margin-20 width100">
  <div class="row width100">
    <mat-form-field >
      <input matInput placeholder="Rule Name" [(ngModel)]="rulesDoc.name">
    </mat-form-field>
    <button mat-icon-button 
              (click)="addRule()">
              <mat-icon>note_add</mat-icon>
    </button>
  </div>
  <div class="width100">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <div class="column width100">
          <div>
                <!-- use a disabled button to provide padding for tree leaf -->
                <button mat-icon-button disabled></button>
                {{node.rule.value}}
                <button mat-icon-button 
                      (click)="editRule(node)">
                      <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="node.level == 0"
                      [attr.aria-label]="'Toggle ' + node.name"
                      (click)="addChild(node)">
                      <mat-icon>note_add</mat-icon>
                </button>
                <button mat-icon-button 
                [attr.aria-label]="'Toggle ' + node.name"
                (click)="delete(node)">
                  <mat-icon>close</mat-icon>
                </button>
          </div>
          <div *ngIf="node.inEditMode">
            <ng-container  class="Column" *ngTemplateOutlet="editRuleTmpl"></ng-container>
          </div>
        </div>
        
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
        <div class="column width100">
          <div>
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.rule.value}}
            <button mat-icon-button 
                  (click)="editRule(node)">
                  <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button *ngIf="node.level == 0"
                  (click)="addChild(node)">
                  <mat-icon>note_add</mat-icon>
            </button>
            <button mat-icon-button 
                [attr.aria-label]="'Toggle ' + node.name"
                (click)="delete(node)">
                  <mat-icon>close</mat-icon>
                </button>
        </div>
        <div *ngIf="node.inEditMode">
          <ng-container  class="Column" *ngTemplateOutlet="editRuleTmpl"></ng-container>
        </div>
      </div>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>

<ng-template #editRuleTmpl >
  <div class="columns width50">
    <textarea [(ngModel)]="currentRule.rule.value"  
      name="svg" id="svg" cols="30" rows="10">
    </textarea>
    <mat-form-field >
      <input matInput placeholder="Rule Name" [(ngModel)]="currentRule.rule.key">
    </mat-form-field>
    <button  (click)="done()"
    mat-raised-button color="accent">
      Done          
    </button>
  </div>
</ng-template>