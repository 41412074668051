import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Rule, RulesDoc } from 'src/app/models';
import { FirebaseService } from '../firebase/firebase.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class RulesService {

  public rulesCollName = "rules";

  public rulesDoc$ = new BehaviorSubject<RulesDoc|null>(null);

  private _rulesDoc:RulesDoc | null = null;

  //create getter and setter for _rulesDoc
  get rulesDoc():RulesDoc|null{
    return this._rulesDoc;
  }
  set rulesDoc(rulesDoc:RulesDoc|null){
    this._rulesDoc = rulesDoc;
    this.rulesDoc$.next(this._rulesDoc);
  }
  

  constructor(
    public fSvc: FirebaseService,
    public userSvc: UserService
  ) { } 
  
  //get Rules from the id specified in route
  getRulesFromRoute(route: ActivatedRoute){
    let s = new Subject<RulesDoc>()
    route.params.subscribe( (params) => {
      let id = params["id"];
      this.getRuleDoc(id).subscribe( (rulesDoc) => {
        s.next(rulesDoc);
      });
    });
    return s;
  }


  createRuleDoc(tagName:string){
    let r = new RulesDoc("New Rules");
    r.tag = tagName;
    r.authorId = this.userSvc.LoggedInUser.uid;
    r.companyId = this.userSvc.user.company;

    return this.fSvc.createFireStoreDoc(r, this.rulesCollName);

  }

  getRuleDoc(id:string){
    return this.fSvc.getFireStoreDoc(id, this.rulesCollName)
    .pipe( map( doc => {
      let data = doc.data();
      let r = new RulesDoc(data.name);
      r.copy(data);
      this.rulesDoc = r;
      return r;
    }  ) )
    
  }

  //get Rules doc by filtering the collection on the field tag
  getRulesDocByTag(tag:string){
    return from(this.fSvc.getFirestoreDocsByField( "tag", tag, this.rulesCollName,))
    .pipe( map( docs => {
        let count = 0;
        docs.forEach( (doc) => {
        let data = doc.data() as any;
        let r = new RulesDoc(data.name);
        r.copy(data);
        count ++;
        this.rulesDoc = r;
        return r;
      })
      if( count === 0 ){
        this.rulesDoc = null;
      }

    }))
  } 




  getRulesForAuthor(tag:string){
    let s = new Subject<RulesDoc[]>();
    this.fSvc.getFirestoreDocsForAuthor(
      this.userSvc.LoggedInUser.uid,
      tag,
      this.rulesCollName
    )
    .then( (snapShot) => {
      let rulesDocs:RulesDoc[] = [];
      snapShot.forEach( (doc) => {
        let data = doc.data() as any;
        let r = new RulesDoc(data.name);
        r.copy(data);
        rulesDocs.push(r);
      });
      s.next( rulesDocs );
    } )

    return s;
  }

  saveRulesDoc(rulesDoc:RulesDoc){
    return this.fSvc.saveFireStoreDoc(rulesDoc, this.rulesCollName);
  }

  //function to return 4 digit random number
  getRandomNumber4(){
    return Math.floor(1000 + Math.random() * 9000).toString();
  }


  //get Rule from the rules doc which has same key as 
  //passed in parameter
  getRuleByKey(key:string) :Rule|null {
    if( !this.rulesDoc ){
      return null
    }
    let ret:Rule|null = null;
    this.rulesDoc.rules.forEach( (rule) => {
      if( rule.key === key ){
        ret =  rule as Rule;
      }
    })
    
    //if no rule found at root than search in sub rules
    this.rulesDoc.rules.forEach( (rule) => {
      //check in the children collection
      if( rule.children ){
        rule.children.forEach( (child) => {
          if( child.key === key ){
            ret =  child as Rule;
          }
        })
      }
    })

    return ret;
  }

}
