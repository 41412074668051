import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-rearrange',
  templateUrl: './rearrange.component.html',
  styleUrls: ['./rearrange.component.css']
})
export class RearrangeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RearrangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]
  ) {
    console.log(data);
    
   }

  ngOnInit(): void {

  }


  up(index: number) {
    //move element at index up in the list
    if (index > 0) {
      const temp = this.data[index];
      this.data[index] = this.data[index - 1];
      this.data[index - 1] = temp;
    }
  }
  down(index: number){
    //move element at index down in the list
    if (index < this.data.length - 1) {
      const temp = this.data[index];
      this.data[index] = this.data[index + 1];
      this.data[index + 1] = temp;
    }
  }
}
