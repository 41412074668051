import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RearrangeComponent } from '../../components/rearrange/rearrange.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor( public dialog: MatDialog ) { }

  //rearrange dialog
  public openRearrangeDialog(data:any[]){
    return this.dialog.open(RearrangeComponent, {
      width: '400px',
      height: '400px',
      data: data
    });
  }


}
