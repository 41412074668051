import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { Rule, RulesDoc } from 'src/app/models';

@Component({
  selector: 'app-show-rule',
  templateUrl: './show-rule.component.html',
  styleUrls: ['./show-rule.component.css']
})
export class ShowRuleComponent implements OnInit, OnDestroy {
  public ngUnsubscribe = new Subject<void>();
  public selectedRule!: Rule;
  public rulesDoc!:RulesDoc


  constructor(
    public route: ActivatedRoute,
    public rulesSvc: RulesService,
    public userSvc: UserService
  ) { 
    
  }

  ngOnInit(): void {
    
    this.rulesSvc.getRulesFromRoute(this.route)
    .pipe(  takeUntil(this.ngUnsubscribe) )
    .subscribe( (rulesDoc) => {
      this.rulesDoc = rulesDoc;
      if( !this.rulesDoc.rules || this.rulesDoc.rules.length == 0 ){
        this.rulesDoc.rules = [];
      }
    })    

    this.selectedRule = new Rule();
    this.selectedRule.key = this.rulesSvc.getRandomNumber4();
  }


  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
