import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-presentation-confirmation',
  templateUrl: './create-presentation-confirmation.component.html',
  styleUrls: ['./create-presentation-confirmation.component.css']
})
export class CreatePresentationConfirmationComponent implements OnInit {

  create:string="yes"

  constructor(
    public dialogRef: MatDialogRef<CreatePresentationConfirmationComponent>
  ) { }

  ngOnInit(): void {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}
