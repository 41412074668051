import { Component, Input, OnInit } from '@angular/core';
import { IPSlide, KeyValue, PTikzSvgComponent } from 'src/app/models';
import { DialogService } from '../../services/dialog/dialog.service';

@Component({
  selector: 'app-tikz',
  templateUrl: './tikz.component.html',
  styleUrls: ['./tikz.component.css']
})
export class TikzComponent implements OnInit {

  public _component!:PTikzSvgComponent;
  get component():any{
    return this._component;
  }
  @Input() set component(slide:any){
    this._component = slide as PTikzSvgComponent;
  }


  @Input() slide!:IPSlide;

  //Map of all groups with ids
  public groups: KeyValue<string, boolean>[] = [];

  
  constructor( public dSvc:DialogService
   ) { }

  ngOnInit(): void {
  }


  setSvg(){
    //get element by id
    let element = document.getElementById(`tikz_${this.component.id}`);
    //create a new div element 
    let newDiv = document.createElement("div");
    //set the id of the new div
    newDiv.id = `tikz_${this.component.id}`;
    //set the inner html of the new div
    newDiv.innerHTML = this.component.tikz;
    //set the width of new div to 100%
    newDiv.style.width = "100%";
    //get the first element of the newDiv
    let firstElement = newDiv.firstElementChild as HTMLElement;
    firstElement.style.width = "100%";
    //set the id of the first element
    firstElement.id = `svg_${this.component.id}`;
    //replace the outerHTML of the element with the new div
    element!.outerHTML = newDiv.outerHTML;

    setTimeout(() => {
      //get the groups
      this.getGroupsWithId();
    }, 10);
  }

  onTabChanged(event: any) {
    let clickedIndex = event.index;
    if(  clickedIndex === 1 ){
        this.setSvg();
    }
  }

  //function to get the svg and get all elements by tag name "g" 
  //and check if they have id. If they have id than add them to the 
  //groupsWithId Map
  getGroupsWithId(){
    //get the svg element
    let svg = document.getElementById(`svg_${this.component.id}`);
    //get all elements by tag name "g"
    let groups = svg!.getElementsByTagName("g");
    //loop through all groups
    for( let i = 0; i < groups.length; i++ ){
        //get the group
        let group = groups[i];
        //check if the group has an id
        if( group.id ){
            //if id contains page ignore it
            if( group.id.includes("page") ){
                continue;
            }
            //add the id to the groupsWithId array
            this.groups.push( new KeyValue<string, boolean>( group.id, false));
        }
      }
    }

    rearrange(){
      this.dSvc.openRearrangeDialog(this.component.components);
    }
}
