import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Subject } from 'rxjs';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { IPSlide, KeyValue, PQuestionComponent, QuestionType, Rule, RuleDialogData } from 'src/app/models';
import { RuleTreeDialogComponent } from 'src/app/show/rules/rule-tree-dialog/rule-tree-dialog.component';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  private selectedRule$:Subject<Rule> = new Subject<Rule>();

  questionTypes: string[] = ["Multiple Choice", "True/False"];

  private _assessment:boolean = false;
  public set assessment(assessment:boolean){
    this._assessment = assessment;
    this.component.assessment = assessment;
    this.presentationSvc.assessmentChanged$.next(this.component.assessment);
  }

  public get assessment():boolean{
    return this._assessment;
  }



  public _component!:PQuestionComponent
  get component():any{
    return this._component;
  }
  @Input() set component(slide:any){
    this._component = slide as PQuestionComponent;
    this.assessment = this.component.assessment;
  }

  @Input() slide!:IPSlide;


  questionTypeEnum = QuestionType;

  _numberOfQuestions:number = 1;

  get numberOfQuestions():number{
    return this._numberOfQuestions;
  }

  set numberOfQuestions(num:number){
    this._numberOfQuestions = num;

    if( this.component.answers.length < num ){
        //add questions to the component
        for( let i = this.component.answers.length; i < num; i++ ){
          this.component.answers.push( new KeyValue<string, boolean>("your text", false) );
        }
    }
    else if( this.component.answers.length > num ){
      //delete questions from the component
      this.component.answers.splice(num, this.component.answers.length - num);
    }
  }

  selectNumberOfQuestions:number[] = [1,2,3,4];

  constructor(
    private ruleSvc:RulesService,
    public dialog: MatDialog,
    public presentationSvc:PresentationService,
  ) { }

  ngOnInit(): void {
    //create questions array if it is null
    if( this.component.answers == null ){
      this.component.answers = [];
    }
    
    this.selectedRule$.subscribe(rule => {
      //set the key of rule to the ruleTag property
      this._component.ruleKey = rule.key
    });


    //if component have no questions than add two questions
    if( this.component.answers.length == 0 ){
      this.component.answers.push( new KeyValue<string, boolean>("your text", false) );
      this.component.answers.push( new KeyValue<string, boolean>("your text", false) );
    }
  }


  onQuestionTypeChanged($event:MatRadioChange){
    
    if( $event.source.value == "Multiple Choice" ){
      this.component.qType = QuestionType.checkbox;
    }else if( $event.source.value == "True/False" ){
      this.component.qType = QuestionType.radio;
    }

    //delete all answers from the component
    this.component.answers = [];
    //add two answers to the component
    this.component.answers.push( new KeyValue<string, boolean>("your text", false) );
    this.component.answers.push( new KeyValue<string, boolean>("your text", false) );

  }

  questionRadioChanged($event:any){
    //iterate over all elements in component.questions 
    //and set the value to false
    for( let i = 0; i < this.component.answers.length; i++ ){
      this.component.answers[i].value = false;
    }
    //set the value to true for the selected question
    this.component.answers[+$event.srcElement.value].value = true;
  }

  onQuestionKeyChange(q:KeyValue<string, boolean>, $event:any){
      q.key = $event.target.innerHTML;
  }

  onQuestionChangedRadio(q:KeyValue<string, boolean>, $event:any){
    //iterate over all elements in component.questions 
    //and set the value to false
    for( let i = 0; i < this.component.answers.length; i++ ){
      this.component.answers[i].value = false;
    }
    //set the value to true for the selected question
    q.value = true;
  }


  onQuestionChangedCheckbox(q:KeyValue<string, boolean>,  $event:any){
      //set the  value of the q based on event
      q.value = $event.srcElement.checked;
  }


  rule(){
    //Check if we have rules for the presentation
    if( !this.ruleSvc.rulesDoc ){
      alert("Please add rules first");
      return;
    }
    //Get the rule
    let ruleTag = this._component.ruleKey;
    let rule = null;
    if( !ruleTag ){
      rule = new Rule();
      rule.key = this.ruleSvc.getRandomNumber4();
    }
    else {
      rule = this.ruleSvc.getRuleByKey(ruleTag);
    }

    this.dialog.open(RuleTreeDialogComponent, {
      width: '800px',
      height: '500px',
      data: {
        ruleDoc: this.ruleSvc.rulesDoc,
        selectedRule: rule,
        ruleSelected$: this.selectedRule$
      } as RuleDialogData
    });
    
  }
}
