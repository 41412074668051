<div class="row">
    <div class="row-children svg" >
        <div id="tikz_{{tikzComponent.id}}" class="row-children">
        </div>
    </div>
    <div class="marker-info row-children">
        <div 
            *ngIf="textComponent?.type === slideComponentEnum.tikzText" 
            class="explain" 
            id="explain_{{tikzComponent.id}}"  
            [innerHTML]="textComponent?.text" 
        ></div>
        <div class="explain" 
        id="explain_{{tikzComponent.id}}" 
        *ngIf="textComponent?.type === slideComponentEnum.tikzQuestion" >
            <app-show-question 
                    [component]="flatMarker" 
                >
            </app-show-question>
        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg"
        id="svg_arrow_{{tikzComponent.id}}" 
            class="arrow"
            >
            <defs>
                <marker id="arrowhead_{{tikzComponent.id}}" viewBox="0 0 10 10" 
                    refX="3" refY="5" stroke="red" fill="red"
                    markerWidth="6" markerHeight="6" orient="auto">
                <path d="M 0 0 L 10 5 L 0 10 z" />
                </marker>
            </defs>
            <g  id="g_arrowhead_{{tikzComponent.id}}" fill="none" stroke="red" stroke-width="2">
                <path id="arrow_path_{{tikzComponent.id}}"/>
            </g>
            </svg>
</div>
<app-show-paginator [index]="curentMarkerIndex" [component]="component" ></app-show-paginator>