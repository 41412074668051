import { Injectable } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssessmentDetail, FlatMarker, FlatPresentation, IFlatSlide, IPQuestionComponent, PSlideComponentEnum, SlideEventEnum, theory } from 'src/app/models';
import { FirebaseService } from '../firebase/firebase.service';
import { PerformanceService } from '../performance/performance.service';
import { RulesService } from '../rules/rules.service';
import { UserService } from '../user/user.service';

interface IQuestionFound{
    question:IPQuestionComponent,
    slide:IFlatSlide,
}


@Injectable({
  providedIn: 'root'
})
export class ScoreService {


  private questionFound$ = new Subject<IQuestionFound>();
  private addSlideState$ = new Subject<string>();
  
  public slideChanged$ = new BehaviorSubject<string>("1");

  //public performance:Performance = new Performance();
  //member variable for AssessmentDetails
  public assessmentDetails!:AssessmentDetail;

  //variable to keep reference of current Flat presentation
  public flatPresentation!: FlatPresentation;

  //reference to count down timer
  public countdowenTimer!:CountdownComponent
  constructor(
    public rulesSvc:RulesService,
    public userSvc:UserService,
    public fbSvc:FirebaseService,
    public perfSvc:PerformanceService,
  ) {
    this.slideChanged$.subscribe( ( i ) => {
      if( this.assessmentDetails ){
        let isAssessmentSlide = this.isAssessmentSlide(i);
        this.assessmentDetails.setCurrentSlide(i, !isAssessmentSlide || this.isTheoryPresentation());
      }
    } );
  }

  //function to set current Flat presentation
  setCurrentFlatPresentation(flatPresentation: FlatPresentation){
    let ret = new Subject();
    this.flatPresentation = flatPresentation;
    this.reinit();
    let s = this.questionFound$.subscribe( ( i ) => {
      this.assessmentDetails.addQuestion(i.question, i.slide.id);
    });
    //We must have state for all the slides
    let stateAdd = this.addSlideState$.subscribe( ( i ) => {
      this.assessmentDetails.addSlideState(i);
    });

    this.iterateSlides();

    this.perfSvc.getPerformance(flatPresentation.presentation.firebaseId)
    .subscribe( ( performance ) => {
      if(  performance ){
        this.assessmentDetails.updateFromAssessment(performance);
      }
      else {
        this.assessmentDetails.aggregateScores();
      }
      ret.next();
    })

    s.unsubscribe();
    stateAdd.unsubscribe();
    return ret;
  }


  isAssessmentSlide(i:string){
      let slides = this.flatPresentation.slides.filter( s => s.id == i );
      if( slides && slides.length){
          return slides[0].slide.components[0].type ===  PSlideComponentEnum.question
                 && (slides[0].slide.components[0] as IPQuestionComponent).assessment 
      }

      return false;
  }

  reinit(){
    //reinitialize performance
    this.assessmentDetails  = new AssessmentDetail();
    this.assessmentDetails.initialize(
        this.flatPresentation.presentation.docType,
        this.flatPresentation.presentation.firebaseId
    );
    let sub = this.slideChanged$.subscribe( ( i ) => {
      this.assessmentDetails.setCurrentSlide(i, this.isTheoryPresentation());
    } );
    sub.unsubscribe();
  }

  isTheoryPresentation(){
    return this.flatPresentation.presentation.docType === theory;
  }

  isAssessmentQuestion(question:IPQuestionComponent){
    return !this.isTheoryPresentation() 
          && question.assessment;
  }

  iterateSlides(){
    //iterate through all the slides in flat presentation
    for(let i = 0; i < this.flatPresentation.slides.length; i++){
      let slide = this.flatPresentation.slides[i];
      this.addSlideState$.next(slide.id);
      this.iterateSlideForQuestions(slide);
    }
  }

  iterateSlideForQuestions(slide:IFlatSlide){
    //iterate through all the components in the slide
    for(let j = 0; j < slide.components.length; j++){
      let flatComponent = slide.components[j]
      //iterate through all markers in the component
      for(let k = 0; k < flatComponent.markers.length; k++){
        //get the marker
        let marker = flatComponent.markers[k] as FlatMarker;
        //get the component from the marker
        let component = marker.component;
        if(component.type === PSlideComponentEnum.question){
          //type case component as IPQuestionComponent
          let qc = component as IPQuestionComponent;
          //fn(qc, slide);
          //We have no idea what need to be done next so raise an event
          this.questionFound$.next({question:qc, slide:slide});
        }
      }
    }
  }


  public isAssessmentDirty(){
    return this.assessmentDetails.isDirty;
  }

  updateUserScore(question:IPQuestionComponent, score:number){
      this.assessmentDetails.updateUserScore(question, score);
  }

  addShortNote(note:string){
    //append note to end of notes array of performance
    //ToDo::Add notes to assessmentDetails
    this.assessmentDetails.addUserNote(note);
  }

  savePerformance(){
    //ToDo::Save AssessmentDetails to firebase
    return this.perfSvc.savePerformance(this.assessmentDetails);
  }
}
