import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { ScoreService } from 'src/app/common/services/score/score.service';
import { IDispayRulesScore, SlideEventEnum } from 'src/app/models';

@Component({
  selector: 'app-rules-score-dialog',
  templateUrl: './rules-score-dialog.component.html',
  styleUrls: ['./rules-score-dialog.component.css']
})
export class RulesScoreDialogComponent implements OnInit {

  public rulesScore:IDispayRulesScore[] = [];

  constructor(
    private scoreSvc:ScoreService,
    public rulesSvc:RulesService,
    public dialogRef: MatDialogRef<RulesScoreDialogComponent>,
  ) { }

  ngOnInit(): void {
    let rules = this.rulesSvc.rulesDoc;
    if( !rules ){
      return;
    }

    this.rulesScore = [];
    this.rulesScore = this.scoreSvc.assessmentDetails.getRulesScore(rules)
  }


  next(){
    this.scoreSvc.assessmentDetails.processQuestion(null, 0, SlideEventEnum.assessmentShown)
    this.dialogRef.close();
  }
}
