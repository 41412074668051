import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SlideEventEnum } from 'src/app/models';
import { ScoreService } from '../../services/score/score.service';

@Component({
  selector: 'app-user-note',
  templateUrl: './user-note.component.html',
  styleUrls: ['./user-note.component.css']
})
export class UserNoteComponent implements OnInit {

  public note:string = '';

  @Output() public noteChange = new EventEmitter<string>();

  constructor(
    public scoreSvc: ScoreService,
    public dialogRef: MatDialogRef<UserNoteComponent>,
  ) { }

  ngOnInit(): void {
    this.scoreSvc.assessmentDetails.processQuestion(null, 0, SlideEventEnum.notesAdded)
  }


  updateNote(){
    this.noteChange.emit(this.note);
    this.dialogRef.close();
  }
}
