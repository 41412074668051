<div class="toolbar">
  <mat-toolbar>
    <app-create-home-icon></app-create-home-icon>
    <button 
        mat-icon-button 
        class="btn" 
        (click)="savePresentation()">
      <mat-icon>save</mat-icon>
    </button>
    <button 
          mat-raised-button 
          color="primary"
        (click)="addSlide()">
      Add Slide
    </button>
    <span class="spacer"></span>
    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button>
  </mat-toolbar>
</div>
<div class="content">
  <div class="columns create">
      <div class="row">
          <div class="menu">
            <div class="columns">
              <mat-card>
                <mat-card-title>
                  <a [routerLink]="['metadata']">
                    {{presentation?.title}}
                  </a>
                </mat-card-title>
                <mat-card-content>
                  <div class="row">
                    <mat-icon (click)="rearrange()">list</mat-icon>
                    <a routerLink="/showPresentationTimeline/{{presentation?.firebaseId}}" target="_blank">
                        <mat-icon>language</mat-icon>
                    </a>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-divider></mat-divider>
              <mat-card  *ngFor="let slide of presentation?.slides">
                <mat-card-title>{{slide.title}}</mat-card-title>
                <mat-card-actions>
                  <a routerLink="slide/{{slide.id}}">
                    <button mat-button><mat-icon>edit</mat-icon></button>
                  </a>
                  <button mat-button (click)="deleteSlide(slide)" ><mat-icon>delete</mat-icon></button>
                  <button 
                    mat-icon-button
                    (click)="rearrangeComponentsOfSlide(slide)"
                    color="warning">
                    <mat-icon>list</mat-icon>
              </button>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
          <div class="main">
            <router-outlet></router-outlet>
          </div>
      </div>
  </div>
</div>
