import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage'
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { SvgExperimentsComponent } from './svg-experiments/svg-experiments.component';
import { TextComponent } from './create/components/text/text.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { HomeComponent } from './create/components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreateComponent } from './create/components/create/create.component';
import { PresentationMetadataComponent } from './create/components/presentation-metadata/presentation-metadata.component';
import { SlideComponent } from './create/components/slide/slide.component';
import { CommonOptionsComponent } from './create/components/common-options/common-options.component';
import { ImageComponent } from './create/components/image/image.component';
import { SvgMarkerComponent } from './create/components/svg-marker/svg-marker.component';
import { UploadFileComponent } from './create/components/upload-file/upload-file.component';
import { TikzComponent } from './create/components/tikz/tikz.component';
import { TikzMarkerComponent } from './create/components/tikz-marker/tikz-marker.component';
import { TitleComponent } from './create/components/title/title.component';
import { ShowPresentationComponent } from './show/components/show-presentation/show-presentation.component';
import { ShowSlideComponent } from './show/components/show-slide/show-slide.component';
import { ShowTextComponent } from './show/components/show-text/show-text.component';
import { ShowImageComponent } from './show/components/show-image/show-image.component';
import { ShowPaginatorComponent } from './show/components/show-paginator/show-paginator.component';
import { ShowTikzComponent } from './show/components/show-tikz/show-tikz.component';
import { RearrangeComponent } from './create/components/rearrange/rearrange.component';
import { MessageComponent } from './common/dialogs/message/message.component';
import { QuestionComponent } from './create/components/question/question.component';
import { ShowQuestionComponent } from './show/components/show-question/show-question.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './dashboard/admin/components/admin/admin.component';
import { AuthorComponent } from './dashboard/author/author.component';
import { LogoutComponent } from './logout/logout.component';
import { ShowPresentationsComponent } from './dashboard/common/components/show-presentations/show-presentations.component';
import { TagsComponent } from './common/components/tags/tags.component';
import { RulesHomeComponent } from './create/rules/components/rules-home/rules-home.component';
import { RulesCreateComponent } from './create/rules/components/rules-create/rules-create.component';
import { HomeIconComponent } from './create/common/components/home-icon/home-icon.component';
import { BaseComponent } from './create/rules/components/base/base.component';
import { ShowRuleComponent } from './show/rules/components/show-rule/show-rule.component';
import { RuleTreeComponent } from './show/rules/rule-tree/rule-tree.component';
import { RuleTreeDialogComponent } from './show/rules/rule-tree-dialog/rule-tree-dialog.component';
import { ReuseImageComponent } from './create/components/reuse-image/reuse-image.component';
import { StartComponent } from './start/start.component';
import { UserNoteComponent } from './common/dialogs/user-note/user-note.component';
import { CreatePresentationConfirmationComponent } from './common/dialogs/create-presentation-confirmation/create-presentation-confirmation.component';
import { NewDocumentComponent } from './common/components/new-document/new-document.component';
import { CountdownModule } from 'ngx-countdown';
import { GoalSettingComponent } from './show/components/goal-setting/goal-setting.component';
import { GoalAssessmentComponent } from './show/components/goal-assessment/goal-assessment.component';
import { RulesScoreComponent } from './show/components/rules-score/rules-score.component';
import { RulesScoreDialogComponent } from './show/components/rules-score-dialog/rules-score-dialog.component';
import { AssessmentComponent } from './show/components/assessment/assessment.component';
import { TimelineVerticalComponent } from './timeline-vertical/timeline-vertical.component';
import { ShowPresentationTimelineComponent } from './show/components/show-presentation-timeline/show-presentation-timeline.component';
import { MessageChoiceComponent } from './common/dialogs/message-choice/message-choice.component'
import { StepComponent } from './show/components/step/step.component';

@NgModule({
  declarations: [
    AppComponent,
    SvgExperimentsComponent,
    TextComponent,
    HomeComponent,
    CreateComponent,
    PresentationMetadataComponent,
    SlideComponent,
    CommonOptionsComponent,
    ImageComponent,
    SvgMarkerComponent,
    UploadFileComponent,
    TikzComponent,
    TikzMarkerComponent,
    TitleComponent,
    ShowPresentationComponent,
    ShowSlideComponent,
    ShowTextComponent,
    ShowImageComponent,
    ShowPaginatorComponent,
    ShowTikzComponent,
    RearrangeComponent,
    MessageComponent,
    QuestionComponent,
    ShowQuestionComponent,
    LoginComponent,
    AdminComponent,
    AuthorComponent,
    LogoutComponent,
    ShowPresentationsComponent,
    TagsComponent,
    RulesHomeComponent,
    RulesCreateComponent,
    HomeIconComponent,
    BaseComponent,
    ShowRuleComponent,
    RuleTreeComponent,
    RuleTreeDialogComponent,
    ReuseImageComponent,
    StartComponent,
    UserNoteComponent,
    CreatePresentationConfirmationComponent,
    NewDocumentComponent,
    GoalSettingComponent,
    GoalAssessmentComponent,
    RulesScoreComponent,
    RulesScoreDialogComponent,
    AssessmentComponent,
    TimelineVerticalComponent,
    ShowPresentationTimelineComponent,
    MessageChoiceComponent,
    StepComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    EditorModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule,
    MatTabsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatDialogModule,
    MatListModule,
    MatSidenavModule,
    MatTreeModule,
    MatBadgeModule,
    LayoutModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    /*AngularFirestoreModule.enablePersistence(),*/
    AngularFirestoreModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    CountdownModule, 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
