<div class="container">
    <mat-toolbar>
        <mat-toolbar-row>
            <div class="row">
                <div>
                    <!-- mat form fiels with input box  -->
                    <mat-form-field>
                        <input matInput placeholder="Select Tag" type="text" readonly [(ngModel)]="selectedTag">  
                    </mat-form-field>
                </div>
                <div>
                    <app-tags (tagChangeEvent)="tagChangeEvent($event)"></app-tags>
                </div>
            </div>
            <!-- component app-tags -->
            
        </mat-toolbar-row>
        <mat-toolbar-row>
            <app-new-document 
            (createDocumentEvent)="createRule($event)">
            </app-new-document>
            <button mat-icon-button 
                    aria-label="Example icon-button with menu icon"
                    (click)="listRules()"
                >
                <mat-icon>view_list</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div>
        <div class="columns">
            <div class="row" *ngFor="let r of (rules$ | async)">
                <div class="title"> 
                    <a routerLink="/createRule/{{r.firebaseId}}">
                        {{ r.name }}
                    </a> </div>
                <div class="tag"> {{ r.tag }} </div>
                <div class="actions">
                    <div>
                        <a routerLink="/showRule/{{r.firebaseId}}" target="_blank">
                            <mat-icon>language</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>