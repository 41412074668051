import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageComponent } from 'src/app/common/dialogs/message/message.component';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { ScoreService } from 'src/app/common/services/score/score.service';
import { ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { IDispayRulesScore } from 'src/app/models';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css']
})
export class AssessmentComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject();

  public rulesScore:IDispayRulesScore[] = [];
  public passed = true;
  public score:number = 0;
  public scorePercentage:string = "0";
  finishedInTime:string = "0";
  isTheory:boolean = true;
  @ViewChild('stepper') stepper!: MatStepper;


  constructor(
    public scoreSvc:ScoreService,
    public spSvc: ShowPresentationService,
    public rulesSvc:RulesService,
    private router:Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.isTheory = this.scoreSvc.isTheoryPresentation()
    this.spSvc.showPresentationTime$
    .pipe( takeUntil(this.ngUnsubscribe$) )
    .subscribe(time => {
      let temp = ( time*1000 -  this.scoreSvc.countdowenTimer.left)/1000/60
      this.finishedInTime = temp.toFixed(2);
      this.passed = this.passed && temp >= 0;
      this.score = this.scoreSvc.assessmentDetails.userScore;
      this.passed = this.passed && this.score >= (this.scoreSvc.assessmentDetails.totalScore/2);
      this.scorePercentage = ((this.score/this.scoreSvc.assessmentDetails.totalScore)*100).toFixed(2);
      this.calculateRulesScore();
    });
  }

  ngOnDestroy(){
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  next(){
    if( this.isTheory  ){
      this.done();
    }
    else {
      this.stepper.selectedIndex = 1;
    }
    
  }

  async done(){
    let message$ = new BehaviorSubject<string>("Please wait while we save the analysis document");
    let ref = this.dialog.open( MessageComponent, {
        data: message$
      } )
    await this.scoreSvc.savePerformance();
    ref.close();

    this.router.navigateByUrl("/")
  }

  calculateRulesScore(){
    let rules = this.rulesSvc.rulesDoc;
    if( !rules ){
      return;
    }

    this.rulesScore = [];
    this.rulesScore = this.scoreSvc.assessmentDetails.getRulesScoreAll(rules);
}
}
