import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { RulesDoc } from 'src/app/models';

@Component({
  selector: 'app-rules-home',
  templateUrl: './rules-home.component.html',
  styleUrls: ['./rules-home.component.css']
})
export class RulesHomeComponent implements OnInit {

  public selectedTag:string = "";
  public rules$!:  Subject<RulesDoc[]>;

  constructor(
    public rulesSvc: RulesService,
    public routerSvc:Router,
    public userSvc: UserService
  ) { }

  ngOnInit(): void {
  }


  tagChangeEvent($event:string){
    this.selectedTag = $event;
  }

  createRule($event:boolean){
    if( !$event){
      return;
    }


    if( !this.selectedTag  ){
      alert("please select a tag");
      return;
    }

    this.rulesSvc.createRuleDoc(this.selectedTag)
    .subscribe( (doc) => {
      this.routerSvc.navigate([`/createRule/${doc.firebaseId}`]);
    })
 }

  listRules(){
    if( !this.selectedTag  ){
      alert("please select a tag");
      return;
    } 

    this.rules$ =  this.rulesSvc.getRulesForAuthor(this.selectedTag)
  }
}
