<div class="row">
    <div class="select">
        <mat-form-field appearance="fill">
            <mat-label>Class</mat-label>
            <mat-select (selectionChange)="classChange($event)" name="view">
            <mat-option *ngFor="let v of (companySvc.classes$ | async)" [value]="v.name">
                {{v.name}}
            </mat-option>
            </mat-select>
        </mat-form-field>  
    </div>
    <div class="select">
        <mat-form-field appearance="fill">
            <mat-label>Subject</mat-label>
            <mat-select (selectionChange)="subjectChange($event)" name="view">
            <mat-option *ngFor="let s of (companySvc.subjects$ | async)" [value]="s">
                {{s.name}}
            </mat-option>
            </mat-select>
        </mat-form-field>  
    </div>
    <div class="select">
        <mat-form-field appearance="fill">
            <mat-label>Chapter</mat-label>
            <mat-select (selectionChange)="tagChange($event)" name="view">
            <mat-option *ngFor="let ch of chapters" [value]="ch.name">
                {{ch.number}}
            </mat-option>
            </mat-select>
        </mat-form-field>  
    </div>
</div>