<div class="columns">
    <div [innerHTML]="question?.text"></div>
    <div class="radio-group" *ngIf="question?.qType === questionTypeEnum.radio">
        <div class="columns row-children">
            <div class="row row-children">
                <mat-radio-group 
                class="width100"
                aria-label="Select an option"
                [(ngModel)]="radioAnswer">
                    <mat-radio-button class="radio-group" 
                    *ngFor="let q of question?.answers; index as i" 
                    value="{{i}}"
                    [disabled] = "questionAnswered"
                    [class.questionAnswered]="questionAnswered && q.value" >
                        {{q.key}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
    <div class="radio-group" *ngIf="question?.qType === questionTypeEnum.checkbox">
        <div class="columns row-children" *ngFor="let q of question?.answers; index as i" >
            <mat-checkbox 
                [class.questionAnswered]="questionAnswered && q.value"
                [(ngModel)]="checkBoxAnswers[i]"
                [disabled] = "questionAnswered"
            >
            {{q.key}}
            </mat-checkbox>
        </div>        
    </div>
    <div class="center" *ngIf = "!(spSvc.showHidePaginator$|async)">
        <button mat-raised-button color="primary"
            (click)="verify()">
            Verify
        </button>
    </div>
    <div *ngIf="isAssessmentQuestion" class="row action">
        <span class="action">
            <button *ngIf="hasHint"
                mat-raised-button color="warn" 
                (click)="showHint()">
                Hint
            </button>
        </span>
        <span class="action">
            <button 
            mat-raised-button color="warn" 
            (click)="showSolution()">
            Show Solution
            </button>
        </span>
        
    </div>
</div>
