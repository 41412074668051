<div class="columns">
    <div>
        <mat-toolbar>
            <app-common-options [component]="component" [slide]="slide"></app-common-options>
            <app-upload-file 
                [slide] = "slide" 
                [component]="component"
                (url) = "uploadedUrl($event)"
                ></app-upload-file>
            <input type="text" name="url" id="url_{{component.id}}">
        </mat-toolbar>
    </div>
    <div>
        <editor
        [(ngModel)]="component.text"
        apiKey="ybfsof6ix4fia55ylhfhcf7p0mv7zzcgjag8pq1u0mq6x7c0"
        [init]="{
        height: 150,
        menubar: false,
        plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
        'undo redo | formatselect | bold italic backcolor | image |\
        alignleft aligncenter alignright alignjustify | link |\
        bullist numlist outdent indent | removeformat | help'
    }"
    ></editor>
    </div>
</div>