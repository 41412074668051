import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { BaseComponent } from 'src/app/create/rules/components/base/base.component';
import { Rule, RulesDoc } from 'src/app/models';

@Component({
  selector: 'app-show-rule-tree',
  templateUrl: './rule-tree.component.html',
  styleUrls: ['./rule-tree.component.css']
})
export class RuleTreeComponent extends BaseComponent implements OnInit,  OnDestroy  {


  private _rulesDoc!: RulesDoc;

  @Input() selectedRule!: Rule;

  @Input() set temp(r:RulesDoc){
    this.rulesDoc = r;
    this.dataSource.data = this.rulesDoc.rules as Rule[];
  }

  @Output() ruleSelected = new EventEmitter<Rule>();

  get temp():RulesDoc {
    return this.rulesDoc
  }


  constructor(
    public route: ActivatedRoute,
    public rulesSvc: RulesService,
    public userSvc: UserService
  ) { 
    super(route, rulesSvc, userSvc);
  }

  ngOnInit(): void {
    //call super
    super.ngOnInit();
    setTimeout(() => {
      this.refresh();
    }, 10);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  setRule(r:Rule){
    this.selectedRule = r;
    this.ruleSelected.emit(r);
  }

}
