import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ignoreElements } from 'rxjs/operators';
import { UserService } from 'src/app/common/services/user/user.service';

@Component({
  selector: 'app-create-home-icon',
  templateUrl: './home-icon.component.html',
  styleUrls: ['./home-icon.component.css']
})
export class HomeIconComponent implements OnInit {

  constructor(
    public userSvc: UserService,
    public routerSvc: Router
  ) { }

  ngOnInit(): void {
  }

  home(){
    //get confirmation that there are no unsaved changes
    if( !confirm("Have you saved the content?")  ){
      return;
    }

    this.routerSvc.navigateByUrl(`/dashboard/${this.userSvc.user.role}`)
  }

}
