<div class="columns">
    <div class="row occupySpace">
        <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="radio-margin">
            <mat-radio-button 
                class="" 
                *ngFor="let q of questionTypes" 
                [value]="q"
                (change)="onQuestionTypeChanged($event)"
            >
                {{q}}
            </mat-radio-button>
        </mat-radio-group>
        <mat-form-field appearance="fill">
            <mat-label>Questions</mat-label>
            <mat-select [(ngModel)]="numberOfQuestions" name="view">
            <mat-option *ngFor="let i of selectNumberOfQuestions" [value]="i">
                {{i}}
            </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-checkbox class="example-margin" 
        [(ngModel)]="assessment"
        *ngIf="slide.components[0].id === component.id">
            Assessment
        </mat-checkbox>
        <button class="btn-height" mat-raised-button color="primary" (click)="rule()">
          Set Rule
        </button>
    </div>
    <div>
        <app-text [component]="component" [slide]="slide"></app-text>
    </div>
    <div>
        <div class="radio-group" *ngIf="component.qType === questionTypeEnum.radio">
            <div class="row row-children" *ngFor="let q of component.answers; index as i" >
                <input type="radio" 
                    value="{{i}}" name="{{component.id}}"
                    (change)="onQuestionChangedRadio(q, $event)"
                    [checked]="q.value"
                >
                <div>
                    <mat-form-field appearance="legacy" class="width100">
                        <mat-label>text</mat-label>
                            <input [ngModel]="q?.key"  
                                (ngModelChange)="q.key = $event" 
                            matInput placeholder="Placeholder">
                    </mat-form-field>
                 </div>
            </div>
        </div>

        <div class="radio-group" *ngIf="component.qType === questionTypeEnum.checkbox">
            <div class="row row-children" *ngFor="let q of component.answers; index as i" >
                <input type="checkbox" 
                    value="{{i}}" 
                    (change)="onQuestionChangedCheckbox(q, $event)"
                    [checked]="q.value"
                >
                <div>
                    <mat-form-field appearance="legacy" class="width100">
                        <mat-label>text</mat-label>
                            <input [ngModel]="q?.key"  
                                (ngModelChange)="q.key = $event" 
                            matInput placeholder="Placeholder">
                    </mat-form-field>
                 </div>
            </div>
        </div>
    </div>
</div>
