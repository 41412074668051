import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShowPresentationRouteService, ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { FlatComponent, FlatMarker, IPTikzSvgComponent, IPTikzTextComponent, KeyValue, PSlideComponentEnum } from 'src/app/models';

@Component({
  selector: 'app-show-tikz',
  templateUrl: './show-tikz.component.html',
  styleUrls: ['./show-tikz.component.css']
})
export class ShowTikzComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe$ = new Subject();
  public componentIndex:number = 0;
  public curentMarkerIndex:number = 0;

  public slideComponentEnum = PSlideComponentEnum;


  public textComponent!:IPTikzTextComponent;
  public flatMarker!:FlatMarker;

  public tikzComponent!: IPTikzSvgComponent;

  public _component!:FlatComponent
  get component():any{
    return this._component;
  }
  @Input() set component(slide:any){
    this._component = slide as FlatComponent;
    this.tikzComponent = this._component.component as IPTikzSvgComponent;
  }

  constructor(
    private spSvc:ShowPresentationService,
    private routerSvc:ShowPresentationRouteService,

  ) { }
  ngOnInit(): void {
    this.routerSvc.routeChanged$
    .pipe( takeUntil( this.ngUnsubscribe$ ) )
    .subscribe(  (routeInfo:KeyValue<string, number>[]) => {
        let sid = routeInfo[0].value;
        this.componentIndex = this.spSvc.flatPresentation.slides[sid].components.indexOf(this.component);
        //Get the marker from the routeInfo 
        let i = routeInfo[2].value;
        this.curentMarkerIndex = i;
        this.setSvg();
    } )

    this.spSvc.orientationChanged$.subscribe((value:any) => {
      setTimeout(() => {
        this.setSvg();
      }, 10);
    })

    this.spSvc.stepperSlideChanged$.subscribe( i => {
      if(this.componentIndex == i){
        setTimeout(() => {
          this.setSvg();
        }, 10);
      }
    } )
  }

  ngAfterViewInit(): void {
      this.setSvg();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  updateView(){
    this.setSvg();

  }

  setSvg(){
    //get element by id
    let element = document.getElementById(`tikz_${this.tikzComponent.id}`);
    if( !element ){
      return
    }
    //create a new div element 
    let newDiv = document.createElement("div");
    //set the id of the new div
    newDiv.id = `tikz_${this.tikzComponent.id}`;
    //set the inner html of the new div
    newDiv.innerHTML = this.tikzComponent.tikz;
    //set the width of new div to 100%
    newDiv.style.width = "100%";
    //get the first element of the newDiv
    let firstElement = newDiv.firstElementChild as HTMLElement;
    firstElement.style.width = "100%";
    //set the id of the first element
    firstElement.id = `svg_${this.tikzComponent.id}`;
    //replace the outerHTML of the element with the new div
    element!.outerHTML = newDiv.outerHTML;

    setTimeout(() => {
      //get the groups
      this.drawMarker();
    }, 10);
  }


  //draw Marker
  drawMarker(){
    //get the marker with index currentMarkerIndex
    let marker = this.component.markers[this.curentMarkerIndex] as FlatMarker;
    this.flatMarker = marker;
    if( !marker ){
        return;
    } 
    let component = marker.component as IPTikzTextComponent;
    this.textComponent = component;
    //Iterate over groups in component
    for(let group of component.groups){
      //get the group with id
      let groupElement = document.getElementById(group.key);
      //hide the element if the group value is false
      if(!group.value){
        groupElement!.style.display = "none";
      }
      //show the element if the group value is true
      else{
        groupElement!.style.display = "block";
      }
    }
    this.drawArrow()
  }

  drawArrow(){
    let gClientRect = document.getElementById(this.textComponent.markerGroupId)?.getBoundingClientRect();
    let textDiv = document.getElementById(`explain_${this.tikzComponent.id}`);
    let textDivRect = document.getElementById(`explain_${this.tikzComponent.id}`)?.getBoundingClientRect();

    if( !gClientRect || !textDivRect ){
      setTimeout(() => {
          this.drawArrow();
      }, 10);
      return; 
    }

    let svgClientRect = this.getSvg()?.getBoundingClientRect();

    //get the svg relative coords 
    gClientRect!.x -= svgClientRect!.x;
    gClientRect!.y -= svgClientRect!.y;

    let xStart =0, yStart = 0, xEnd = 0, yEnd = 0;

    //We are in row layout or column layout 
    if(  textDivRect!.y + 10 > svgClientRect!.bottom ){
      //starting coordinates of path
      xStart = gClientRect!.x + gClientRect!.width/2;
      yStart = gClientRect!.y + gClientRect!.height;
      //end coordinates of the path
      xEnd = xStart;
      yEnd = svgClientRect!.height + textDiv!.offsetTop - 20;
      textDiv!.style.marginTop = "0px"
    }
    else {
      //starting coordinates of path
      xStart = gClientRect!.x + gClientRect!.width;
      yStart = gClientRect!.y + gClientRect!.height/ 2;

      //end coordinates of the path
      xEnd = svgClientRect!.width + textDiv!.offsetLeft - 20;
      //xEnd += textDiv!.offsetLeft;

      yEnd = yStart;

      setTimeout(() => {
        //Must always get new reference to the textDiv else 
        //position is not set correctly
        let textDiv = document.getElementById(`explain_${this.tikzComponent.id}`);
        //textDiv!.style.position = "absolute";
        textDiv!.style.marginTop =  yStart - 20 + "px";
        console.log(`top set to ${textDiv!.style.top}`);
      }, 10);
      
      

    }

    

    var dStrLeft = "M" +
    (xStart) + "," + (yStart) + " " +
    (xEnd) + "," + (yEnd);


    let path = document.getElementById(`arrow_path_${this.tikzComponent.id}`)
    path!.setAttribute("d", dStrLeft);

    this.setGMarker();

  
    setTimeout(() => {
      this.mathjax()
    }, 10);
    
  }
  

  setGMarker(){
    //g_arrowhead_{{tikzComponent.id}}
    let g = document.getElementById(`g_arrowhead_${this.tikzComponent.id}`);
    //set attribute to the group
    g!.setAttribute("marker-end", `url(#arrowhead_${this.tikzComponent.id})`);
  }

  getSvg(){
    return document.getElementById(`svg_${this.tikzComponent.id}`);
  }

  public mathjax(){
    let p = window.MathJax.typesetPromise() as any;
    //resolve the promiose p 
    p.then(() => {
    })
    .catch((err:any) => {
      console.log(err);
    });
  }
}
