<div  *ngIf = "(spSvc.showHidePaginator$ | async)">
    <div class="container" *ngIf="!lastSlide">
        <!-- angular button -->
        <button mat-raised-button (click)="prev()"
        [disabled] = "index === 0"
        color="warn"
        >Previous</button>
        <mat-divider [vertical]="true"></mat-divider>
        <button mat-raised-button color="warn"
        [disabled] = "index === markers.length - 1" 
        (click)="next()">Next</button>
        <mat-divider [vertical]="true"></mat-divider>
        <span *ngIf = "spSvc.showSpeechSynthesie$|async">
            <button 
                *ngIf = !(spSvc.speechSynthesisUtterance$|async)
                mat-raised-button (click)="speak(true)"
                color="warn">
                <mat-icon > volume_off  </mat-icon>
            </button>
            <button 
                *ngIf = (spSvc.speechSynthesisUtterance$|async)
                mat-raised-button (click)="speak(false)"
                color="primary">
                <mat-icon > volume_up  </mat-icon>
            </button>
        </span>
    </div>
    <div *ngIf="lastSlide" class="container">
        <button mat-raised-button (click)="done()"
        color="warn"
        >Done</button>
    </div>

</div>
