import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, pipe, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FlatMarker, FlatPresentation, IPQuestionComponent, IPresentation, IPSlide, KeyValue, PImageComponent, Presentation, PSlideComponentEnum } from 'src/app/models';
import { PresentationService } from '../presentation/presentation.service';
import { ScoreService } from '../score/score.service';



@Injectable({
  providedIn: 'root'
})
export class ShowPresentationRouteService {

    public routeChanged$ = new BehaviorSubject<KeyValue<string, number>[] >([]);
    public current!:KeyValue<string, number>[];


    constructor(private router: Router) { 
        this.setRoute()
    }
    setRoute() {
        this.router.events.pipe(
          filter( event => event instanceof NavigationEnd ),
        ).subscribe( (event) => {
            let tree = this.router.parseUrl( this.router.url );
            let g = tree.root.children.primary;
            if( !g ){
              return;
            }
            //iterate from 2 element to the end
            //Url is of form: /slide/12/c/12/sc/13
            let index = g.segments.findIndex( (segment) => segment.path === 'slide');
            let routeInfo:KeyValue<string, number>[] = []; 
            if( index != -1 ){  
              for (let i = index; i < g.segments.length; i+=2) {
                  routeInfo.push({key: g.segments[i].path, value: +g.segments[i+1].path});
              }
            }
            this.current = routeInfo;
            this.routeChanged$.next(routeInfo);
        });
    }

    //function to get current route info
    getCurrentRoute(){
        return this.current;
    }




}



@Injectable({
  providedIn: 'root'
})
export class ShowPresentationService {

  loadedImages: any[] = [];

    //Show paginator for value true and hide it on false
  public showHidePaginator$ = new BehaviorSubject<boolean>(true);

  public currentPresentation!: Presentation;
  public flatPresentation! : FlatPresentation;
  //subject to emit when stepper slide is changed
  public stepperSlideChanged$ = new BehaviorSubject<number>(0);
  public orientationChanged$ = new Subject();
  public currentSlide$ = new BehaviorSubject<number>(0);

  //Subject to tell that user has finished the slide presentation by 
  //clicking on the Done button in the paginator
  public slideDone$ = new Subject<boolean>();



  public showHint$ = new BehaviorSubject<boolean>(false);
  public showPresentationTime$ = new BehaviorSubject<number>(0);
  public presentationLoaded$:Subject<boolean> = new Subject<boolean>();


  //Subject used to communicate between paginator and show-text component
  //to start or stop the sound syntheseis utterance. 
  public speechSynthesisUtterance$ = new BehaviorSubject(false);
  //Subject used in the paginator to show the right icon for the paginator
  public showSpeechSynthesie$ = new BehaviorSubject(true);

  constructor(
    private pSvc: PresentationService,
    private scoreSvc: ScoreService
  ) {}


  //get presentation from route
  getPresentationFromRoute(route: ActivatedRoute){
    this.presentationLoaded$.next(false);
    //call the getPresentation method from presentation service
    return this.pSvc.getPresentationFromRoute(route);
  }

  getFlatPresentation(presentation:Presentation){
    if( this.flatPresentation && 
        this.flatPresentation.presentation.firebaseId === presentation.firebaseId
      ){
        this.presentationLoaded$.next(true);
        return this.flatPresentation
    }
    else {
      return this.createFlatPresentation(presentation);
    }
  }

  //Create flatPresentation from presentation
  createFlatPresentation(presentation: Presentation){
    //create a new presentation
    this.flatPresentation = new FlatPresentation(presentation);
    //this.presentationLoaded$.next(true);
    setTimeout(() => {
      this.preloadImages();
    }, 10);
    let s = this.scoreSvc.setCurrentFlatPresentation(this.flatPresentation);
    s.subscribe(()=> {
      this.presentationLoaded$.next(true);
    })
    this.setPresentationTime();
    return this.flatPresentation
  }

  setPresentationTime(){
    let time = 10 * 60
    /*if( !this.flatPresentation.isTheory() ){
      time = this.flatPresentation.slidesCount() * 10 * 60;  
      
    }*/
    this.showPresentationTime$.next(time);
  }
  

  //get the first url for the slide index passed as parameter
  getFirstUrl(slideIndex: number){
    //get the slide for the index
    let slide = this.flatPresentation.slides[slideIndex];
    return `slide/${slideIndex}/c/0/sc/0`;
  }


  preloadImages(){
      //iterate through all the slides in flat presentation
      for(let i = 0; i < this.flatPresentation.slides.length; i++){
        //iterate through all the components in the slide
        for(let j = 0; j < this.flatPresentation.slides[i].components.length; j++){
          //get the component
          let component = this.flatPresentation.slides[i].components[j].component;
          //if the component is a image
          if(component.type === PSlideComponentEnum.image){
            let ic = component as PImageComponent;
            //get the image url
            let url = ic.src;
            //if the url is not already loaded
            if(this.loadedImages.indexOf(url) === -1){
              //load the image
              let img = new Image();
              img.src = url;
              //add the url to the loaded images array
              this.loadedImages.push(url);
            }
          }
        }
      }
  }
}
