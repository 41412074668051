<mat-card >
    <mat-card-header>
        <mat-card-title>
            <button  mat-raised-button
                color="warn">
                Lookback
            </button>
            My mistake in this question was
        </mat-card-title>
        <mat-card-subtitle>Write a short note so that we do not repeat mistake again.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div>
            <editor
            [(ngModel)]="note"
            apiKey="ybfsof6ix4fia55ylhfhcf7p0mv7zzcgjag8pq1u0mq6x7c0"
            [init]="{
            height: 150,
            menubar: false,
            plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
            'undo redo | formatselect | bold italic backcolor | image |\
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help'
        }"
        ></editor>
        </div>
    </mat-card-content>
    <mat-card-actions mat-dialog-actions>
        <button mat-button [mat-dialog-close]="note" cdkFocusInitial>Ok</button>
    </mat-card-actions>
</mat-card>
