<div class="title">
    <mat-form-field appearance="legacy" class="width100">
        <mat-label>Component Name</mat-label>
        <input [ngModel]="component?.title"  
        (ngModelChange)="component.title = $event" 
        matInput placeholder="Placeholder">
      </mat-form-field>
      <button *ngIf="isImageComponent"
            mat-raised-button 
            class="showHide"
            color="primary"
            (click)="reuseImage()">
        Use Image
      </button>
      <button 
            mat-raised-button 
            class="showHide"
            color="primary"
            (click)="showHide()">
        Show/Hide
      </button>
      <button mat-button (click)="deleteComponent()" ><mat-icon>delete</mat-icon></button>
</div>
