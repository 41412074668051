<div>
  <mat-vertical-stepper 
    [linear]="false" 
    #stepper (selectionChange)="stepChanged($event)">
        <mat-step *ngFor="let c of (components$ | async); index as i" >
          <ng-template  matStepLabel><app-step 
            *ngIf="!isTheory && c.component.step && c.component.step != -1"
            [component]="c" 
            ></app-step>  {{c.component.title}}</ng-template>
            <div *ngIf="currentVisibleComponentIndex === i">
              <app-show-text *ngIf="c.component.type === slideComponentEnum.text" [component]="c" ></app-show-text>
              <app-show-image *ngIf="c.component.type === slideComponentEnum.image" [component]="c" ></app-show-image>
              <app-show-tikz *ngIf="c.component.type === slideComponentEnum.tikz" [component]="c" ></app-show-tikz>
              <app-show-question *ngIf="c.component.type === slideComponentEnum.question" [component]="c" [slide]="slide"></app-show-question>
              <app-assessment *ngIf="c.component.type === slideComponentEnum.assessment" ></app-assessment>
            </div>
        </mat-step>
      </mat-vertical-stepper>
</div>



