import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore'
import { AngularFireAuth } from '@angular/fire/auth'
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from 'src/app/models/users';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public collName:string =  "users"

  public user!:User;
  public LoggedInUser:any;
  public userAuthStateChanged$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userName:string = "Annonymus User";
  constructor(
    private authSvc: AngularFireAuth,
    private firestore: AngularFirestore,
  ) {
      this.authSvc.onAuthStateChanged( (user) => {
        this.setLoggedInUser(user);
     })
   }

   public setLoggedInUser(user : any){
    if( user ){
      this.LoggedInUser = user;
      this.getUser(this.LoggedInUser.email)
      .subscribe( (u:any) => {
        this.userName = this.LoggedInUser.email.split("@")[0];;
        this.userAuthStateChanged$.next(true);
      } )
    }
  }

  public getLoggedInUserName(){
    if(this.LoggedInUser){
      return this.LoggedInUser.email.split("@")[0];
    }
    else {
      return "Annonymous User"
    }
  }

  public getUser(emailId:string){
    return this._getUser(emailId)
  }

  //get user from firestore collection with collName
  private _getUser(emailId:string){
    //create a subject for user
    let s = new Subject<User>();

    this.firestore.collection(this.collName)
      .doc(emailId)
      .get()
      .subscribe( (snapshot) => {

        if(  snapshot.exists ){
          let user = new User();
          let d = snapshot.data();
          user.fromJson(d);
          this.user = user;
        }
        else {
          this.user = new User();
        }

        s.next(this.user);
      } )

    return s;
  }

  getUserId(){
    return this.LoggedInUser.uid;
  }

  isUserLoggedIn(){
    return this.LoggedInUser != null;
  }

}
