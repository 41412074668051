import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RulesService } from 'src/app/common/services/rules/rules.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { FlatRuleNode, Rule, RulesDoc } from 'src/app/models';

declare global {
  interface Window {
    MathJax: {
      tex: {inlineMath: [['$', '$'], ['\\(', '\\)']]}
      processEscapes: true,
      typesetPromise: () => void;
      typeset: any;
    };
  }
}

@Component({
  selector: 'app-create-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit, OnDestroy {
  public ngUnsubscribe = new Subject<void>();
  public rulesDoc!:RulesDoc

  private _transformer = (node: Rule, level: number) => {
    return {
        expandable: !!node.children && node.children.length > 0,
        level: level,
        inEditMode: false,
        rule: node
    }
  };

  treeControl = new FlatTreeControl<FlatRuleNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children as Rule[],
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  constructor(
    public route: ActivatedRoute,
    public rulesSvc: RulesService,
    public userSvc: UserService
  ) { }

  ngOnInit(): void {
  }

  hasChild = (_: number, node: FlatRuleNode) => node.expandable;

  refresh = () => {
    this.dataSource.data = this.rulesDoc.rules as Rule[];
    this.treeControl.expandAll();
    setTimeout( () => {
      this.mathjax();
    },  )    
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public mathjax(){
    window.MathJax.typesetPromise();
  }


}
