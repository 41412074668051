<!-- Add two divs with display flex and flex-direction as column    -->
<div class="columns" *ngIf="marker">
    <div> 
        <mat-toolbar>
            <div>
                <button mat-icon-button (click)="addText()">
                    Text
                </button>
                <button mat-icon-button (click)="addQuestion()">
                    Question
                </button>
            </div>
            <div>
                <button mat-button (click)="deleteMarker()"><mat-icon>delete</mat-icon></button>
            </div>
            <div>
                <button 
                    mat-icon-button
                    (click)="rearrange()"
                    color="warning">
                    <mat-icon>list</mat-icon>
                </button>
            </div>
        </mat-toolbar>        
    </div>
    <div>
        <mat-card  *ngFor="let c of (components$ | async)" [ngClass]="{'selected-marker':c.id === selectedComponent?.id}">
            <mat-card-title>{{c.id}}</mat-card-title>
            <mat-card-actions>
                <button mat-button (click)="edit(c)"><mat-icon>edit</mat-icon></button>
                <button mat-button (click)="delete(c)" ><mat-icon>delete</mat-icon></button>
            </mat-card-actions>
          </mat-card>
    </div>
    <div *ngIf="selectedComponent">
        <app-text  *ngIf="selectedComponent.type === slideComponentEnum.text" [component]="selectedComponent" [slide]="slide"></app-text>
        <app-question *ngIf="selectedComponent.type === slideComponentEnum.question" [component]="selectedComponent" [slide]="slide"></app-question>
    </div>
</div>
<div *ngIf="!marker">
    Add or select marker
</div>

