import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PBaseSlide, IPSlide, Presentation, PSlideComponentEnum, PTextComponent, PImageComponent, PTikzSvgComponent, KeyValue, IMarker, PQuestionComponent, RulesDoc, UID, uid } from 'src/app/models';
import { FirebaseService } from '../firebase/firebase.service';
import { RulesService } from '../rules/rules.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class PresentationService {

  public currentPresentation$:BehaviorSubject<Presentation|null> = new BehaviorSubject<Presentation|null>(null); 
  public currentPresentation!: Presentation;
  public presentationRules!:RulesDoc;
  public authorPresentations:Presentation[] = [];
  //Subject to notify the common option component that 
  //first component of the slide has been selected as assessment
  public assessmentChanged$:Subject<boolean> = new Subject<boolean>();

  public thoughtProcessPresentation!:Presentation;

  constructor(
    private fbSvc: FirebaseService,
    private userSvc: UserService,
    private rulesSvc: RulesService
  ) { }

  getThoughtProcessPresentation(){
    let s = new Subject<Presentation>();
    if( this.thoughtProcessPresentation){
      setTimeout(() => {
        s.next(this.thoughtProcessPresentation);
        s.complete();
      }, 0);
    }
    else {
      //thought process id is constant 
      let id = "Uah0P3cuy5HnYY6ktRJJ";
      this.fbSvc.getPresentation(id)
      .subscribe( (presentation) => {
        if( presentation ){
          s.next(presentation);
          this.thoughtProcessPresentation = presentation;
          s.complete();
        }
      })
    }

    return s;
  }


  getPresentationForSearch(id:string){
    return this.fbSvc.getPresentation(id);
  }

  //get presentation from firebase and return Subject with presentation
  getPresentation(id: string){  
    let s = new Subject<Presentation>()

    s.subscribe( (presentation) => {
      this.currentPresentation = presentation;
      this.getRuleDocForPresentation(presentation.tag);
    });

    //get presentation from author presentations if it is there
    let presentation = this.authorPresentations.find( (presentation) => {
      return presentation.firebaseId === id;
    });

    if(  presentation  || 
      (this.currentPresentation && this.currentPresentation.firebaseId == id) ) {
      setTimeout(() => {
        if( !presentation ){
          presentation = this.currentPresentation;
        }
        s.next(presentation);
        s.complete();
        this.currentPresentation$.next(this.currentPresentation);
      }, 0);
      return s;
    } 



    this.fbSvc.getPresentation(id)
    .subscribe( (presentation) => {
      if( presentation ){
        s.next(presentation);
        //notify that we have new presentation
        this.currentPresentation$.next(presentation);
        s.complete();
      }
    })
  
    return s;
  }
    

  getRuleDocForPresentation(tag:string){
    this.rulesSvc.getRulesDocByTag(tag)
    .subscribe( (rulesDoc) => {
      console.log("got rules doc");
    });
  }

  //get Presentation from the id specified in route
  getPresentationFromRoute(route: ActivatedRoute){
    let s = new Subject<Presentation>()
    route.params.subscribe( (params) => {
      let id = params["id"];
      this.getPresentation(id).subscribe( (presentation) => {
        s.next(presentation);
        s.complete();
      });
    });
    return s;
  }

  //save presentation
  savePresentation(presentation:Presentation){
    //update the last used id
    presentation.lastUsedId = uid.getId()
    this.fbSvc.savePresentation(presentation)
    .subscribe( (snapshot) => {
      console.log("Document saved");
      if(this.currentPresentation 
        && this.currentPresentation.firebaseId == presentation.firebaseId){
        this.currentPresentation = presentation;
      }
      },
      (error:any) => {
        console.log(error);
      }
    );
  }


  //method to create presentation
  createPresentation(tag:string){
    let presentation = new Presentation("New Presentation", tag);
    presentation.setAuthorId( this.userSvc.LoggedInUser.uid  );
    presentation.setCompanyId( this.userSvc.user.company )
    return this.fbSvc.createPresentation(presentation)
 }


  //add slide to current presentation
  addSlide(){
    let slide = new PBaseSlide();
    this.currentPresentation.slides.push(slide);
    return slide.id;
  }
  //get slide from current presentation slides by id
  getSlide(id:string){
    return this.currentPresentation.slides.find( (slide) => {
      return slide.id === id;
    });
  }
  //add text component to the slide passed as parameter
  addTextComponent(slide:IPSlide){
    let textComponent = new PTextComponent("Add text here");
    slide.components.push(textComponent);
  }

  //add image component to the slide passed as parameter
  addImageComponent(slide:IPSlide){
    let imageComponent = new PImageComponent();
    slide.components.push(imageComponent);
  }


  //add tikz component to the slide passed as parameter and return component
  addTikzComponent(slide:IPSlide){
    let tikzComponent = new PTikzSvgComponent();
    slide.components.push(tikzComponent);
    return tikzComponent;
  }

  addQuestionComponent(slide:IPSlide){
    let questionComponent = new PQuestionComponent("Add question here");
    slide.components.push(questionComponent);
    return questionComponent;
  }

  //delete component from slide
  deleteComponent(slide:IPSlide, component:any){
    let index = slide.components.indexOf(component);
    if( index > -1 ){
      slide.components.splice(index, 1);
    }
  }

  //delete slide from presentation
  deleteSlide(slide:IPSlide){
    let index = this.currentPresentation.slides.indexOf(slide);
    if( index > -1 ){
      this.currentPresentation.slides.splice(index, 1);
    }
  }

  //upload image to firebase storage using method of firebase service
  uploadImage(slideId:string, imageName:string,  file_url:string){
    let filePath = `${this.currentPresentation.firebaseId}/${slideId}/${imageName}`;
    return this.fbSvc.uploadImage(filePath, file_url);
  }

  //add marker to the component and return the marker
  addMarker(component:PImageComponent, x:number, y:number){
    let marker = component.addMarker(x,y);
    return marker;
  }

  //delete marker from component
  deleteMarker(component:PImageComponent, marker:IMarker){
    component.deleteMarker(marker);
  }


  //get all presentations for the logged in user
  getPresentations(tag:string){
    return this.fbSvc.getPresentationsForAuthor(this.userSvc.LoggedInUser.uid, tag)
    .pipe( map( (presentations) => {
      this.authorPresentations = presentations;
      return presentations;
    }) ) as Subject<Presentation[]>;
  }

}
