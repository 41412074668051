<div class="toolbar">
    <mat-toolbar>
        <span>
        <mat-form-field >
            <mat-label>Slide title</mat-label>
            <input [ngModel]="slide?.title"  
            (ngModelChange)="slide.title = $event" 
            matInput placeholder="Placeholder">
        </mat-form-field>
        </span>
      <button 
            mat-raised-button 
            color="primary"
            (click)="addText()">
        Text
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button 
            mat-raised-button 
            color="primary"
            (click)="addImage()">
        Image
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button 
            mat-raised-button 
            color="primary"
            (click)="addTikz()">
        Tikz
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button 
            mat-raised-button 
            color="primary"
            (click)="addQuestion()">
        Question
      </button>
    </mat-toolbar>
</div>
<div class="column main" *ngIf="slide && slide.components && slide.components.length">
    <div class="component" *ngFor="let c of slide.components" >
        <div>
            <app-title [component]="c" [slide]="slide"></app-title>
        </div>
        <div *ngIf="c.type === slideComponentEnum.text" id="component_{{c.id}}">
            <app-text [component]="c" [slide]="slide"></app-text>
        </div>
        <div *ngIf="c.type === slideComponentEnum.image" id="component_{{c.id}}">
            <app-image [component]="c" [slide]="slide"></app-image>
        </div>
        <div *ngIf="c.type === slideComponentEnum.tikz" id="component_{{c.id}}">
            <app-tikz [component]="c" [slide]="slide"></app-tikz>
        </div>
        <div *ngIf="c.type === slideComponentEnum.question" id="component_{{c.id}}">
            <app-question [component]="c" [slide]="slide"></app-question>
        </div>
    </div>
</div>
