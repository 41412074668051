import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from "firebase";

import * as firebaseui from 'firebaseui'
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../common/services/user/user.service';
import { LoginService } from './services/login/login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>();

  public ui! : firebaseui.auth.AuthUI;
  public showMessage:boolean = false;
  
  constructor(
    private loginSvc:LoginService,
    private userSvc: UserService,
    private router: Router,
    private zone:NgZone,
    private activeRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.loggedInEvent()


    var uiConfig = {
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult:this.onLoginSuccessfull.bind(this),
      }  
    };


    this.ui = this.loginSvc.getFirebaseuiInstance();
    this.ui.start('#firebaseui-auth-container', uiConfig);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  public onLoginSuccessfull(authResult:any, redirectUrl:any) : boolean{
    this.showMessage = true
    this.userSvc.setLoggedInUser( authResult.user );
    this.loggedInEvent()
    return false;
  }

  public loggedInEvent(){
    this.userSvc.userAuthStateChanged$
    .pipe(  takeUntil(this.ngUnsubscribe) )
    .subscribe(   
      (value) => {
        if(value){
          this.zone.run( () => {
            let redirect = ""
            this.activeRoute.queryParams.subscribe( (params) => {
              if(params.redirect){
                redirect = params.redirect
              }
            });
            if(redirect){
              this.router.navigateByUrl(redirect);
            }
            else if(  this.userSvc.user && this.userSvc.user.role){
              this.router.navigate([`/dashboard/${this.userSvc.user.role}`]);
            }
            else {
              this.router.navigate(['/']);
            }
          })
        }
      }
     )
  }

}
