import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { IPSlide, IPSlideComponent, IPTikzQuestionComponent, IPTikzTextComponent, KeyValue, PSlideComponentEnum, PTikzSvgComponent } from 'src/app/models';
import { DialogService } from '../../services/dialog/dialog.service';

@Component({
  selector: 'app-tikz-marker',
  templateUrl: './tikz-marker.component.html',
  styleUrls: ['./tikz-marker.component.css']
})
export class TikzMarkerComponent implements OnInit {

  public _selectedComponent!:IPTikzTextComponent |  IPTikzQuestionComponent | null;

  @Input() set selectedComponent(component:IPTikzTextComponent |  IPTikzQuestionComponent | null){
    this._selectedComponent = component;
  }

  get selectedComponent():IPTikzTextComponent |  IPTikzQuestionComponent | null{
    return this._selectedComponent;
  }
  

  public slideComponentEnum = PSlideComponentEnum;


  public _component!:PTikzSvgComponent;
  get component():any{
    return this._component;
  }
  @Input() set component(slide:any){
    this._component = slide as PTikzSvgComponent;
    setTimeout(() => {
      this.components$.next(this._component.components as IPTikzTextComponent[]);   
    }, 10);
  }

  @Input() groups!: KeyValue<string, boolean>[];

  @Input() slide!: IPSlide;

  public components$:Subject<IPTikzTextComponent[]> = new Subject();

  constructor(
    private pSvc: PresentationService,
    public dSvc:DialogService
  ) { }

  ngOnInit(): void {

  }


  addText() {
      let c = this.component.addComponent(PSlideComponentEnum.tikzText);
      c.deepCopyGroups(this.groups);
      this.components$.next(this.component.components);
      this.selectedComponent = c;
  }

  addQuestion(){
      let c = this.component.addComponent(PSlideComponentEnum.tikzQuestion);
      c.deepCopyGroups(this.groups);
      this.components$.next(this.component.components);
      this.selectedComponent = c;
  }

  edit(component:IPSlideComponent){
    this.selectedComponent = component as IPTikzTextComponent;
  }

  delete(component:IPSlideComponent){
    this.component.deleteComponent(component);
    this.selectedComponent = null
  }

  groupChanged(group:KeyValue<string, boolean>){
    //toogle the value of the group in the map
    let sc = this.selectedComponent as IPTikzTextComponent;
    //Find the element in sc.groups with the same id as group.key
    let element = sc.groups.find(x => x.key === group.key);
    //if the element is found, toggle the value
    if(element){
      element.value = !element.value;
    }
  }

  rearrange(){
    this.dSvc.openRearrangeDialog(this.component.components);
  }
}
