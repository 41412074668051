import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { MessageComponent } from 'src/app/common/dialogs/message/message.component';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';
import { IPSlide, IPSlideComponent } from 'src/app/models';


@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {
  private message$ = new BehaviorSubject<string>("Please wait while we upload image");



  //subject to emit the url of the uploaded file
  @Output() url = new EventEmitter<string>();

  //input to take component
  @Input() component!:IPSlideComponent;

  //input to take slide
  @Input() slide!:IPSlide;

  constructor(
     private pSvc:PresentationService,
     public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  resizeImage(file:any){
    return new Promise( (resolve, reject) => {
      var fr = new FileReader();
      fr.onload = (fr_e:any) => {
        var canvas = <HTMLCanvasElement>document.getElementById(`canvas_${this.slide.id}`);
        var context = canvas.getContext('2d');
        var imageObj = new Image();
        imageObj.onload = (io_e)=> {
          var image_height = imageObj.naturalHeight;
          var image_width = imageObj.naturalWidth;
  
          canvas.setAttribute("width", Math.round(image_width) + "")
          canvas.setAttribute("height", Math.round(image_height) + "")
  
          context!.drawImage(imageObj, 0, 0, image_width, image_height);
          let dataurl = canvas.toDataURL("image/jpeg", .3);
          resolve(dataurl);
        }
        imageObj.src = fr_e.target.result as string;
  
      };
      fr.readAsDataURL(file)
    });
    
  }  

  uploadImage($event:any){

    this.dialog.open( MessageComponent, {
      data: this.message$
    } )

    let img =  $event.target.files[0];
    let imageName = img.name;
    this.resizeImage(img).then( (img:any) => {
      let obs$ = this.pSvc.uploadImage(this.slide.id, imageName ,img)
      obs$.subscribe( (url : string) => {
          //this.component.src = url as string;
          this.url.emit(url);
          this.dialog.closeAll();
      },
      (error:any) => {
        console.log(error);
      },
      () => {
        console.log("completer")
      } )
    }  )
  }

  chooseFile(){
    document.getElementById(`file_${this.component.id}`)?.click();
  }
}
