<div class="header">
	<div class="container">
		<div class="row">
			<div class="d-flex">
				<div class="align-self-center"><img src="/assets/images/logo.png" /></div>
				<div class="align-self-center">
					<a  *ngIf="!(userSvc.userAuthStateChanged$|async)" routerLink="/login">
                        <button type="button" class="btn btn-primary">Login</button>
                    </a>
                    <a *ngIf="(userSvc.userAuthStateChanged$|async)" routerLink="/logout">
                        <button type="button" class="btn btn-primary">Log out</button>
                    </a>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="thought-process">
	
	<div class="thought">
		<div class="container">
			<div class="blub-image">
				<div class="blub"><img src="/assets/images/blub.png"></div>
			</div>
			<div class="row">
				<div class="col-8 text-right">
					<h1>The Key to success in any field is the <span>thought process</span>.</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h5>HOW TO BUILD THOUGH PROCESS</h5>
			<h2>You can build the thought process by solving all problems using below four steps :</h2>
			</div>
		</div>
		<div class="row mt-5 thought-steps">
			<div class="col-3">
				<span>01</span>
				<h3>Understanding<br />
the problem</h3>
				<ul>
					<li>What is unknown?</li>
					<li>What is data?</li>
					<li>What is condition?</li>
				</ul>
			</div>
			<div class="col-3">
				<span>02</span>
				<h3>Devising<br />
the plan</h3>
				<ul>
					<li>Have you seen it before?</li>
					<li>Do you know a related problem?</li>
					<li>Look at the unknown! And try to think of a familiar problem having the same or a similar unknown.</li>
					<li>Can you restate the problem?</li>
				</ul>
			</div>
			<div class="col-3">
				<span>03</span>
				<h3>Carrying <br />
out the plan</h3>
				<ul>
					<li>Solve the problem step by step.</li>
					<li>Check each step.</li>
					<li>Can you clearly see that the step is correct?</li>
					<li>Can you prove that it is correct?</li>
				</ul>
			</div>
			<div class="col-3">
				<span>04</span>
				<h3>Looking<br />
back</h3>
				<ul>
					<li>Can you clearly see how correct result was arrived at?</li>
					<li>Does the method used for solving the problem makes sense to you?</li>
					<li>Can you use the result, or the method, for some other problem?</li>
				</ul>
			</div>
		</div>
	</div>
</div>
<div class="course">
	<div class="container">
		<div class="row">
			<div class="col-12" >
            	<div class="heading">
                <h1>Explore how we help you build thought process by practicing the above steps.</h1>
					</div>
            </div>
		</div>
		<div class="row">
			<div class="col-3">
				<div class="card">
				<div class="course-image"><img src="/assets/images/math-course.jpg"></div>
				<div class="card-content">
					<div class="grade">Grade 10</div>
					<div class="rating">4.5</div>
				</div>
				<div class="card-content">
                    <a class="slide-title" routerLink="/showPresentationTimeline/rJ3Pr2llvS02AKY2EKCz/">
                        <h4>Euclid's Division Lemma</h4>
                    </a>
					
				</div>
				<div class="card-content">
					<div class="course-info">10 Chapter</div>
					<div class=" course-info">5 Assignments</div>
				</div>
					</div>
			</div>
			
			<div class="col-3">
				<div class="card">
				<div class="course-image"><img src="/assets/images/math-course.jpg"></div>
				<div class="card-content">
					<div class="grade">Grade 10</div>
					<div class="rating">4.5</div>
				</div>
				<div class="card-content">
					<a class="slide-title"  routerLink="/showPresentationTimeline/i1h0lMoMBKERwJMHOxOf/">
                        <h4>Euclid's Division Lemma Problems</h4>
                    </a>
				</div>
				<div class="card-content">
					<div class="course-info">10 Chapter</div>
					<div class=" course-info">5 Assignments</div>
				</div>
					</div>
			</div>
			<div class="col-3">
				<div class="card">
				<div class="course-image"><img src="/assets/images/math-course.jpg"></div>
				<div class="card-content">
					<div class="grade">Grade 10</div>
					<div class="rating">4.5</div>
				</div>
				<div class="card-content">
                    <a class="slide-title" routerLink="/showPresentationTimeline/Eu7wbSPZxLcH9SmQ1iOq">
					    <h4>Fundamental Theorem of Arithmetic</h4>
                    </a>
				</div>
				<div class="card-content">
					<div class="course-info">10 Chapter</div>
					<div class=" course-info">5 Assignments</div>
				</div>
					</div>
			</div>
			<div class="col-3">
				<div class="card">
				<div class="course-image"><img src="/assets/images/math-course.jpg"></div>
				<div class="card-content">
					<div class="grade">Grade 10</div>
					<div class="rating">4.5</div>
				</div>
				<div class="card-content">
                    <a class="slide-title" routerLink="/showPresentationTimeline/MobjotEclNWfssaYnBsp">
					    <h4>Fundamental Theorem of Arithmetic Exercise</h4>
                    </a>
				</div>
				<div class="card-content">
					<div class="course-info">10 Chapter</div>
					<div class=" course-info">5 Assignments</div>
				</div>
					</div>
			</div>
			
		</div>
		<div class="row">
			<div class="col-3">
				<div class="card">
				<div class="course-image"><img src="/assets/images/math-course.jpg"></div>
				<div class="card-content">
					<div class="grade">Grade 10</div>
					<div class="rating">4.5</div>
				</div>
				<div class="card-content">
                    <a class="slide-title" routerLink="/showPresentationTimeline/dwJnJp9SBzA3wXFXDARw/">
                        <h4>ARITHMETIC PROGRESSION</h4>
                    </a>
					
				</div>
				<div class="card-content">
					<div class="course-info">10 Chapter</div>
					<div class=" course-info">5 Assignments</div>
				</div>
					</div>
			</div>
			
			<div class="col-3">
				<div class="card">
				<div class="course-image"><img src="/assets/images/math-course.jpg"></div>
				<div class="card-content">
					<div class="grade">Grade 10</div>
					<div class="rating">4.5</div>
				</div>
				<div class="card-content">
					<a class="slide-title"  routerLink="/showPresentationTimeline/tigSIDBXBgouKuseXlq6/">
                        <h4>ARITHMETIC PROGRESSION - Finding Sum</h4>
                    </a>
				</div>
				<div class="card-content">
					<div class="course-info">10 Chapter</div>
					<div class=" course-info">5 Assignments</div>
				</div>
					</div>
			</div>
			<div class="col-3">
				<div class="card">
				<div class="course-image"><img src="/assets/images/math-course.jpg"></div>
				<div class="card-content">
					<div class="grade">Grade 10</div>
					<div class="rating">4.5</div>
				</div>
				<div class="card-content">
                    <a class="slide-title" routerLink="/showPresentationTimeline/7Y7sd3hpRTNrVVSFPZBA">
					    <h4>Problem in AP - Part 1</h4>
                    </a>
				</div>
				<div class="card-content">
					<div class="course-info">10 Chapter</div>
					<div class=" course-info">5 Assignments</div>
				</div>
					</div>
			</div>
		</div>
        <div class="row">
			<div class="col-3">
				<div class="card">
				<div class="course-image"><img src="/assets/images/physics-course.jpg"></div>
				<div class="card-content">
					<div class="grade">Grade 11</div>
					<div class="rating">4.5</div>
				</div>
				<div class="card-content">
					<a class="slide-title" routerLink="/showPresentationTimeline/WW365zhNB1PUXzLAoQo7">
					    <h4>Newton's Laws of Motion</h4>
                    </a>
				</div>
				<div class="card-content">
					<div class="course-info">10 Chapter</div>
					<div class=" course-info">5 Assignments</div>
				</div>
					</div>
			</div>
			
			<div class="col-3">
				<div class="card">
				<div class="course-image"><img src="/assets/images/physics-course.jpg"></div>
				<div class="card-content">
					<div class="grade">Grade +1</div>
					<div class="rating">4.5</div>
				</div>
				<div class="card-content">
					<a class="slide-title" routerLink="/showPresentationTimeline/Hm8zRmAXUYzNJ1JOfoOS">
					    <h4>Newton's Laws of Motion Problems</h4>
                    </a>
				</div>
				<div class="card-content">
					<div class="course-info">10 Chapter</div>
					<div class=" course-info">5 Assignments</div>
				</div>
					</div>
			</div>
		</div>
	</div>	
</div>