import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './common/services/authGuard/auth-guard.service';
import { CreateComponent } from './create/components/create/create.component';
import { HomeComponent } from './create/components/home/home.component';
import { PresentationMetadataComponent } from './create/components/presentation-metadata/presentation-metadata.component';
import { SlideComponent } from './create/components/slide/slide.component';
import { TextComponent } from './create/components/text/text.component';
import { RulesCreateComponent } from './create/rules/components/rules-create/rules-create.component';
import { AdminComponent } from './dashboard/admin/components/admin/admin.component';
import { AuthorComponent } from './dashboard/author/author.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ShowPresentationTimelineComponent } from './show/components/show-presentation-timeline/show-presentation-timeline.component';
import { ShowPresentationComponent } from './show/components/show-presentation/show-presentation.component';
import { ShowSlideComponent } from './show/components/show-slide/show-slide.component';
import { ShowRuleComponent } from './show/rules/components/show-rule/show-rule.component';
import { StartComponent } from './start/start.component';
import { SvgExperimentsComponent } from './svg-experiments/svg-experiments.component';
import { TimelineVerticalComponent } from './timeline-vertical/timeline-vertical.component';

const routes: Routes = [
  {
    path: "svgTest", component: SvgExperimentsComponent,
  },
  {
    path: "test", component: TextComponent,
  },
  {
    path: "createHome", component: HomeComponent,
  },
  {
    path: "createPresentation/:id", component: CreateComponent,
    children: [
      {
        path: "metadata", component: PresentationMetadataComponent,
      },
      {
        path: "slide/:sid", component: SlideComponent,
      },
    ],
    canActivate: [AuthGuardService],
  },
  {
    path: "showPresentationTimeline/:id", 
    component: ShowPresentationTimelineComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "showPresentation/:id", 
    component: ShowPresentationComponent,
    children: [
      {
        path: "**", component: ShowSlideComponent,
      },
    ],
    canActivate: [AuthGuardService],
  },
  {
    path: "login", 
    component: LoginComponent
  },
  {
    path: "dashboard/admin",
    component: AdminComponent,
  },
  {
    path: "dashboard/author",
    component: AuthorComponent,
  },
  {
    path: "logout",
    component: LogoutComponent
  },
  {
    path: "createRule/:id",
    component: RulesCreateComponent,
  },
  {
    path: "showRule/:id",
    component: ShowRuleComponent,
  },
  {
    path: "tl",
    component: TimelineVerticalComponent,
  },
  {
    path: "",
    component: StartComponent,
  }
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
