<!--create div with class columns-->
<div class="columns h-100">
    <!--create div with class header    -->
    <div class="header">
        <mat-toolbar>
            <button 
                mat-icon-button 
                class="btn"
                (click)="home()">
              <mat-icon>home</mat-icon>
            </button>
            <span class="title-center">
              {{presentation?.title}}
            </span>
            <span class="columns" *ngIf="slideState?.totalScore && !isTheory">
              <span>score</span>
              <span>{{slideState?.userScore}}/{{slideState?.totalScore}}</span>
              
            </span>
            <span>
              <countdown #cd [config]="{'leftTime': (leftTime$|async)}" (event)="handleEvent($event)"></countdown>
            </span>
          </mat-toolbar>
    </div>
    <div class="content h-100">
      <div class="main" id="main" >
        <router-outlet></router-outlet>
      </div>
    </div>
</div>

