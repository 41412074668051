import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShowPresentationRouteService, ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { FlatComponent, FlatMarker, IFlatMarker, IMarker, KeyValue, PImageComponent, PQuestionComponent, PSlideComponentEnum, PTextComponent, PTikzQuestionComponent } from 'src/app/models';

@Component({
  selector: 'app-show-image',
  templateUrl: './show-image.component.html',
  styleUrls: ['./show-image.component.css']
})
export class ShowImageComponent implements OnInit, AfterViewInit ,OnDestroy {
  //SVG variables for drawing the annotation rectangles
  private svg!:SVGSVGElement;
  public slideComponentEnum = PSlideComponentEnum;

  
  private ngUnsubscribe$ = new Subject();
  public viewBox:string = "0 0 800 380";
  
  //Flat marker which has IMarker and PTextComponent
  public subComponent!:FlatMarker;
  public componentIndex:number = 0;
  public imageComponent!: PImageComponent;
  public textComponent!:PTextComponent | PTikzQuestionComponent | PQuestionComponent | null;
  public curentMarkerIndex:number = 0;

  public _component!:FlatComponent
  get component():any{
    return this._component;
  }
  @Input() set component(slide:any){
    this._component = slide as FlatComponent;
    this.imageComponent = this._component.component as PImageComponent;
  }


  constructor(
    private routerSvc:ShowPresentationRouteService,
    private spSvc: ShowPresentationService
  ) { }
  
  
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  ngOnInit(): void {
    this.routerSvc.routeChanged$
    .pipe( takeUntil( this.ngUnsubscribe$ ) )
    .subscribe(  (routeInfo:KeyValue<string, number>[]) => {
        let sid = routeInfo[0].value;
        this.componentIndex = this.spSvc.flatPresentation.slides[sid].components.indexOf(this.component);
        //Get the marker from the routeInfo 
        let i = routeInfo[2].value;
        
        if( i){
          this.curentMarkerIndex = i;
        }
        //get the marker for i from component markers array
        this.subComponent = this.component.markers[i];
        if( this.subComponent.component && this.subComponent.component.type === PSlideComponentEnum.text ){
          this.textComponent = this.subComponent.component as PTextComponent;
        }
        else if(  this.subComponent.component && this.subComponent.component.type === PSlideComponentEnum.question ){
          this.textComponent = this.subComponent.component as PQuestionComponent;
        }
        this.updateView();
    } )

    this.spSvc.orientationChanged$
    .pipe( takeUntil( this.ngUnsubscribe$ ) )
    .subscribe((value:any) => {
      setTimeout(() => {
        this.updateView();
      }, 10);
    })

    this.spSvc.stepperSlideChanged$
    .pipe( takeUntil( this.ngUnsubscribe$ ) )
    .subscribe( i => {
      if(this.componentIndex == i){
        setTimeout(() => {
          this.updateView();
        }, 10);
      }
    } )
  }

  ngAfterViewInit(): void {
  }


  removeAllRects(){
    let parent = this.getSvg();
    if( !parent ) return;
    let children = parent.getElementsByTagName("rect")
    let len = children.length;
    for( let i = 0; i < len; i++ ){
        let child = children[0];
        child.remove();
    }
  }

  setMarkerEnd(){
    //get svg group element from document using id
    let g = document.getElementById(`g_arrow_head_${this.imageComponent.id}`) as any;
    if(g){
      g.setAttribute("marker-end", `url(#arrowhead_${this.imageComponent.id})`);
    }
  }

  async updateView(){
    try{
      this.removeAllRects();
      await this.setViewBox()
      this.drawMarker();
      this.setMarkerEnd()
      this.drawArrow();
    }catch(e){
      console.log(e);
    }
    finally{
      setTimeout(() => {
        this.mathjax()
      }, 10);
    }
  }

  imageLoaded($event:any){
    let x = $event.srcElement.getBBox();
    let width = x.width;
    let height = x.height;

    this.updateView();
  }

  //function to set the view box
  setViewBox(){
      let marker = this.subComponent.marker as IMarker;
      //component.viewHeight is the height of the viewbox
      //Image is divided into views of height component.viewHeight
      //Also we have image height in component.imageHeight
      //calculate the view in which current marker will lie
      let view = Math.floor(marker.y / this.imageComponent.viewHeight);

      //create and return a new promise
      return new Promise((resolve, reject) => {
        this.viewBox = `0 ${view * this.imageComponent.viewHeight} 800 ${this.imageComponent.viewHeight}`;
        setTimeout(() => {
          resolve(null);
        }, 10);
      })
      //set the view box now
      
  }


  getRectangle(){
    let rect = document.createElementNS("http://www.w3.org/2000/svg", 'rect');
    return rect;
  }

  updateRectangle(rect:any, x:number, y:number, width:number, height:number){
    rect.setAttribute('x', `${x}`);
    rect.setAttribute('y', `${y}`);
    rect.setAttribute('width', `${width}`);
    rect.setAttribute('height', `${height}`);
  }


  drawMarker(){
      let svg = this.getSvg()
      if( !svg ){
        return;//Image is yet not in view
      }

      let marker = this.subComponent.marker as IMarker

      //Get the rectangle
      let rect = this.getRectangle();
      //set the id attribute of the rectangle
      rect.setAttribute('id', `rect_${marker.id}`);
      //set the attributes from marker
      this.updateRectangle(rect, marker.x, marker.y, marker.width, marker.height);

      //add the rectangle to the svg
      this.svg.appendChild(rect);
  }


  getSvg(){
    let temp = document.getElementById("svg_" + this.imageComponent.id) as any;
    this.svg = temp as SVGSVGElement;
    return this.svg;
  }


  drawArrow(){
    let marker = this.subComponent.marker as IMarker

    let gClientRect = document.getElementById(`rect_${marker.id}`)?.getBoundingClientRect();
    let textDiv = document.getElementById(`explain_${this.imageComponent.id}`);
    let textDivRect = document.getElementById(`explain_${this.imageComponent.id}`)?.getBoundingClientRect();


    let svgClientRect = this.getSvg()?.getBoundingClientRect();

    //get the svg relative coords 
    gClientRect!.x -= svgClientRect!.x;
    gClientRect!.y -= svgClientRect!.y;

    let xStart =0, yStart = 0, xEnd = 0, yEnd = 0;

    //We are in row layout or column layout 
    if(  textDivRect!.y + 10 > svgClientRect!.bottom ){
      //starting coordinates of path
      xStart = gClientRect!.x + gClientRect!.width/2;
      yStart = gClientRect!.y + gClientRect!.height;
      //end coordinates of the path
      xEnd = xStart;
      yEnd = svgClientRect!.height + textDiv!.offsetTop - 10;
    }
    else {
      //starting coordinates of path
      xStart = gClientRect!.x + gClientRect!.width;
      yStart = gClientRect!.y + gClientRect!.height/ 2;

      //end coordinates of the path
      xEnd = svgClientRect!.width + textDiv!.offsetLeft - 10;
      //xEnd += textDiv!.offsetLeft;

      yEnd = yStart;
      textDiv!.style.marginTop =  yStart - 20 + "px";
      console.log(`top set to ${textDiv!.style.top}`);
    }

    

    var dStrLeft = "M" +
    (xStart) + "," + (yStart) + " " +
    (xEnd) + "," + (yEnd);


    let path = document.getElementById(`arrow_path_${this.imageComponent.id}`)
    path!.setAttribute("d", dStrLeft);
  }


  public mathjax(){
    let p = window.MathJax.typesetPromise() as any;
    //resolve the promiose p 
    p.then(() => {
    })
    .catch((err:any) => {
      console.log(err);
    });
  }
}
