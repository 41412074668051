import { Component, OnInit } from '@angular/core';
import { UserService } from '../common/services/user/user.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {



  constructor(public userSvc: UserService) { }

  ngOnInit(): void {
    
  }

}
