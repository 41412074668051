import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Presentation } from 'src/app/models';

@Component({
  selector: 'app-show-presentations',
  templateUrl: './show-presentations.component.html',
  styleUrls: ['./show-presentations.component.css']
})
export class ShowPresentationsComponent implements OnInit {


  //input for array of presentations
  @Input() public presentations$!:  Subject<Presentation[]>;

  constructor() { }

  ngOnInit(): void {
  }

}
