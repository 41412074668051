import { IPQuestionComponent, theory } from "./Presentation";
import { IDispayRulesScore, IRule, IRulesDoc } from "./rules";

export interface ISlideScore{
    totalScore:number,
    userScore:number,
    populateFromJson(score:ISlideScore):void;
}

//Implement interface IScore
export class SlideScore implements ISlideScore{
    totalScore:number = 0;
    userScore:number = 0;

    constructor(){
    }

    populateFromJson(score:ISlideScore){
        this.totalScore = score.totalScore;
        this.userScore = score.userScore;
    }
}


//enum for state of slide
export enum SlideStateEnum{
    none,
    init,
    solution,
    note,
    assessmentShown,
    finished
}

//enum for events of slide
export enum SlideEventEnum{
    assessmentQuestionAnswered,
    questionAnswered,
    notesAdded,
    none,
    showSolution,
    assessmentShown,
}

//interface for slide state
export interface ISlideState{

    totalScore:number,
    userScore:number,

    userNote:string,

    state:SlideStateEnum,
    //Map of rules key to score
    rulesScores:Map<string,ISlideScore>,
    //array of questions used as cache how many questions 
    //has not been answered yet
    questions:IPQuestionComponent[],
    //function to process the question
    processQuestion(question:IPQuestionComponent|null, score:number, event:SlideEventEnum):void;
    //update rules score
    updateRulesScore(question:IPQuestionComponent, score:number):void
    removeQuestion(question:IPQuestionComponent):void

    //function to convert object to json
    toJson():any;
    //function to populate from json
    populateFromJson(json:any):void;

    //update the slide from the existing slide
    updateFromSlide(slide:ISlideState):void;

    getErrorMsgg():string;

    updateTotalScore(slide:ISlideState, question:IPQuestionComponent):void;

    updateUserScore(question:IPQuestionComponent, score:number):void;

    getQuestionScore(question:IPQuestionComponent):number;

    initState(isTheory:boolean):void;
}

//implement interface ISlideState
export class SlideState implements ISlideState{

    totalScore:number = 0;
    userScore:number = 0;
    userNote:string = "";

    state:SlideStateEnum = SlideStateEnum.init;
    //init rulesScores
    rulesScores:Map<string,ISlideScore> = new Map<string,ISlideScore>();
    questions:IPQuestionComponent[] = [];

    constructor(){
        //set state to none
        this.state = SlideStateEnum.none;
    }
    
    processQuestion(question:IPQuestionComponent|null, 
        score:number, event:SlideEventEnum){
        //switch over the state
        switch(this.state){
            case SlideStateEnum.init:
                if( event == SlideEventEnum.assessmentQuestionAnswered ){
                    //We know assessment question is answered
                    if(  score > 0 ){
                        //set the state to finished
                        this.state = SlideStateEnum.finished;
                        //Also update the rules score for all questions
                        this.questions.forEach( (q) => {
                            this.updateRulesScore(q, 1, false)
                        } );
                        this.questions = [];
                    }
                    else {
                        //set the state to solution
                        this.state = SlideStateEnum.solution;
                        //If there are no questions than mark it as finished
                        if( this.questions.length == 0 ){
                            this.state = SlideStateEnum.finished;
                        }
                    }
                }
                else if( event == SlideEventEnum.showSolution ){
                    this.state = SlideStateEnum.solution;
                }
                break;
            case SlideStateEnum.solution:
                this.updateRulesScore(question, score, true);
                //If questions array is empty than set the state to note
                if( this.questions.length == 0 ){
                    this.state = SlideStateEnum.note;
                }
                break;
            case SlideStateEnum.note:
                if(  event == SlideEventEnum.notesAdded ){
                    this.state = SlideStateEnum.finished;
                }
                break;
            }
    }

    //function updateRulesScore which takes question and score and 
    //updates the rules score
    //Variable canDelete is used to determine if the question can be deleted from the questions array
    //If user answers assessment question correctly than we update the rules
    //score but do not delete the question because its possible that 
    //user sees solution and then answers the question again at which point
    updateRulesScore(question:IPQuestionComponent | null, score:number, canDelete:boolean=true){
        if( question == null ){
            return;
        }
        if( question.assessment ){
            return;
        }
        //do we have a rule for this question?
        let ruleKey = "others"
        if(  question.ruleKey ){
          ruleKey = question.ruleKey;
        }

        //get the rules score from the rulesScores map
        let rulesScore = this.rulesScores.get(ruleKey);
        //if rulesScore is not found, create a new one
        if( rulesScore == null ){
            rulesScore = new SlideScore();
            this.rulesScores.set(ruleKey, rulesScore);
        }

        rulesScore.totalScore += 1;
        //update user score only if its positive
        if( score > 0 ){
            rulesScore.userScore += 1;
        }
        if( canDelete ){
            this.removeQuestion(question);
        }
    }

    removeQuestion(question:IPQuestionComponent){
        //find the index of the question in the questions array using id
        let index = this.questions.findIndex( (q) => {
            return q.id == question.id;
        });

        //if index is found than remove it from the array
        if( index > -1 ){
            this.questions.splice(index, 1);
        }        
    }

    //implement toJson
    toJson():any{
        let json = {
            totalScore:this.totalScore,
            userScore:this.userScore,
            rulesScores:{},
            state:this.state,
            userNote:this.userNote,
        } as any;
        //iterate over the rulesScores map
        this.rulesScores.forEach( (value, key) => {
            json.rulesScores[key] =  JSON.parse(JSON.stringify(value));
        } );
        return json;
        
    }

    //implement populateFromJson
    populateFromJson(json:ISlideState){
        this.totalScore = json.totalScore;
        this.userScore = json.userScore;
        this.state = json.state;
        this.rulesScores = new Map<string,ISlideScore>();
        this.userNote = json.userNote;
        let rs = json.rulesScores as any;
        //iterate over the rulesScores object
        for( let key in json.rulesScores ){
            let rulesScore = new SlideScore();
            rulesScore.populateFromJson(rs[key]);
            this.rulesScores.set(key, rulesScore);
        }

    }

    //implement updateFromSlide
    updateFromSlide(slide:ISlideState){
        this.totalScore = slide.totalScore;
        this.userScore = slide.userScore;
        this.state = slide.state;
        this.rulesScores = slide.rulesScores;
        this.userNote = slide.userNote;
    }

    //implement getErrorMsgg
    getErrorMsgg(){
        if( this.state == SlideStateEnum.none ){
            return "";
        }
        //if state is init then return message that "You must answer the question before going to next question."
        if( this.state == SlideStateEnum.init ){
            return "You have not answered the question. Are you you sure you want to exit?";
        }
        //if state is solution then return message that "You must go through alll the slides before going to next question."
        if( this.state == SlideStateEnum.solution ){
            return "You must go through all the slides before going to next question.";
        }
        return "";
    }

    //It can never be theory
    updateTotalScore(slide:ISlideState, question:IPQuestionComponent){
        if( question.assessment ){
            this.totalScore += this.getQuestionScore(question);
        }
    }

    getQuestionScore(question:IPQuestionComponent){
        return 10;
    }

    //implement updateUserScore
    updateUserScore(question:IPQuestionComponent, score:number):void{
        if( this.state == SlideStateEnum.finished ){
            return;
        }
        if( question.assessment ){
            if( question.solutionShown ){
                //no point will be given for showing solution
                return;
            }

            //update the user score
            this.userScore +=  this.getQuestionScore(question) * score
            //deduct points for hint
            if(  question.hintShown ){
                this.userScore +=  -2
            }
            this.processQuestion(question, score, SlideEventEnum.assessmentQuestionAnswered);
        }
        else {
            this.processQuestion(question, score, SlideEventEnum.questionAnswered);
        }
    }

    initState(isTheory:boolean){
        //if state is not none then return
        if( this.state !== SlideStateEnum.none ){
            return;
        }

        this.state = SlideStateEnum.init;

        //If we are in theory and we get slide which has no question 
        //mark it is finished so that it can be seen as complete.
        if(  !this.questions.length && isTheory){
            this.state = SlideStateEnum.finished;
        }
    }
}


export class SlideStateTheory   extends   SlideState  implements ISlideState {
    constructor(){
        super();
    }

    processQuestion(question:IPQuestionComponent|null, 
        score:number, event:SlideEventEnum){
        //switch over the state
        switch(this.state){
            case SlideStateEnum.init:
                    //change the state to solution
                    this.state = SlideStateEnum.solution;
                    if(question){
                        this.removeQuestion(question);
                    }
                    if( !this.questions.length ){
                        this.state = SlideStateEnum.finished;
                    }
                break;
            case SlideStateEnum.solution:
                //remove the question from the questions array
                if( question ){
                    this.removeQuestion(question);
                }
                //If questions array is empty than set the state to note
                if( !this.questions.length ){
                    this.state = SlideStateEnum.finished;
                }
                break;
            }
    }


    updateTotalScore(slide:ISlideState, question:IPQuestionComponent){
        this.totalScore += this.getQuestionScore(question);
    }

    getQuestionScore(question:IPQuestionComponent){
        return 1;
    }

    //implement updateUserScore
    updateUserScore(question:IPQuestionComponent, score:number):void{
        if( this.state == SlideStateEnum.finished ){
            return;
        }
        this.userScore +=  this.getQuestionScore(question) * score;
        this.processQuestion(question, score, SlideEventEnum.questionAnswered);
    }

    //implement getErrorMsgg
    getErrorMsgg(){
        if( this.state == SlideStateEnum.none ){
            return "";
        }
        //if state is init then return message that "You must answer the question before going to next question."
        if( this.state == SlideStateEnum.init || this.state == SlideStateEnum.solution ){
            return "You must go through all the slides before going to next question.";
        }
        return "";
    }
}

//interface to keep assessment detail
export interface IAssessmentDetail{

    //Only save assessment if the assessment is dirty. 
    isDirty:boolean;

    //Id of the presentation for which we are keeping the assessment detail
    firebaseId:string
    docType:string
    //aggregration of all the slides
    totalScore:number,
    userScore:number,
    //currently selected Slide
    currentSlideId:string,
    //map of slide id to slide state
    slideStates:Map<string,ISlideState>,

    //variable to tell if we have updated the assessment detail
    isUpdated:boolean,

    instructionsShown:boolean;


    //function to initialize the assessment
    initialize(docType:string, firebaseId:string):void;

    //function to add state slide
    addSlideState(slideId:string):void;



    //function to add rules question to the slide state
    addQuestion(question:IPQuestionComponent, slideId:string):void;
    //function to check if we can move from current slide
    canMoveFromCurrentSlide():boolean;
    //get rules score for the current slide
    getRulesScore(rulesDoc:IRulesDoc|null):IDispayRulesScore[];
    //get rules score for the all slides
    getRulesScoreAll(rulesDoc:IRulesDoc|null):IDispayRulesScore[];
    //aggregate scores of all slides
    aggregateScores():void;
    //function to convert object to json
    toJson():any;
    //function to populate from json
    populateFromJson(json:IAssessmentDetail):void;

    //function to add user note to the current slide
    addUserNote(note:string):void;

    //update assesment from existing assessment
    updateFromAssessment(assessment:IAssessmentDetail):void;

    //get slide from slide id
    getSlide(slideId:string):ISlideState | null;
}

//implement interface IAssessmentDetail
export class AssessmentDetail implements IAssessmentDetail{
    
    isDirty:boolean = false;

    totalScore:number = 0;
    userScore:number = 0;
    docType!: string;
    firebaseId!: string;

    currentSlideId:string = "";
    slideStates:Map<string,ISlideState> = new Map<string,ISlideState>();

    isUpdated:boolean = false;
    instructionsShown:boolean = false;
    constructor(){
    }
    
    //implement initialize
    initialize(docType:string, firebaseId:string):void{
        //set the docType
        this.docType = docType;
        //set the firebaseId
        this.firebaseId = firebaseId;
    }

    isTheoryPresentation(){
        return this.docType === theory;
      }    

    isAssessmentQuestion(question:IPQuestionComponent){
    return !this.isTheoryPresentation() 
            && question.assessment;
    }

    updateTotalScore(slide:ISlideState, question:IPQuestionComponent){
        //get the current slide and call the updateTotalScore function
        slide.updateTotalScore(slide, question);
    }


    updateUserScore(question:IPQuestionComponent, score:number){
        this.isDirty = true;
        let slide = this.slideStates.get(this.currentSlideId);
        slide?.updateUserScore(question, score);
    }

    getSlideStateInstance(){
        let slideState = null;
        if( this.isTheoryPresentation()  ){
            slideState = new SlideStateTheory();
        }
        else {
            slideState = new SlideState();
        }

        return slideState;
    }


    addQuestion(question:IPQuestionComponent, slideId:string){
        //get the slide state from the slideStates map
        let slideState = this.slideStates.get(slideId);
        
        //if slide state is not found, create a new one
        if( slideState == null ){
            slideState = this.getSlideStateInstance();
            this.slideStates.set(slideId, slideState);
        }

        if(  !slideState ){
            return
        }

        //add the question to the slide state
        //if question is assessment than return
        if( !question.assessment ){
            slideState.questions.push(question);;
        }

        this.updateTotalScore(slideState, question);
        
    }

    canMoveFromCurrentSlide():boolean{
        //get the slide state from the slideStates map
        let slideState = this.slideStates.get(this.currentSlideId);
        //if slide state is not found, create a new one
        if( slideState == null ){
            //Must be the assessment slide
            return true;
        }

        //check if the slide state is finished
        return slideState.state == SlideStateEnum.finished;
    }




    //function to process question in the current slide
    processQuestion(question:IPQuestionComponent|null, score:number, event:SlideEventEnum){
        //get the slide state from the slideStates map
        let slideState = this.slideStates.get(this.currentSlideId);
        if(  !slideState ){
            console.log("slide state is not found");
            return
        }
        //process the question
        slideState.processQuestion(question, score, event);
    }

    //function to get map with rules score from all slides
    _getMapOfAllRulesScore():Map<string,ISlideScore>{
        let temp :Map<string,ISlideScore> = new Map<string,ISlideScore>();
        //loop over the slide states
        this.slideStates.forEach( (slideState) => {
            //loop over the rules scores
            slideState.rulesScores.forEach( (score, key) => {
                //get the rules score from the temp map
                let rulesScore = temp.get(key);
                //if rules score is not found, create a new one
                if( rulesScore == null ){
                    rulesScore = new SlideScore();
                    temp.set(key, rulesScore);
                }

                //update the rules score
                rulesScore.totalScore += score.totalScore;
                rulesScore.userScore += score.userScore;
            } );
        } );

        return temp;
    }

    //function to set current slide
    setCurrentSlide(slideId:string, isTheory:boolean){
        this.currentSlideId = slideId;
        //update the state of the slide to init if its none
        let slideState = this.slideStates.get(slideId);
        if( slideState ){
            slideState.initState(isTheory);
        }
    }

    getCurrentSlide():ISlideState|undefined{
        return this.slideStates.get(this.currentSlideId);
    }

    getCurrentState():SlideStateEnum{
        //get the current slide state
        let slideState = this.slideStates.get(this.currentSlideId);
        if( slideState == null ){
            return SlideStateEnum.none;
        }
        return slideState.state;
    }

    getErrorMsgg(){
        //get the current slide state
        let slide = this.getCurrentSlide();
        return slide ? slide.getErrorMsgg() : "";
    }


    private _getRuleScore(rule:IRule, 
        score:Map<string,ISlideScore> | undefined,
        rulesScore:IDispayRulesScore[]
        ){
        if( !rule  || !score){
            return;
          }
          //Does this rule have a score?
          let s = score.get(rule.key);
          //check if rule already exists in the rules score array
            let index = rulesScore.findIndex( (r) => {
                return r.key == rule.key;
            } );

          if(  s && index == -1 ){
            let x = {} as IDispayRulesScore;
            x.key = rule.key;
            x.content = rule.value;
            x.userScore = s.userScore;
            x.totalScore = s.totalScore;
            x.percentage = +((s.userScore/s.totalScore)*100).toFixed(2);
            rulesScore.push(x);
          }
      
          //Iterate through the children
          if(  rule.children ){
            rule.children.forEach(r => {
              this._getRuleScore(r, score, rulesScore);
            } );
          }
    }

    //get rules score for the current slide
    getRulesScore(rulesDoc:IRulesDoc|null):IDispayRulesScore[]{
        if( !rulesDoc ){
            return[];
        }
        //get the slide state from the slideStates map
        let slideState = this.slideStates.get(this.currentSlideId);
        if( slideState == null ){
            return [];
        }

        let rulesScore:IDispayRulesScore[] = [];
        rulesDoc.rules.forEach( (rule) => {
            this._getRuleScore(rule, slideState?.rulesScores, rulesScore);
        });

        return rulesScore;
    }

    //implement function to get rules score for the all slides
    getRulesScoreAll(rulesDoc:IRulesDoc|null):IDispayRulesScore[]{
        if( !rulesDoc ){
            return[];
        }
        let rulesScore:IDispayRulesScore[] = [];
        let temp = this._getMapOfAllRulesScore();
        //We cannot use the values of map directly because it 
        //will not give us the rules in sequesnce as declared in rules Doc
        this.slideStates.forEach( (slideState) => {
            rulesDoc.rules.forEach( (rule) => {
                this._getRuleScore(rule, temp, rulesScore);
            } );
        } );
        return rulesScore;
    }

    //function to return true if all slides are finished
    areAllSlidesFinished():boolean{
        //use for loop to loop over the slide states and check if all slides are finished
        for( let slideState of this.slideStates.values() ){
            if( slideState.state != SlideStateEnum.finished ){
                return false;
            }
        }
        return true;
    }

    //implement aggregate score function
    aggregateScores(){
        this.totalScore  = 0;
        this.userScore  = 0;
        //loop over the slide states
        this.slideStates.forEach( (slideState) => {
            //add the total score to the total score
            this.totalScore += slideState.totalScore;
            this.userScore += slideState.userScore;
        } );
        
    }

    //implement function tojson to return the json object
    toJson():any{
        this.aggregateScores();
        let json = {} as any;
        json["slideStates"] = {};
        //iterate over map of slide states
        this.slideStates.forEach( (slideState, key) => {
            json["slideStates"][key] = slideState.toJson();
        } );
        json["totalScore"] = this.totalScore;
        json["userScore"] = this.userScore;
        //set the firebase id
        json["firebaseId"] = this.firebaseId;
        json["currentSlideId"] = this.currentSlideId;
        //set instructions shown    
        json["instructionsShown"] = this.instructionsShown;
        return json;
    }

    //implement function populate from json
    populateFromJson(json:IAssessmentDetail){
        this.totalScore = json.totalScore;
        this.userScore = json.userScore;
        this.currentSlideId = json.currentSlideId;
        this.instructionsShown = json.instructionsShown;
        this.slideStates = new Map<string,ISlideState>();
        let ss = json.slideStates as any
        //iterate over the slide states object
        for( let key in json.slideStates ){
            let value = ss[key] as any;
            //create a new slide state
            let slideState = this.getSlideStateInstance();
            //populate the slide state from the json
            slideState.populateFromJson(value);
            //add the slide state to the slide states map
            this.slideStates.set(key, slideState);
        }            

    }

    //add user note function
    addUserNote(note:string){
        this.isDirty = true;
        //get the current slide state
        let slideState = this.slideStates.get(this.currentSlideId);
        if( slideState == null ){
            return;
        }
        //add the note to the slide state
        slideState.userNote = note;
    }

    //implement function updateAssessment
    updateFromAssessment(assessment:IAssessmentDetail){
        if( this.isUpdated ){
            this.aggregateScores(); 
            return;
        }
        this.isUpdated = true;
        //copy total ans user score from assessment to this object
        this.totalScore = assessment.totalScore;
        this.userScore = assessment.userScore;
        this.instructionsShown = assessment.instructionsShown;
        //iterate over the slide states map of assessment
        assessment.slideStates.forEach( (value, key) => {
            //get the slide state from this object
            let slideState = this.slideStates.get(key);
            //if slide state is not found then create a new slide state
            if( slideState == null ){
                return
            }
            //populate the slide state from the assessment
            slideState.updateFromSlide(value);
        } );

    }

    getSlide(slideId:string):ISlideState | null {
        return this.slideStates.get(slideId) || null;
    }

    //implement addSlideState function
    addSlideState(slideId: string): void {
        //create a new slide state
        let slideState = this.getSlideStateInstance();
        //add the slide state to the slide states map
        this.slideStates.set(slideId, slideState);
    }
}


