import { Component, Input, OnInit } from '@angular/core';
import { ShowPresentationService } from 'src/app/common/services/show-presentation/show-presentation.service';
import { FlatComponent, IPSlideComponent } from 'src/app/models';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {

  public isTheory:boolean = true;

  public text:string = ""; 


  private _component!:IPSlideComponent;

  public get component():any{
    return this._component;
  }


  @Input() public set component(fc:any){
    let c  = fc as FlatComponent;
    this._component = c.component as IPSlideComponent;
    if(  !this._component.step  ){
      return;
    }
    switch(this._component.step){
      case 1: {
        this.text = "Understand"
        break;
      }
      case 2: {
        this.text = "Plan"
        break;
      }
      case 3: {
        this.text = "Execute"
        break;
      }
      case 4: {
        this.text = "Lookback"
        break;
      }
      default: {
        break;
      }
    }
  }

  constructor(public spSvc:ShowPresentationService) { }

  ngOnInit(): void {
    this.isTheory = this.spSvc.flatPresentation.isTheory();
  }

}
