import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/common/services/firebase/firebase.service';
import { PresentationService } from 'src/app/common/services/presentation/presentation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private pSvc: PresentationService,
    private router : Router,
  ) { }

  ngOnInit(): void {
    
  }

  //create a function handler create
  create(){
    this.pSvc.createPresentation("10M1")
    .subscribe( (presentation) => {
      this.router.navigateByUrl(`/createPresentation/${presentation.firebaseId}`);
    });
  }

}
