<div class="header">
    <mat-toolbar>
        <button 
            mat-icon-button 
            class="btn"
            (click)="home()">
          <mat-icon>home</mat-icon>
        </button>
        <span class="title-center">
          {{presentation?.title}}
        </span>
      
      </mat-toolbar>
</div>
<div class="content" *ngIf="presentation">
  <div class="row card-assessment justify-left" *ngIf="!isTheory">
    <div class="width100 title-row">
      <mat-card >   
        <mat-card-title>
          <span>Score &nbsp;</span>
          <span>{{scoreSvc.assessmentDetails?.userScore}}/{{scoreSvc.assessmentDetails?.totalScore}}</span>
          <span class="justify-right">
            <button mat-button  color="primary"
            (click)="openGoalDialog(1, true)">Instructions/Rules</button>  
          </span>
        </mat-card-title>
        
      </mat-card>
    </div>
  </div>
  <div class="cards-container row justify-left" >
    <div class="card" [ngClass] = "{'slide-attempt-correct': slideStateColor(i) === 'green'}"  *ngFor="let slide of presentation.slides;index as i">
        <mat-card class="slide-result"> 
            <mat-card-title >
                <div class="row width100 justify-left">
                  
                  <div class="status">
                    <span *ngIf="slideStateColor(i) === ''" class="notstarted"> Not Started</span>
                    <span *ngIf="slideStateColor(i) === 'incomplete'" class="incomplete"> incomplete</span>
                    <span *ngIf="slideStateColor(i) === 'complete'" class="incomplete"> Complete</span>
                    <span *ngIf="slideStateColor(i) === 'passed'" class="passed"> Passed</span>
                    <span *ngIf="slideStateColor(i) === 'failed'" class="failed"> Failed</span>
                  </div>
                  <a class="slide-title" (click)="goTo(i)" >
                     {{slide.title}}
                </a>
                </div>
              </mat-card-title >
              <mat-card-content *ngIf="!isTheory">
                <span>
                  <b>Score:</b> {{getSlideState(i)?.userScore}}/{{getSlideState(i)?.totalScore}}
                </span>  
                <span class="notes" *ngIf="getSlideState(i)?.userNote" 
                (click) = "openNote(i)" >View Notes</span>
                
                
                <!-- <div  class="status-icon">
                  <mat-icon *ngIf="slideStateColor(i) === 'green'" class="txt-success">check_circle</mat-icon>
                  <mat-icon *ngIf="slideStateColor(i) === 'red'" color="warn">highlight_off</mat-icon>
                  <mat-icon *ngIf="slideStateColor(i) === 'grey'" color="warn">eject</mat-icon>
                </div> -->
              </mat-card-content>
        </mat-card>
      </div>
  </div>
</div>
