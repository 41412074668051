<div class="timeline">
    <div>
        <div class="container left" >
            <div class="inner">
                <div class="head">
                    <div class="icon">
                        <img class="ui tiny image" 
                            src="https://image.flaticon.com/icons/svg/214/214335.svg">
                    </div>
                    <div class="title">
                        <span >2016 - 2019</span>
                        My last travel 
                    </div>
                </div>
                <div class="body">
                    <p >
                        There are so much countries in the world...
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container right" >
            <div class="inner">
                <div class="head">
                    <div class="icon">
                        <img class="ui tiny image" 
                            src="https://image.flaticon.com/icons/svg/214/214335.svg">
                    </div>
                    <div class="title">
                        <span >2016 - 2019</span>
                        My last travel 
                    </div>
                </div>
                <div class="body">
                    <p >
                        There are so much countries in the world...
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container left" >
            <div class="inner">
                <div class="head">
                    <div class="icon">
                        <img class="ui tiny image" 
                            src="https://image.flaticon.com/icons/svg/214/214335.svg">
                    </div>
                    <div class="title">
                        <span >2016 - 2019</span>
                        My last travel 
                    </div>
                </div>
                <div class="body">
                    <p >
                        There are so much countries in the world...
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
